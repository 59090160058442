
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchWrapper } from '_helpers';

const name = 'itteam';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const itteamActions = { ...slice.actions, ...extraActions };
export const itteamReducer = slice.reducer;

function createInitialState() {
    return {
        itteam: {},
        oncall: {},
        status: 'idle',
        error: null,
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_itteam_API_URL;

    return {
        saveDetails: saveDetails(),
        editDetails: editDetails(),
        editOnCall: editOnCall(),
        destroy: destroy(),
        getITTeam: getITTeam(),
        onCallDeets: onCallDeets(),
    };

    function getITTeam() {
        return createAsyncThunk(
            `/${name}/getITTeam`,
            async () => await fetchWrapper.get(`${baseUrl}`)
        );
    }
    function onCallDeets() {
        return createAsyncThunk(
            `/${name}/onCallDeets`,
            async () => await fetchWrapper.get(`${baseUrl}/onCallDeets`)
        );
    }
    function editDetails() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/editDetails`,
            async (data) => await
                axios.post(`${baseUrl}/editDetails`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function saveDetails() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/saveDetails`,
            async (data) => await
                axios.post(`${baseUrl}/saveDetails`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function editOnCall() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editOnCall`,
            async (data) => await
                axios.post(`${baseUrl}/editOnCall`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function destroy() {
        return createAsyncThunk(
            `/${name}/destroy`,
            async ({ it_team_id }) => await fetchWrapper.post(`${baseUrl}/destroy`, { it_team_id })
        );
    }
}

function createExtraReducers() {
    return {
        ...destroy(),
        ...editDetails(),
        ...editOnCall(),
        ...getITTeam(),
        ...onCallDeets(),
        ...saveDetails(),
    };

    function saveDetails() {
        var { pending, fulfilled, rejected } = extraActions.saveDetails;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Detail created') {
                    state.status = 'succeeded';
                    state.itteam = action.payload.itteam;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function destroy() {
        var { pending, fulfilled, rejected } = extraActions.destroy;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function getITTeam() {
        var { pending, fulfilled, rejected } = extraActions.getITTeam;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.itteam = action.payload.itteam;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function onCallDeets() {
        var { pending, fulfilled, rejected } = extraActions.onCallDeets;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                if (action.payload.message === 'Successfully retrieved details') {
                    state.oncall = action.payload.oncall;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function editDetails() {
        var { pending, fulfilled, rejected } = extraActions.editDetails;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully edited details') {
                    state.status = 'succeeded';
                    state.itteam = action.payload.itteam;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function editOnCall() {
        var { pending, fulfilled, rejected } = extraActions.editOnCall;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

}