import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import axios from 'axios';

const name = 'wiki';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const wikiActions = { ...slice.actions, ...extraActions };
export const wikiReducer = slice.reducer;

const token = JSON.parse(localStorage.getItem('token'));
const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

function createInitialState() {
    return {
        loading: false,
        error: null,
        wikiFeatured: [],
        dashboardData: [],
        wikiSearch: [],
        wikiBySubcategory: [],
        wikiSub: [],
        wikiCat: [],
        thisCategory: [],
        thisArticle: {},
        nextArticle: {},
        prevArticle: {},
        wikiBytag: [],
        wikiAllTags: [],
        allCategories: [],
        allSubCategories: [],
        subsByCategory: [],
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_WIKI_API_URL;

    return {
        ...basicActions(),
        ...articleActions(),
        ...categoryActions(),
        ...tagActions(),
        ...uploadActions(),
    };

    function basicActions() {
        return {
            wikiFeatured: createAsyncThunk(
                `/${name}/wikiFeatured`,
                async () => await fetchWrapper.get(`${baseUrl}/wiki/wikiFeatured`)
            ),
            dashboardData: createAsyncThunk(
                `/${name}/dashboardData`,
                async () => await fetchWrapper.get(`${baseUrl}/wiki/dashboardData`)
            ),
            wikiSearch: createAsyncThunk(
                `/${name}/wikiSearch`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/wikiSearch/${id}`)
            ),
        };
    }

    function articleActions() {
        return {
            thisArticle: createAsyncThunk(
                `/${name}/thisArticle`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/thisArticle/${id}`)
            ),
            markPublished: createAsyncThunk(
                `/${name}/markPublished`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/markPublished/${id}`)
            ),
            updateStatus: createAsyncThunk(
                `/${name}/updateStatus`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/updateStatus`, data)
            ),
            deleteArticle: createAsyncThunk(
                `/${name}/deleteArticle`,
                async (slug) => await fetchWrapper.get(`${baseUrl}/wiki/deleteArticle/${slug}`)
            ),
            cloneArticle: createAsyncThunk(
                `/${name}/cloneArticle`,
                async (slug) => await fetchWrapper.get(`${baseUrl}/wiki/cloneArticle/${slug}`)
            ),
            updateFeatured: createAsyncThunk(
                `/${name}/updateFeatured`,
                async (slug) => await fetchWrapper.get(`${baseUrl}/wiki/isFeatured/${slug}`)
            ),
        };
    }


    function categoryActions() {
        return {
            wikiBySubcategory: createAsyncThunk(
                `/${name}/wikiBySubcategory`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/wikiBySubcategory/${id}`)
            ),
            thisCategory: createAsyncThunk(
                `/${name}/thisCategory`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/thisCategory/${id}`)
            ),
            allCategories: createAsyncThunk(
                `/${name}/allCategories`,
                async () => await fetchWrapper.get(`${baseUrl}/wiki/allCategories`)
            ),
            subsByCategory: createAsyncThunk(
                `/${name}/subsByCategory`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/subsByCategory/${id}`)
            ),
            addCategory: createAsyncThunk(
                `/${name}/addCategory`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/addCategory`, data)
            ),
            addSubCategory: createAsyncThunk(
                `/${name}/addSubCategory`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/addSubCategory`, data)
            ),
            deleteCategory: createAsyncThunk(
                `/${name}/deleteCategory`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/deleteCategory/${id}`)
            ),
            deleteSubCategory: createAsyncThunk(
                `/${name}/deleteSubCategory`,
                async (id) => await fetchWrapper.get(`${baseUrl}/wiki/deleteSubCategory/${id}`)
            ),
            editCategory: createAsyncThunk(
                `/${name}/editCategory`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/editCategory`, data)
            ),
            editSubCategory: createAsyncThunk(
                `/${name}/editSubCategory`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/editSubCategory`, data)
            ),
        };
    }

    function tagActions() {
        return {
            wikiBytag: createAsyncThunk(
                `/${name}/wikiBytag`,
                async (tag_name) => await fetchWrapper.get(`${baseUrl}/wiki/wikiBytag/${tag_name}`)
            ),
            wikiAllTags: createAsyncThunk(
                `/${name}/wikiAllTags`,
                async () => await fetchWrapper.get(`${baseUrl}/wiki/wikiAllTags`)
            ),
        };
    }

    function uploadActions() {
        return {
            uploadArticle: createAsyncThunk(
                `/${name}/uploadArticle`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/uploadArticle`, data)
            ),
            setArticlePayload: createAsyncThunk(
                `/${name}/setArticlePayload`,
                async ({ payload, id }) => await fetchWrapper.post(`${baseUrl}/wiki/setArticlePayload/${id}`, payload)
            ),
            uploadCategory: createAsyncThunk(
                `/${name}/uploadCategory`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/uploadCategory`, data)
            ),
            uploadSubcategory: createAsyncThunk(
                `/${name}/uploadSubcategory`,
                async (data) => await fetchWrapper.post(`${baseUrl}/wiki/uploadSubcategory`, data)
            ),
            uploadImage: createAsyncThunk(
                `/${name}/uploadImage`,
                async (data) => await
                    axios.post(`${baseUrl}/wiki/uploadImage`, data, config).then(function (response) {
                        return response.data
                    })
            ),
            uploadDocument: createAsyncThunk(
                `/${name}/uploadDocument`,
                async (data) => await
                    axios.post(`${baseUrl}/wiki/uploadDocument`, data, config).then(function (response) {
                        return response.data
                    })
            ),
            uploadVideo: createAsyncThunk(
                `/${name}/uploadVideo`,
                async (data) => await
                    axios.post(`${baseUrl}/wiki/uploadVideo`, data, config).then(function (response) {
                        return response.data
                    })
            ),
        };
    }
}


function createExtraReducers() {
    return {
        ...basicReducers(),
        ...articleReducers(),
        ...categoryReducers(),
        ...tagReducers(),
        ...uploadReducers(),
    };

    function basicReducers() {
        return {
            ...wikiFeatured(),
            ...dashboardData(),
            ...wikiSearch(),
        };
    }

    function articleReducers() {
        return {
            ...thisArticle(),
            ...markPublished(),
            ...updateStatus(),
            ...deleteArticle(),
            ...cloneArticle(),
            ...updateFeatured(),
        };
    }

    function categoryReducers() {
        return {
            ...wikiBySubcategory(),
            ...thisCategory(),
            ...allCategories(),
            ...subsByCategory(),
            ...addCategory(),
            ...addSubCategory(),
            ...deleteCategory(),
            ...deleteSubCategory(),
            ...editCategory(),
            ...editSubCategory(),
        };
    }

    function tagReducers() {
        return {
            ...wikiBytag(),
            ...wikiAllTags(),
        };
    }

    function uploadReducers() {
        return {
            ...uploadArticle(),
            ...setArticlePayload(),
            ...uploadCategory(),
            ...uploadSubcategory(),
            ...uploadImage(),
            ...uploadDocument(),
            ...uploadVideo(),
        };
    }

    function wikiFeatured() {
        var { pending, fulfilled, rejected } = extraActions.wikiFeatured;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Featured articles retrieved successfully') {
                    state.wikiFeatured = action.payload.wikiFeatured;
                } else {
                    state.wikiFeatured = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function dashboardData() {
        var { pending, fulfilled, rejected } = extraActions.dashboardData;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Dashboard data retrieved successfully') {
                    state.dashboardData = action.payload.dashboardData;
                } else {
                    state.dashboardData = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function wikiSearch() {
        var { pending, fulfilled, rejected } = extraActions.wikiSearch;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Search retrieved successfully') {
                    state.wikiSearch = action.payload.wikiSearch;
                } else {
                    state.wikiSearch = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function thisArticle() {
        var { pending, fulfilled, rejected } = extraActions.thisArticle;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Article retrieved successfully') {
                    state.thisArticle = action.payload.thisArticle;
                    state.nextArticle = action.payload.nextWiki;
                    state.prevArticle = action.payload.previousWiki;
                } else {
                    state.thisArticle = {};
                }
               
                    state.loading = false;    
               
                
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function markPublished() {
        var { pending, fulfilled, rejected } = extraActions.markPublished;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function updateStatus() {
        var { pending, fulfilled, rejected } = extraActions.updateStatus;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function deleteArticle() {
        var { pending, fulfilled, rejected } = extraActions.deleteArticle;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Article deleted successfully') {
                    state.loading = false;
                    state.thisArticle = {};
                } else {
                    state.loading = false;
                }
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function cloneArticle() {
        var { pending, fulfilled, rejected } = extraActions.cloneArticle;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
                state.thisArticle = {};
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        }
    }

    function updateFeatured() {
        var { pending, fulfilled, rejected } = extraActions.updateFeatured;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        }
    }

    function wikiBySubcategory() {
        var { pending, fulfilled, rejected } = extraActions.wikiBySubcategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Wiki retrieved successfully') {
                    state.wikiBySubcategory = action.payload.wikiBySubcategory;
                    state.wikiSub = action.payload.wikiSub;
                    state.wikiCat = action.payload.wikiCat;
                } else {
                    state.wikiBySubcategory = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function thisCategory() {
        var { pending, fulfilled, rejected } = extraActions.thisCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Category retrieved successfully') {
                    state.thisCategory = action.payload.thisCategory;
                } else {
                    state.thisCategory = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function allCategories() {
        var { pending, fulfilled, rejected } = extraActions.allCategories;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Categories retrieved successfully') {
                    state.allCategories = action.payload.allCategories;
                } else {
                    state.allCategories = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function subsByCategory() {
        var { pending, fulfilled, rejected } = extraActions.subsByCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {

                if (action.payload.message === 'Subcategories retrieved successfully') {
                    state.subsByCategory = action.payload.subsByCategory;
                } else {
                    state.subsByCategory = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function addCategory() {
        var { pending, fulfilled, rejected } = extraActions.addCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function addSubCategory() {
        var { pending, fulfilled, rejected } = extraActions.addSubCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                console.log(action.error);
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function deleteCategory() {
        var { pending, fulfilled, rejected } = extraActions.deleteCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                console.log(action.error);
                state.loading = false;
                state.error = action.error;
            }
        };
    }
    function deleteSubCategory() {
        var { pending, fulfilled, rejected } = extraActions.deleteSubCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                console.log(action.error);
                state.loading = false;
                state.error = action.error;
            }
        };
    }
    function editCategory() {
        var { pending, fulfilled, rejected } = extraActions.editCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                console.log(action.error);
                state.loading = false;
                state.error = action.error;
            }
        };
    }
    function editSubCategory() {
        var { pending, fulfilled, rejected } = extraActions.editSubCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                console.log(action.error);
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function wikiBytag() {
        var { pending, fulfilled, rejected } = extraActions.wikiBytag;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Wiki retrieved successfully') {
                    state.wikiBytag = action.payload.wikiBytag;
                } else {
                    state.wikiBytag = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function wikiAllTags() {
        var { pending, fulfilled, rejected } = extraActions.wikiAllTags;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Tags retrieved successfully') {
                    state.wikiAllTags = action.payload.wikiTags;
                } else {
                    state.wikiAllTags = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function uploadArticle() {
        var { pending, fulfilled, rejected } = extraActions.uploadArticle;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function setArticlePayload() {
        var { pending, fulfilled, rejected } = extraActions.setArticlePayload;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function uploadCategory() {
        var { pending, fulfilled, rejected } = extraActions.uploadCategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function uploadSubcategory() {
        var { pending, fulfilled, rejected } = extraActions.uploadSubcategory;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function uploadImage() {
        var { pending, fulfilled, rejected } = extraActions.uploadImage;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function uploadDocument() {
        var { pending, fulfilled, rejected } = extraActions.uploadDocument;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function uploadVideo() {
        var { pending, fulfilled, rejected } = extraActions.uploadVideo;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }
}