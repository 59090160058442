import { useState, useEffect, useCallback } from 'react';

const useWindowDimensions = () => {
    const [windowDimension, setWindowDimension] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });

    const detectSize = useCallback(() => {
        setWindowDimension({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    }, []);

    useEffect(() => {
        window.addEventListener('resize', detectSize);
        return () => {
            window.removeEventListener('resize', detectSize);
        };
    }, [detectSize]);
    return windowDimension;
};

export default useWindowDimensions;