import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'fuse';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const cacheDuration = 3600000;
export const fuseActions = { ...slice.actions, ...extraActions };
export const fuseReducer = slice.reducer;

const token = JSON.parse(localStorage.getItem('token'));
const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

function setCache(key, data) {
    const item = { data, timestamp: Date.now() };
    sessionStorage.setItem(key, JSON.stringify(item));
    clearExpiredCacheItems();
}

function getCache(key) {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);
    if (Date.now() - item.timestamp > cacheDuration) {
        sessionStorage.removeItem(key);
        return null;
    }
    return item.data;
}

function clearExpiredCacheItems() {
    const now = Date.now();
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        try {
            const itemStr = sessionStorage.getItem(key);
            const item = JSON.parse(itemStr);
            if (item && item.timestamp && (now - item.timestamp > cacheDuration)) {
                sessionStorage.removeItem(key);
                console.log(`Cache item with key '${key}' has been removed due to expiration.`);
                i--;
            }
        } catch (e) {
            console.error(`Error reading or parsing sessionStorage item with key '${key}':`, e);
        }
    }
}

function clearCache(key) {
    sessionStorage.removeItem(key);
}

function createInitialState() {
    return {
        isLoading: false,
        loading: false,
        error: false,
        isFuseError: false,
        isCached: false,
        isOverCached: false,
        fuseSearch: [],
        fuseQuery: [],
        fuseDataAll: [],
        thisLocation: [],
        overviewData: [],
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_FUSE_API_URL;
    const baseUrlGAS = process.env.REACT_APP_gasApp_API_URL;

    return {
        ...basicActions(),
    };

    function basicActions() {
        return {
            unpaidLinks: createAsyncThunk(
                `/${name}/unpaidLinks`,
                async ({ isPaid, isExpired, pageSet, isType }) => await fetchWrapper.get(`${baseUrl}/fuse/unpaidLinks?paid=` + String(isPaid) + "&expired=" + String(isExpired) + "&limit=" + String(pageSet) + "&type=" + String(isType))
            ),
            getAllSites: createAsyncThunk(
                `/${name}/getAllSites`,
                async (settingDate, { dispatch }) => {
                    const cacheKey = `thcache_allSites_${settingDate}`;
                    const cachedData = getCache(cacheKey);
                    if (cachedData) {
                        return { ...cachedData, isCached: true };
                    }
                    const response = await fetchWrapper.get(`${baseUrl}/fusemetrix/getLocationSessionsAll?locationName=0&date=${settingDate}`);
                    if (response.message === 'Success') {
                        setCache(cacheKey, response);
                    }
                    return { ...response, isCached: false };
                }
            ),

            getThisSite: createAsyncThunk(
                `/${name}/getThisSite`,
                async ({ locationName, settingOfDate }, { dispatch }) => {
                    const cacheKey = `thcache_thisSite_${locationName}_${settingOfDate}`;
                    const cachedData = getCache(cacheKey);
                    if (cachedData) {
                        return { ...cachedData, isCached: true };
                    }
                    const response = await fetchWrapper.get(`${baseUrl}/fusemetrix/sessions?locationName=${locationName}&date=${settingOfDate}`);
                    if (response.message === 'Success') {
                        setCache(cacheKey, response);
                    }
                    return { ...response, isCached: false };
                }
            ),

            getOverViewOfDay: createAsyncThunk(
                `/${name}/getOverViewOfDay`,
                async ({ locationName, settingOfDate }, { dispatch }) => {
                    const cacheKey = `thcache_dayOverview_${locationName}_${settingOfDate}`;
                    const cachedData = getCache(cacheKey);
                    if (cachedData) {
                        return { ...cachedData, isOverCached: true };
                    }
                    const response = await fetchWrapper.get(`${baseUrlGAS}/rotas/getOverViewOfDay/${locationName}/${settingOfDate}`);
                    if (response.message === 'Day overview retrieved successfully') {
                        setCache(cacheKey, response);
                    }
                    return { ...response, isOverCached: false };
                }
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
    };

    function basicReducers() {
        return {
            ...unpaidLinks(),
            ...getAllSites(),
            ...getThisSite(),
            ...getOverViewOfDay(),
        };
    }

    function unpaidLinks() {
        var { pending, fulfilled, rejected } = extraActions.unpaidLinks;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.fuseSearch = action.payload.response;
                } else {
                    state.fuseSearch = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }


    function getAllSites() {
        var { pending, fulfilled, rejected } = extraActions.getAllSites;
        return {
            [pending]: (state) => {
                state.isLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.fuseDataAll = action.payload;
                    state.isCached = action.payload.isCached;
                    state.isLoading = false;
                } else {
                    state.fuseDataAll = [];
                    state.isCached = false;
                }
            },
            [rejected]: (state, action) => {
                state.isLoading = false;
                state.isFuseError = action.error;
                state.isCached = false;
            }
        };
    }

    function getThisSite() {
        var { pending, fulfilled, rejected } = extraActions.getThisSite;
        return {
            [pending]: (state) => {
                state.isLoading = true;
            },
            [fulfilled]: (state, action) => {
                state.thisLocation = action.payload.response;
                state.isCached = action.payload.isCached;
                state.isLoading = false;
            },
            [rejected]: (state, action) => {
                state.isLoading = false;
                state.isFuseError = action.error;
                state.isCached = false;
            }
        };
    }

    function getOverViewOfDay() {
        var { pending, fulfilled, rejected } = extraActions.getOverViewOfDay;
        return {
            [pending]: (state) => {
                state.isLoading = true;
            },
            [fulfilled]: (state, action) => {
                if(action.payload.message === 'Day overview retrieved successfully') {
                    state.overviewData = action.payload;
                    state.isOverCached =true;
                }else{
                    state.overviewData = [];
                    state.isOverCached = false;
                }
                state.isLoading = false;
            },
            [rejected]: (state, action) => {
                state.isLoading = false;
                state.isFuseError = action.error;
                state.isOverCached = false;
            }
        };
    }
}