import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fetchWrapper } from '_helpers'
import { getApiUrl } from '_helpers/url'

const name = 'links'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, extraReducers })

export const linkActions = { ...slice.actions, ...extraActions }
export const linksReducer = slice.reducer

function createInitialState() {
  return {
    links: {},
    thisLink: {},
  }
}

function createExtraActions() {
  const baseUrl = getApiUrl(`/api/linksapp`)

  return {
    destroyThisLink: destroyThisLink(),
    saveNewLink: saveNewLink(),
    getThisLink: getThisLink(),
    getAllLinks: getAllLinks(),
    editThisLink: editThisLink(),
  }

  function destroyThisLink() {
    return createAsyncThunk(
      `/${name}/destroyThisLink`,
      async ({ link_id }) =>
        await fetchWrapper.post(`${baseUrl}/links/destroyThisLink`, { link_id })
    )
  }
  function editThisLink() {
    return createAsyncThunk(
      `/${name}/editThisLink`,
      async ({ link, displayName, link_id, linkType }) =>
        await fetchWrapper.post(`${baseUrl}/links/editThisLink`, {
          link,
          displayName,
          link_id,
          linkType,
        })
    )
  }
  function saveNewLink() {
    return createAsyncThunk(
      `/${name}/saveNewLink`,
      async ({ link, displayName, linkType }) =>
        await fetchWrapper.post(`${baseUrl}/links/saveNewLink`, {
          link,
          displayName,
          linkType,
        })
    )
  }

  function getThisLink() {
    return createAsyncThunk(
      `/${name}/getThisLink`,
      async (id) => await fetchWrapper.get(`${baseUrl}/links/` + id)
    )
  }

  function getAllLinks() {
    return createAsyncThunk(
      `/${name}/getAllLinks`,
      async () => await fetchWrapper.get(`${baseUrl}/links`)
    )
  }
}

function createExtraReducers() {
  return {
    ...destroyThisLink(),
    ...saveNewLink(),
    ...getThisLink(),
    ...getAllLinks(),
    ...editThisLink(),
  }

  function editThisLink() {
    var { pending, fulfilled, rejected } = extraActions.editThisLink
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully edited link!') {
          state.links = action.payload.links
        } else {
          state.links = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function destroyThisLink() {
    var { pending, fulfilled, rejected } = extraActions.destroyThisLink
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully deleted link!') {
          state.links = action.payload.links
        } else {
          state.links = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function getThisLink() {
    var { pending, fulfilled, rejected } = extraActions.getThisLink
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.thisLink = action.payload.link
      },
      [rejected]: (state, action) => {},
    }
  }
  function getAllLinks() {
    var { pending, fulfilled, rejected } = extraActions.getAllLinks
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved links') {
          state.links = action.payload.links
        } else {
          state.links = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function saveNewLink() {
    var { pending, fulfilled, rejected } = extraActions.saveNewLink
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.links = action.payload.links
      },
      [rejected]: (state, action) => {},
    }
  }
}
