import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWrapper } from '_helpers'
import { getApiUrl } from '_helpers/url'

const name = 'statsTest'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, extraReducers })

export const statsTestActions = { ...slice.actions, ...extraActions }
export const statsTestReducer = slice.reducer

function createInitialState() {
  return {
    statsTest: {},
  }
}

function createExtraActions() {
  const baseUrl = getApiUrl(`/api/teststats/FMX/stats`)

  return {
    userStats: userStats(),
    removeThisLine: removeThisLine(),
  }

  function userStats() {
    return createAsyncThunk(
      `${name}/userstats`,
      async ({ siteName }) => await fetchWrapper.get(baseUrl + '/' + siteName)
    )
  }
  function removeThisLine() {
    return createAsyncThunk(
      `${name}/removeThisLine`,
      async ({ id }) =>
        await fetchWrapper.delete(baseUrl + '/removeThisLine/' + id)
    )
  }
}

function createExtraReducers() {
  return {
    ...userStats(),
    ...removeThisLine(),
  }

  function removeThisLine() {
    var { pending, fulfilled, rejected } = extraActions.removeThisLine
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }
  function userStats() {
    var { pending, fulfilled, rejected } = extraActions.userStats
    return {
      [pending]: (state) => {
        state.statsTest = { loading: true }
      },
      [fulfilled]: (state, action) => {
        state.statsTest = action.payload
      },
      [rejected]: (state, action) => {
        state.statsTest = { error: action.error }
      },
    }
  }
}
