import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchWrapper } from '_helpers';

const name = 'helpdesk';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const helpdeskActions = { ...slice.actions, ...extraActions };
export const helpdeskReducer = slice.reducer;

function createInitialState() {
    return {
        feedbackList: {},
        taskList: {},
        thisFeedback: {},
        thisTask: {},
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_helpdesk_API_URL;

    return {
        getFeedback: getFeedback(),
        getFeedbackReduced: getFeedbackReduced(),
        getTasks: getTasks(),
        getThisFeedback: getThisFeedback(),
        getThisTask: getThisTask(),
        destroyFeedback: destroyFeedback(),
        destroyTask: destroyTask(),
        saveNewFeedback: saveNewFeedback(),
        saveNewTask: saveNewTask(),
        updateFeedback: updateFeedback(),
        updateTask: updateTask(),
        updateTaskStatus: updateTaskStatus(),
        setTaskCompleteState: setTaskCompleteState(),
        updateTaskType: updateTaskType(),
    };

    function getFeedback() {
        return createAsyncThunk(
            `/${name}/getFeedback`,
            async () => await fetchWrapper.get(`${baseUrl}`)
        );
    }
    function getFeedbackReduced() {
        return createAsyncThunk(
            `/${name}/getFeedbackReduced`,
            async (dataUrl) => await fetchWrapper.get(`${dataUrl}`)
        );
    }
    function getTasks() {
        return createAsyncThunk(
            `/${name}/getTasks`,
            async () => await fetchWrapper.get(`${baseUrl}/tasks`)
        );
    }

    function getThisFeedback() {
        return createAsyncThunk(
            `/${name}/getThisFeedback`,
            async ({helpdesk_id}) => await fetchWrapper.get(`${baseUrl}/feedbackget/` + helpdesk_id)
        );
    }

    function getThisTask() {
        return createAsyncThunk(
            `/${name}/getThisTask`,
            async ({helpdesk_tasks_id}) => await fetchWrapper.get(`${baseUrl}/taskget/` + helpdesk_tasks_id)
        );
    }
    
    function destroyFeedback() {
        return createAsyncThunk(
            `/${name}/destroyFeedback`,
            async ({ helpdesk_id }) => await fetchWrapper.post(`${baseUrl}/destroyFeedback`, { helpdesk_id })
        );
    }
    function destroyTask() {
        return createAsyncThunk(
            `/${name}/destroyTask`,
            async ({ helpdesk_tasks_id }) => await fetchWrapper.post(`${baseUrl}/destroyTask`, { helpdesk_tasks_id })
        );
    }

    function saveNewFeedback() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/saveNewFeedback`,
            async (data) => await 
            axios.post(`${baseUrl}/saveNewFeedback`, data, config).then(function (response) {
                return response.data
            })
        );
    }

    function saveNewTask() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/saveNewTask`,
            async (data) => await 
            axios.post(`${baseUrl}/saveNewTask`, data, config).then(function (response) {
                return response.data
            })
        );
    }
    
    function updateFeedback() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/updateFeedback`,
            async (data) => await 
            axios.post(`${baseUrl}/updateFeedback`, data, config).then(function (response) {
                return response.data
            })
        );
    }

    function updateTask() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/updateTask`,
            async (data) => await 
            axios.post(`${baseUrl}/updateTask`, data, config).then(function (response) {
                return response.data
            })
        );
    }

    function updateTaskStatus() {
        return createAsyncThunk(
            `/${name}/updateTaskStatus`,
            async ( {helpdesk_tasks_id, status} ) => await fetchWrapper.post(`${baseUrl}/updateTaskStatus`,  {helpdesk_tasks_id, status} )
        );
    }
    function setTaskCompleteState() {
        return createAsyncThunk(
            `/${name}/setTaskCompleteState`,
            async ( {helpdesk_tasks_id, state} ) => await fetchWrapper.post(`${baseUrl}/setTaskCompleteState`,  {helpdesk_tasks_id, state} )
        );
    }

    
    function updateTaskType() {
        return createAsyncThunk(
            `/${name}/updateTaskType`,
            async ( {helpdesk_tasks_id, type} ) => await fetchWrapper.post(`${baseUrl}/updateTaskType`,  {helpdesk_tasks_id, type} )
        );
    }
}

function createExtraReducers() {
    return {
        ...getFeedback(),
        ...getTasks(),
        ...getFeedbackReduced(),
        ...getThisFeedback(),
        ...getThisTask(),
        ...saveNewFeedback(),
        ...saveNewTask(),
        ...destroyFeedback(),
        ...destroyTask(),
        ...updateFeedback(),
        ...updateTask(),
        ...updateTaskStatus(),
        ...setTaskCompleteState(),
        ...updateTaskType(),
    };


    function getFeedback() {
        var { pending, fulfilled, rejected } = extraActions.getFeedback;
        return {
            [pending]: (state) => {
                state.feedbackList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved feedback') {
                    state.feedbackList = action.payload.feedback;
                }
            },
            [rejected]: (state, action) => {
                state.feedbackList = { error: action.error };
            }
        };
    }
    function getTasks() {
        var { pending, fulfilled, rejected } = extraActions.getTasks;
        return {
            [pending]: (state) => {
                state.taskList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved tasks') {
                    state.taskList = action.payload.feedbackTasks;
                }
            },
            [rejected]: (state, action) => {
                state.taskList = { error: action.error };
            }
        };
    }
    function getFeedbackReduced() {
        var { pending, fulfilled, rejected } = extraActions.getFeedbackReduced;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved feedback') {
                    state.feedbackList = action.payload.feedback;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getThisFeedback() {
        var { pending, fulfilled, rejected } = extraActions.getThisFeedback;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                state.thisFeedback = action.payload.thisFeedback;
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getThisTask() {
        var { pending, fulfilled, rejected } = extraActions.getThisTask;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                state.thisTask = action.payload.thisTask;
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    
    function destroyFeedback() {
        var { pending, fulfilled, rejected } = extraActions.destroyFeedback;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Successfully deleted feedback!") {
                    
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function destroyTask() {
        var { pending, fulfilled, rejected } = extraActions.destroyTask;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Successfully deleted feedback!") {
                    
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function saveNewFeedback() {
        var { pending, fulfilled, rejected } = extraActions.saveNewFeedback;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Feedback successfully created') {
                    
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function saveNewTask() {
        var { pending, fulfilled, rejected } = extraActions.saveNewTask;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Task successfully created') {
                    
                } else {
                    
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    
    function updateFeedback() {
        var { pending, fulfilled, rejected } = extraActions.updateFeedback;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Feedback successfully updated') {

                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function updateTask() {
        var { pending, fulfilled, rejected } = extraActions.updateTask;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Task successfully updated') {

                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function updateTaskStatus() {
        var { pending, fulfilled, rejected } = extraActions.updateTaskStatus;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Task successfully updated') {

                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function setTaskCompleteState() {
        var { pending, fulfilled, rejected } = extraActions.setTaskCompleteState;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Task successfully updated') {

                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    

    function updateTaskType() {
        var { pending, fulfilled, rejected } = extraActions.updateTaskType;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Task successfully updated') {

                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }


}