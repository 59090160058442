import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'ai';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const aiActions = { ...slice.actions, ...extraActions };
export const aiReducer = slice.reducer;

function createInitialState() {
    return {
        loading: false,
        error: null,
        aiSearch: [],
        hubAIConversation: [],
        aiQuery: [],
        conversations: [],
        thisConversation: [],
        conversationId: null,
        conversationTitle: null,
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_AI_API_URL;

    return {
        ...basicActions(),
    };

    function basicActions() {
        return {
            aiSearch: createAsyncThunk(
                `/${name}/aiSearch`,
                async (data) => await fetchWrapper.post(`${baseUrl}/ai/process-airequest`, data)
            ),
            getConversations: createAsyncThunk(
                `/${name}/getConversations`,
                async () => await fetchWrapper.get(`${baseUrl}/ai/get-conversations`)
            ),
            getThisConversation: createAsyncThunk(
                `/${name}/getThisConversation`,
                async (id) => await fetchWrapper.get(`${baseUrl}/ai/get-conversation/${id}`)
            ),
            hubAIInteraction: createAsyncThunk(
                `/${name}/hubAIInteraction`,
                async (data) => await fetchWrapper.post(`${baseUrl}/ai/assistantInteraction`, data)
            ),
            deleteThread: createAsyncThunk(
                `/${name}/deleteThread`,
                async (id) => await fetchWrapper.get(`${baseUrl}/ai/deleteThread/${id}`)
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
    };

    function basicReducers() {
        return {
            ...aiSearch(),
            ...getConversations(),
            ...getThisConversation(),
            ...hubAIInteraction(),
            ...deleteThread(),
        };
    }

    function aiSearch() {
        var { pending, fulfilled, rejected } = extraActions.aiSearch;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully asked AI') {
                    state.aiSearch = action.payload.responseFrom;
                } else {
                    state.aiSearch = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function deleteThread() {
        var { pending, fulfilled, rejected } = extraActions.deleteThread;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully deleted thread') {
                    
                } else {
                    
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getConversations() {
        var { pending, fulfilled, rejected } = extraActions.getConversations;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully fetched conversations') {
                    state.conversations = action.payload.conversations;
                } else {
                    state.conversations = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getThisConversation() {
        var { pending, fulfilled, rejected } = extraActions.getThisConversation;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully fetched conversation') {
                    state.thisConversation = action.payload.conversation;
                } else {
                    state.thisConversation = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function hubAIInteraction() {
        var { pending, fulfilled, rejected } = extraActions.hubAIInteraction;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully interacted with assistant') {
                    state.hubAIConversation = action.payload.responseFrom?.original?.assistant_response;
                    state.conversationId = action.payload.conversationId;
                    state.conversationTitle = action.payload.chatTitle;
                } else {
                    state.hubAIConversation = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }
}