import { createSlice } from '@reduxjs/toolkit';

const name = 'alert';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const alertActions = { ...slice.actions };
export const alertReducer = slice.reducer;

function createInitialState() {
    return { values: [] }
}

function createReducers() {
    return {
        success: createAlert('green'),
        error: createAlert('red'),
        info: createAlert('blue'),
        warning: createAlert('orange'),
        clear
    };

    function createAlert(alertType) {
        return (state, action) => {
            state.values.push({
                id: Math.random(),
                type: alertType,
                title: action.payload?.title || null,
                message: action.payload?.message || action.payload,
                showAfterRedirect: action.payload?.showAfterRedirect
            });
        };
    }

    function clear(state, action) {
        state.values = state.values.filter(alert => alert.id !== action.payload);
    }
}