import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from './_store'
import { App } from './App'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ToastProvider } from 'use-toast-mui'

// import * as Sentry from '@sentry/react'
// Sentry.init({
//   dsn: 'https://66c8fecc0790ef031a1ebfc8a38bf560@o4507566441168896.ingest.de.sentry.io/4507566443069520',
//   integrations: [
//     Sentry.feedbackIntegration({
//       // Additional SDK configuration goes in here, for example:
//       colorScheme: 'system',
//       formTitle: 'Give Feedback',
//       triggerLabel: 'Report Bug / Feedback',
//       enableScreenshot: true,
//       submitButtonLabel: 'Send Feedback',
//       showEmail: false,
//       isNameRequired: true,
//     }),
//   ],
// })

const root = ReactDOM.createRoot(document.getElementById('root'))
const theme = createTheme({
  typography: {
    fontFamily:
      'sofia-pro, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, sans-serif',
  },
  palette: {
    primary: {
      main: '#033051',
    },
    secondary: {
      light: '#FFEC4B',
      main: '#df7737',
      contrastText: '#033051',
    },
    goRed: {
      main: '#E30613',
      contrastText: '#fff',
    },
    goOrange: {
      main: '#df7737',
      contrastText: '#fff',
    },
    goGreen: {
      main: '#009B7D',
      contrastText: '#fff',
    },
    goForest: {
      main: '#004E3F',
      contrastText: '#fff',
    },
    goYellow: {
      main: '#FFD005',
      contrastText: '#444',
    },
    custom: {
      light: '#FFFCC8',
      main: '#009B7D',
      dark: '#E30613',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
})

root.render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </ThemeProvider>
    </Router>
  </Provider>
)
