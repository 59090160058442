import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import axios from 'axios';

const name = 'enviro';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const enviroActions = { ...slice.actions, ...extraActions };
export const enviroReducer = slice.reducer;

function createInitialState() {
    return {
        enviroDash: {},
        enviroTeam: {},
        enviroNewsList: {},
        enviroNewsItem: {},
        usersList: {},
        overviewDeets: {},
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_enviro_API_URL;

    return {
        getDashDetails: getDashDetails(),
        getEnvOverview: getEnvOverview(),
        getEnvNews: getEnvNews(),
        showEnvNews: showEnvNews(),
        addEnvNews: addEnvNews(),
        editEnvNews: editEnvNews(),
        destroyEnvNews: destroyEnvNews(),
        getEnvTeam: getEnvTeam(),
        saveNewTeamMember: saveNewTeamMember(),
        editThisTeamMember: editThisTeamMember(),
        destroyTeamMember: destroyTeamMember(),
        getUserList: getUserList(),
        updateEnvInfo: updateEnvInfo(),
    };

    function getDashDetails() {
        return createAsyncThunk(
            `/${name}/getDashDetails`,
            async () => await fetchWrapper.get(`${baseUrl}`)
        );
    }
    function getEnvOverview() {
        return createAsyncThunk(
            `/${name}/getEnvOverview`,
            async () => await fetchWrapper.get(`${baseUrl}/getEnvOverview`)
        );
    }
    
    function getUserList() {
        return createAsyncThunk(
            `/${name}/getUserList`,
            async (sendingTo) => await fetchWrapper.get(`${baseUrl}/getUserList/` + sendingTo)
        );
    }

    function getEnvNews() {
        return createAsyncThunk(
            `/${name}/getEnvNews`,
            async () => await fetchWrapper.get(`${baseUrl}/getEnvNews`)
        );
    }

    function getEnvTeam() {
        return createAsyncThunk(
            `/${name}/getEnvTeam`,
            async () => await fetchWrapper.get(`${baseUrl}/getEnvTeam`)
        );
    }

    function showEnvNews() {
        return createAsyncThunk(
            `/${name}/showEnvNews`,
            async (id) => await fetchWrapper.get(`${baseUrl}/showEnvNews/${id}`)
        );
    }

    function updateEnvInfo() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateEnvInfo`,
            async (data) => await
                axios.post(`${baseUrl}/updateEnvInfo`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
        
    function addEnvNews() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addEnvNews`,
            async (data) => await
                axios.post(`${baseUrl}/addEnvNews`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function editEnvNews() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editEnvNews`,
            async (data) => await
                axios.post(`${baseUrl}/editEnvNews`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyEnvNews() {
        return createAsyncThunk(
            `/${name}/destroyEnvNews`,
            async ({ tribe_env_news_id }) => await fetchWrapper.post(`${baseUrl}/destroyEnvNews`, { tribe_env_news_id })
        );
    }
    
    function saveNewTeamMember() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/saveNewTeamMember`,
            async (data) => await
                axios.post(`${baseUrl}/saveNewTeamMember`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function editThisTeamMember() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editThisTeamMember`,
            async (data) => await
                axios.post(`${baseUrl}/editThisTeamMember`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyTeamMember() {
        return createAsyncThunk(
            `/${name}/destroyTeamMember`,
            async ({ member_id }) => await fetchWrapper.post(`${baseUrl}/destroyTeamMember`, { member_id })
        );
    }
}


function createExtraReducers() {
    return {
        ...getDashDetails(),
        ...getEnvOverview(),
        ...getEnvNews(),
        ...showEnvNews(),
        ...addEnvNews(),
        ...editEnvNews(),
        ...destroyEnvNews(),
        ...getEnvTeam(),
        ...saveNewTeamMember(),
        ...editThisTeamMember(),
        ...destroyTeamMember(),
        ...getUserList(),
        ...updateEnvInfo(),
    };

    
    function getUserList() {
        var { pending, fulfilled, rejected } = extraActions.getUserList;
        return {
            [pending]: (state) => {
                state.usersList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.usersList = action.payload;
            },
            [rejected]: (state, action) => {
                state.usersList = { error: action.error };
            }
        };
    }

    function getDashDetails() {
        var { pending, fulfilled, rejected } = extraActions.getDashDetails;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Env Dash Details retrieved') {
                    state.enviroDash = action.payload.dash;
                } else {
                    state.enviroDash = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getEnvOverview() {
        var { pending, fulfilled, rejected } = extraActions.getEnvOverview;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Tribe Enviromental Details retrieved') {
                    state.overviewDeets = action.payload.dash;
                } else {
                    state.overviewDeets = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getEnvNews() {
        var { pending, fulfilled, rejected } = extraActions.getEnvNews;
        return {
            [pending]: (state) => {
                state.enviroNewsList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved enviro news') {
                    state.enviroNewsList = action.payload.envNewsList;
                } else {
                    state.enviroNewsList = null;
                }
            },
            [rejected]: (state, action) => {
                state.enviroNewsList = { error: action.error };
            }
        };
    }

    function showEnvNews() {
        var { pending, fulfilled, rejected } = extraActions.showEnvNews;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved env news item') {
                    state.enviroNewsItem = action.payload.envNewsItem;
                } else {
                    state.enviroNewsItem = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function addEnvNews() {
        var { pending, fulfilled, rejected } = extraActions.addEnvNews;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function editEnvNews() {
        var { pending, fulfilled, rejected } = extraActions.editEnvNews;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function destroyEnvNews() {
        var { pending, fulfilled, rejected } = extraActions.destroyEnvNews;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function updateEnvInfo() {
        var { pending, fulfilled, rejected } = extraActions.updateEnvInfo;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    
    function getEnvTeam() {
        var { pending, fulfilled, rejected } = extraActions.getEnvTeam;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved team') {
                    state.enviroTeam = action.payload.envTeam;
                } else {
                    state.enviroTeam = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function saveNewTeamMember() {
        var { pending, fulfilled, rejected } = extraActions.saveNewTeamMember;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function editThisTeamMember() {
        var { pending, fulfilled, rejected } = extraActions.editThisTeamMember;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function destroyTeamMember() {
        var { pending, fulfilled, rejected } = extraActions.destroyTeamMember;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    
}
