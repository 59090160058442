import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWrapper } from '_helpers'
import { getApiUrl } from '_helpers/url'

const name = 'users'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, extraReducers })

export const userActions = { ...slice.actions, ...extraActions }
export const usersReducer = slice.reducer

function createInitialState() {
  return {
    loading: false,
    users: {},
    thisUser: {},
    notifications: {},
    alerts: {},
    usersDrop: {},
    usersDropStaff: {},
    usersRoles: [],
    usersDropList: {},
    usersDropMessagingList: {},
    userCalendar: {},
    singularCalendar: {},
    userChats: {},
    userMail: {},
    homeNews: {},
    links: {},
    mdNews: {},
    homevacancies: {},
    homeNotices: {},
    usersList: {},
    usersGrouped: {},
    userCount: {},
    siteActivity: {},
    usersSearched: {},
    kudosList: {},
    HRData: [],
  }
}

function createExtraActions() {
  const baseUrl = getApiUrl(`api/users`)

  return {
    getAll: getAll(),
    getUsersReduced: getUsersReduced(),
    getUsersFilterReduced: getUsersFilterReduced(),
    fetchActivity: fetchActivity(),
    fetchActivityReduced: fetchActivityReduced(),
    getThis: getThis(),
    getThisShortened: getThisShortened(),
    usersDrop: usersDrop(),
    usersDropStaff: usersDropStaff(),
    usersDropAll: usersDropAll(),
    usersDropList: usersDropList(),
    usersRolesAll: usersRolesAll(),
    usersRolesOne: usersRolesOne(),
    usersDropMessaging: usersDropMessaging(),
    fetchCalendar: fetchCalendar(),
    fetchUserEvents: fetchUserEvents(),
    fetchTeams: fetchTeams(),
    fetchMail: fetchMail(),
    getHomeInfo: getHomeInfo(),
    markAllRead: markAllRead(),
    getNotifications: getNotifications(),
    settingUserAsAgreed: settingUserAsAgreed(),
    updateNotificationArray: updateNotificationArray(),
    getDirectoryUsersReduced: getDirectoryUsersReduced(),
    getDirectoryUsersFilterReduced: getDirectoryUsersFilterReduced(),
    getUsersInvite: getUsersInvite(),
    deleteFormSubmission: deleteFormSubmission(),
    HRData: HRData(),
  }

  function deleteFormSubmission() {
    return createAsyncThunk(
      `/${name}/deleteFormSubmission`,
      async (id) =>
        await fetchWrapper.get(`${baseUrl}/deleteFormSubmission/` + id)
    )
  }

  function updateNotificationArray() {
    return createAsyncThunk(`/${name}/updateNotificationArray`, async (arg) => {
      return arg
    })
  }

  function getUsersInvite() {
    return createAsyncThunk(
      `/${name}/getUsersInvite`,
      async ({ sendingTo }) =>
        await fetchWrapper.get(`${baseUrl}/getUsersInvite/` + sendingTo)
    )
  }

  function getUsersReduced() {
    return createAsyncThunk(
      `/${name}/getUsersReduced`,
      async ({ pageSet, limitSet }) =>
        await fetchWrapper.get(
          `${baseUrl}/getUsersReduced?page=` +
            String(pageSet) +
            '&limit=' +
            String(limitSet)
        )
    )
  }
  function getUsersFilterReduced() {
    return createAsyncThunk(
      `/${name}/getUsersFilterReduced`,
      async ({ filter, type, pageSet, limitSet }) =>
        await fetchWrapper.get(
          `${baseUrl}/getUsersReduced?filter=` +
            String(filter) +
            '&type=' +
            String(type) +
            '&page=' +
            String(pageSet) +
            '&limit=' +
            String(limitSet)
        )
    )
  }

  function getDirectoryUsersReduced() {
    return createAsyncThunk(
      `/${name}/getDirectoryUsersReduced`,
      async (incoming) =>
        await fetchWrapper.get(`${baseUrl}/getDirectory` + incoming)
    )
  }

  function getDirectoryUsersFilterReduced() {
    return createAsyncThunk(
      `/${name}/getDirectoryUsersFilterReduced`,
      async ({ filterSet, locationSet, pageSet, orderSet }) =>
        await fetchWrapper.get(
          `${baseUrl}/getDirectory?filter=` +
            String(filterSet) +
            '&location=' +
            String(locationSet) +
            '&limit=' +
            String(pageSet) +
            '&order=' +
            String(orderSet)
        )
    )
  }

  function fetchActivity() {
    return createAsyncThunk('notices/fetchActivity', async (page) => {
      const url = `${baseUrl}/fetchActivity${page ? `?page=${page}` : ''}`
      return await fetchWrapper.get(url)
    })
  }

  function fetchActivityReduced() {
    return createAsyncThunk(
      `/${name}/fetchActivityReduced`,
      async (dataUrl) => await fetchWrapper.get(`${dataUrl}`)
    )
  }

  function getHomeInfo() {
    return createAsyncThunk(
      `${name}/getHomeInfo`,
      async () => await fetchWrapper.get(getApiUrl('/api/users/getHomeInfo'))
    )
  }

  function markAllRead() {
    return createAsyncThunk(
      `/${name}/markAllRead`,
      async () => await fetchWrapper.get(`${baseUrl}/markAllRead`)
    )
  }

  function settingUserAsAgreed() {
    return createAsyncThunk(
      `/${name}/settingUserAsAgreed`,
      async () => await fetchWrapper.get(`${baseUrl}/settingUserAsAgreed`)
    )
  }

  function fetchMail() {
    return createAsyncThunk(
      `${name}/fetchMail`,
      async () => await fetchWrapper.get(getApiUrl('/api/fetchMail'))
    )
  }

  function fetchCalendar() {
    return createAsyncThunk(
      `${name}/fetchCalendar`,
      async ({ startOfDate, endOfDate }) =>
        startOfDate
          ? await fetchWrapper.get(
              getApiUrl(
                '/api/fetchCalendar?startOfDate=' +
                  startOfDate +
                  '&endOfDate=' +
                  endOfDate
              )
            )
          : await fetchWrapper.get(getApiUrl('/api/fetchCalendar'))
    )
  }

  function fetchUserEvents() {
    return createAsyncThunk(
      `${name}/fetchUserEvents`,
      async ({ startOfDate, endOfDate, userID }) =>
        startOfDate
          ? await fetchWrapper.get(
              getApiUrl(
                '/api/fetchUserEvents/' +
                  userID +
                  '?startOfDate=' +
                  startOfDate +
                  '&endOfDate=' +
                  endOfDate
              )
            )
          : await fetchWrapper.get(getApiUrl('/api/fetchUserEvents/' + userID))
    )
  }

  function fetchTeams() {
    return createAsyncThunk(
      `${name}/fetchTeams`,
      async () => await fetchWrapper.get(getApiUrl('api/fetchTeams'))
    )
  }

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async () => await fetchWrapper.get(baseUrl)
    )
  }

  function getThis() {
    return createAsyncThunk(
      `${name}/getThis`,
      async (userID) => await fetchWrapper.get(baseUrl + '/getUser/' + userID)
    )
  }

  function getThisShortened() {
    return createAsyncThunk(
      `${name}/getThisShortened`,
      async (userID) =>
        await fetchWrapper.get(baseUrl + '/getThisShortened/' + userID)
    )
  }

  function usersDrop() {
    return createAsyncThunk(
      `${name}/usersDrop`,
      async () => await fetchWrapper.get(baseUrl + '/drop/usersDrop')
    )
  }
  function usersDropStaff() {
    return createAsyncThunk(
      `${name}/usersDropStaff`,
      async () => await fetchWrapper.get(baseUrl + '/drop/usersDropStaff')
    )
  }

  function usersDropAll() {
    return createAsyncThunk(
      `${name}/usersDropAll`,
      async () => await fetchWrapper.get(baseUrl + '/drop/usersDropAll')
    )
  }

  function usersRolesAll() {
    return createAsyncThunk(
      `${name}/usersRolesAll`,
      async () => await fetchWrapper.get(baseUrl + '/drop/usersRolesAll')
    )
  }

  function usersRolesOne() {
    return createAsyncThunk(
      `${name}/usersRolesOne`,
      async (id) =>
        await fetchWrapper.get(baseUrl + '/drop/usersRolesOne/' + id)
    )
  }

  function usersDropList() {
    return createAsyncThunk(
      `${name}/usersDropList`,
      async () => await fetchWrapper.get(baseUrl + '/drop/usersDrop')
    )
  }
  function usersDropMessaging() {
    return createAsyncThunk(
      `${name}/usersDropMessaging`,
      async () => await fetchWrapper.get(baseUrl + '/drop/usersDropMessaging')
    )
  }

  function getNotifications() {
    return createAsyncThunk(
      `${name}/users/notifications`,
      async () => await fetchWrapper.get(baseUrl + '/notifications')
    )
  }

  function HRData() {
    return createAsyncThunk(
      `${name}/HRData`,
      async () => await fetchWrapper.get(baseUrl + '/HRData')
    )
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...getThis(),
    ...getThisShortened(),
    ...usersDrop(),
    ...usersDropStaff(),
    ...usersDropAll(),
    ...usersDropList(),
    ...usersRolesAll(),
    ...usersRolesOne(),
    ...usersDropMessaging(),
    ...getNotifications(),
    ...fetchCalendar(),
    ...fetchUserEvents(),
    ...fetchTeams(),
    ...fetchMail(),
    ...getHomeInfo(),
    ...markAllRead(),
    ...settingUserAsAgreed(),
    ...getUsersReduced(),
    ...getUsersFilterReduced(),
    ...fetchActivity(),
    ...fetchActivityReduced(),
    ...updateNotificationArray(),
    ...getDirectoryUsersReduced(),
    ...getDirectoryUsersFilterReduced(),
    ...getUsersInvite(),
    ...deleteFormSubmission(),
    ...HRData(),
  }

  function deleteFormSubmission() {
    var { pending, fulfilled, rejected } = extraActions.deleteFormSubmission
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully deleted submission') {
        } else {
          state.thisUser = []
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getUsersInvite() {
    var { pending, fulfilled, rejected } = extraActions.getUsersInvite
    return {
      [pending]: (state) => {
        state.usersSearched = { loading: true }
      },
      [fulfilled]: (state, action) => {
        state.usersSearched = action.payload
      },
      [rejected]: (state, action) => {
        state.usersSearched = { error: action.error }
      },
    }
  }

  function getDirectoryUsersReduced() {
    var { pending, fulfilled, rejected } = extraActions.getDirectoryUsersReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Users retrieved') {
          state.usersList = action.payload.users
        } else {
          state.vacancy = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getDirectoryUsersFilterReduced() {
    var { pending, fulfilled, rejected } =
      extraActions.getDirectoryUsersFilterReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Users retrieved') {
          // state.usersList = action.payload.users;
          state.usersGrouped = action.payload.grouped
          state.userCount = action.payload.staffCount
        } else {
          state.usersList = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function fetchActivity() {
    var { pending, fulfilled, rejected } = extraActions.fetchActivity
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved tracker') {
          state.siteActivity = action.payload.tracker
        } else {
          state.siteActivity = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function fetchActivityReduced() {
    var { pending, fulfilled, rejected } = extraActions.fetchActivityReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved tracker') {
          state.siteActivity = action.payload.tracker
        } else {
          state.siteActivity = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function markAllRead() {
    var { pending, fulfilled, rejected } = extraActions.markAllRead
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.notifications = action.payload
      },
      [rejected]: (state, action) => {},
    }
  }

  function settingUserAsAgreed() {
    var { pending, fulfilled, rejected } = extraActions.settingUserAsAgreed
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }

  function getHomeInfo() {
    var { pending, fulfilled, rejected } = extraActions.getHomeInfo
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Home information retrieved') {
          state.homeNews = action.payload.dashData.news
          state.links = action.payload.dashData.links
          state.homevacancies = action.payload.dashData.vacancies
          state.homeNotices = action.payload.dashData.notices
          state.notifications = action.payload.dashData
          state.alerts = action.payload.dashData.alerts
          state.mdNews = action.payload.dashData.MDNews
          state.kudosList = action.payload.dashData.kudos
        } else {
          state.homeNews = action.payload.news
          state.links = action.payload.links
          state.homevacancies = action.payload.vacancies
          state.homeNotices = action.payload.notices
          // state.notifications = action.payload;
          state.mdNews = action.payload.MDNews
          state.kudosList = action.payload.kudos
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function fetchMail() {
    var { pending, fulfilled, rejected } = extraActions.fetchMail
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.userMail = action.payload.mailMessages
      },
      [rejected]: (state, action) => {},
    }
  }

  function fetchTeams() {
    var { pending, fulfilled, rejected } = extraActions.fetchTeams
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.userChats = action.payload.teamChat
      },
      [rejected]: (state, action) => {},
    }
  }

  function fetchCalendar() {
    var { pending, fulfilled, rejected } = extraActions.fetchCalendar
    return {
      [pending]: (state) => {
        state.userCalendar = []
      },
      [fulfilled]: (state, action) => {
        state.userCalendar = action.payload.userCal
      },
      [rejected]: (state, action) => {
        state.userCalendar = []
      },
    }
  }

  function fetchUserEvents() {
    var { pending, fulfilled, rejected } = extraActions.fetchUserEvents
    return {
      [pending]: (state) => {
        state.singularCalendar = []
      },
      [fulfilled]: (state, action) => {
        state.singularCalendar = action.payload.userCal
      },
      [rejected]: (state, action) => {
        state.singularCalendar = []
      },
    }
  }

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll
    return {
      [pending]: (state) => {
        state.users = { loading: true }
      },
      [fulfilled]: (state, action) => {
        state.users = action.payload
      },
      [rejected]: (state, action) => {
        state.users = { error: action.error }
      },
    }
  }

  function getThis() {
    var { pending, fulfilled, rejected } = extraActions.getThis
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'User retrieved') {
          state.thisUser = action.payload.user
        } else {
          state.thisUser = []
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getThisShortened() {
    var { pending, fulfilled, rejected } = extraActions.getThisShortened
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }

  function usersDrop() {
    var { pending, fulfilled, rejected } = extraActions.usersDrop
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.usersDrop = action.payload.users
      },
      [rejected]: (state, action) => {},
    }
  }
  function usersDropStaff() {
    var { pending, fulfilled, rejected } = extraActions.usersDropStaff
    return {
      [pending]: (state) => {
        state.loading = true
      },
      [fulfilled]: (state, action) => {
        state.usersDropStaff = action.payload.users
        state.loading = false
      },
      [rejected]: (state, action) => {
        state.loading = false
      },
    }
  }

  function usersDropAll() {
    var { pending, fulfilled, rejected } = extraActions.usersDropAll
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.usersDropStaff = action.payload.users
      },
      [rejected]: (state, action) => {},
    }
  }

  function usersRolesAll() {
    var { pending, fulfilled, rejected } = extraActions.usersRolesAll
    return {
      [pending]: (state) => {
        state.loading = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === "here's all the data you requested!") {
          state.usersRoles = action.payload.roles
          state.loading = false
        }
      },
      [rejected]: (state, action) => {
        state.loading = false
      },
    }
  }

  function usersRolesOne() {
    var { pending, fulfilled, rejected } = extraActions.usersRolesOne
    return {
      [pending]: (state) => {
        state.loading = true
      },
      [fulfilled]: (state, action) => {
        state.loading = false
      },
      [rejected]: (state, action) => {
        state.loading = false
      },
    }
  }

  function usersDropList() {
    var { pending, fulfilled, rejected } = extraActions.usersDropList
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.usersDropList = action.payload
      },
      [rejected]: (state, action) => {},
    }
  }

  function usersDropMessaging() {
    var { pending, fulfilled, rejected } = extraActions.usersDropMessaging
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.usersDropMessagingList = action.payload.users
      },
      [rejected]: (state, action) => {},
    }
  }

  function getNotifications() {
    var { pending, fulfilled, rejected } = extraActions.getNotifications
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.notifications = action.payload
      },
      [rejected]: (state, action) => {},
    }
  }

  function updateNotificationArray() {
    var { pending, fulfilled, rejected } = extraActions.updateNotificationArray
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        var array = [
          ...state.notifications.output.alerts,
          action.payload.notification.alert,
        ]
        void (state.notifications.output.alerts = array)
      },
      [rejected]: (state, action) => {},
    }
  }

  function getUsersFilterReduced() {
    var { pending, fulfilled, rejected } = extraActions.getUsersFilterReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Users retrieved') {
          state.usersList = action.payload.users
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getUsersReduced() {
    var { pending, fulfilled, rejected } = extraActions.getUsersReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Users retrieved') {
          state.usersList = action.payload.users
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function HRData() {
    var { pending, fulfilled, rejected } = extraActions.HRData
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.HRData = action.payload
      },
      [rejected]: (state, action) => {},
    }
  }
}
