export const getApiUrl = function (path) {
  let envUrl = process.env.REACT_APP_auth_API_URL
  if (!envUrl) {
    envUrl = 'https://start.goape.co.uk'
  }
  return removeTrailingSlash(envUrl) + '/' + removeSlashesOnBothEnds(path)
}

function removeSlashesOnBothEnds(path) {
  return removePrecedingSlash(removeTrailingSlash(path))
}

function removeTrailingSlash(path) {
  return path.endsWith('/') ? path.slice(0, -1) : path
}

function removePrecedingSlash(path) {
  return path.startsWith('/') ? path.slice(1) : path
}
