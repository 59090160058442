import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import axios from 'axios';

const name = 'hands';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const handsActions = { ...slice.actions, ...extraActions };
export const handsReducer = slice.reducer;

function createInitialState() {
    return {
        handsDash: {},
        handsDocs: {},
        handsReps: {},
        handsRepsTeam: {},
        handsRiskList: {},
        handsRisk: {},
        handsMentalHealth: {},
        handsNews: [],
        handsNewsOne: [],
        handsLinks: [],
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_hands_API_URL;

    return {
        getHandsNews: getHandsNews(),
        getHandsLinks: getHandsLinks(),
        storeNews: storeNews(),
        storeLink: storeLink(),
        getHandsDash: getHandsDash(),
        getHandsReps: getHandsReps(),
        getHandsRepsTeam: getHandsRepsTeam(),
        getHandsMH: getHandsMH(),
        getHandsRisks: getHandsRisks(),
        getHandsRisksReduced: getHandsRisksReduced(),
        getThisHandsRisk: getThisHandsRisk(),
        addHandsRep: addHandsRep(),
        addHandsRA: addHandsRA(),
        editHandsRep: editHandsRep(),
        destroyHandsRep: destroyHandsRep(),
        getHandsDocs: getHandsDocs(),
        destroyThisDoc: destroyThisDoc(),
        updateHandsInfo: updateHandsInfo(),
        getHandsNewsOne: getHandsNewsOne(),
    };

    function getHandsNews() {
        return createAsyncThunk(
            `/${name}/getHandsNews`,
            async (page = 1) => await fetchWrapper.get(`${baseUrl}/getHandsNews?per_page=5&page=${page}`)
        );
    }

    function getHandsLinks() {
        return createAsyncThunk(
            `/${name}/getHandsLinks`,
            async (page = 1) => await fetchWrapper.get(`${baseUrl}/getHandsLinks?per_page=5&page=${page}`)
        );
    }

    function getConfig() {
        const token = JSON.parse(localStorage.getItem('token'));
        return {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };
    }

    function storeNews() {
        const config = getConfig();
        return createAsyncThunk(
            '/hands/storeNews',
            async (formData) => {
                const response = await axios.post(`${baseUrl}/storeNews`, formData, config);
                return response.data;
            }
        );
    }

    function storeLink() {
        const config = getConfig();

        return createAsyncThunk(
            '/hands/storeLink',
            async (data) => {
                const response = await axios.post(`${baseUrl}/storeLink`, data, config);
                return response.data;
            }
        );
    }

    function getHandsNewsOne() {
        return createAsyncThunk(
            `/${name}/getHandsNewsOne`,
            async (id) => await fetchWrapper.get(`${baseUrl}/getHandsNewsOne/${id}`)
        );
    }

    function getHandsDash() {
        return createAsyncThunk(
            `/${name}/getHandsDash`,
            async () => await fetchWrapper.get(`${baseUrl}`)
        );
    }
    function getHandsDocs() {
        return createAsyncThunk(
            `/${name}/getHandsDocs`,
            async () => await fetchWrapper.get(`${baseUrl}/handsDocs`)
        );
    }
    function getHandsReps() {
        return createAsyncThunk(
            `/${name}/getHandsReps`,
            async () => await fetchWrapper.get(`${baseUrl}/getHandsReps`)
        );
    }
    function getHandsRepsTeam() {
        return createAsyncThunk(
            `/${name}/getHandsRepsTeam`,
            async () => await fetchWrapper.get(`${baseUrl}/getHandsRepsTeam`)
        );
    }

    function getHandsMH() {
        return createAsyncThunk(
            `/${name}/getHandsMH`,
            async () => await fetchWrapper.get(`${baseUrl}/getHandsMH`)
        );
    }
    function getHandsRisks() {
        return createAsyncThunk(
            `/${name}/getHandsRisks`,
            async () => await fetchWrapper.get(`${baseUrl}/getHandsRisks`)
        );
    }

    function getHandsRisksReduced() {
        return createAsyncThunk(
            `/${name}/getHandsRisks`,
            async (dataUrl) => await fetchWrapper.get(`${dataUrl}`)
        );
    }

    function getThisHandsRisk() {
        return createAsyncThunk(
            `/${name}/getThisHandsRisk`,
            async (id) => await fetchWrapper.get(`${baseUrl}/getThisHandsRisk/` + id)
        );
    }

    function updateHandsInfo() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateHandsInfo`,
            async (data) => await
                axios.post(`${baseUrl}/updateHandsInfo`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function addHandsRep() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addHandsRep`,
            async (data) => await
                axios.post(`${baseUrl}/addHandsRep`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function addHandsRA() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addHandsRA`,
            async (data) => await
                axios.post(`${baseUrl}/addHandsRA`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function editHandsRep() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editHandsRep`,
            async (data) => await
                axios.post(`${baseUrl}/editHandsRep`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function destroyHandsRep() {
        return createAsyncThunk(
            `/${name}/destroyHandsRep`,
            async ({ hands_reps_id }) => await fetchWrapper.post(`${baseUrl}/destroyHandsRep`, { hands_reps_id })
        );
    }

    function destroyThisDoc() {
        return createAsyncThunk(
            `/${name}/destroyThisDoc`,
            async ({ hands_doc_id }) => await fetchWrapper.post(`${baseUrl}/destroyThisDoc`, { hands_doc_id })
        );
    }

}

function createExtraReducers() {
    return {
        ...storeNews(),
        ...storeLink(),
        ...getHandsNews(),
        ...getHandsLinks(),
        ...getHandsDash(),
        ...getHandsDocs(),
        ...getHandsRisks(),
        ...getHandsRisksReduced(),
        ...getThisHandsRisk(),
        ...getHandsReps(),
        ...getHandsRepsTeam(),
        ...getHandsMH(),
        ...updateHandsInfo(),
        ...addHandsRep(),
        ...addHandsRA(),
        ...editHandsRep(),
        ...destroyHandsRep(),
        ...destroyThisDoc(),
        ...getHandsNewsOne()
    };

    function storeNews() {
        var { pending, fulfilled, rejected } = extraActions.storeNews;
        return {
            [pending]: (state) => {
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "News added successfully") {

                }
                state.fetching = false
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function storeLink() {
        var { pending, fulfilled, rejected } = extraActions.storeLink;
        return {
            [pending]: (state) => {
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Link added successfully") {

                }
                state.fetching = false
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }
    function getHandsNews() {
        var { pending, fulfilled, rejected } = extraActions.getHandsNews;
        return {
            [pending]: (state) => {
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS News') {
                    state.handsNews = action.payload.handsNews;
                } else {
                    state.handsNews = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function getHandsNewsOne() {
        var { pending, fulfilled, rejected } = extraActions.getHandsNewsOne;
        return {
            [pending]: (state) => {
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved H & S News') {
                    state.handsNewsOne = action.payload.handsNews[0];
                } else {
                    state.handsNewsOne = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }
    
    function getHandsLinks() {
        var { pending, fulfilled, rejected } = extraActions.getHandsLinks;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS Links') {
                    state.handsLinks = action.payload.handsLinks;
                } else {
                    state.handsLinks = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }
    
    function getHandsDash() {
        var { pending, fulfilled, rejected } = extraActions.getHandsDash;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS information') {
                    state.handsDash = action.payload.result;
                } else {
                    state.handsDash = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function getHandsRisksReduced() {
        var { pending, fulfilled, rejected } = extraActions.getHandsRisksReduced;
        return {
            [pending]: (state) => {
                state.handsRiskList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS risk assessments') {
                    state.handsRiskList = action.payload.handsRisks;
                } else {
                    state.handsRiskList = null;
                }
            },
            [rejected]: (state, action) => {
                state.notices = { error: action.error };
            }
        };
    }

    function getHandsRisks() {
        var { pending, fulfilled, rejected } = extraActions.getHandsRisks;
        return {
            [pending]: (state) => {
                state.handsRiskList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS risk assessments') {
                    state.handsRiskList = action.payload.handsRisks;
                } else {
                    state.handsRiskList = null;
                }

            },
            [rejected]: (state, action) => {
                state.notices = { error: action.error };
            }
        };
    }

    function getThisHandsRisk() {
        var { pending, fulfilled, rejected } = extraActions.getThisHandsRisk;
        return {
            [pending]: (state) => {
                state.handsRisk = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS risk assessment') {
                    state.handsRisk = action.payload.handsRisk;
                } else {
                    state.handsRisk = null;
                }

            },
            [rejected]: (state, action) => {
                state.notices = { error: action.error };
            }
        };
    }
    function getHandsDocs() {
        var { pending, fulfilled, rejected } = extraActions.getHandsDocs;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS docs') {
                    state.handsDocs = action.payload.getHandsDocs;
                } else {
                    state.handsDocs = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getHandsReps() {
        var { pending, fulfilled, rejected } = extraActions.getHandsReps;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS reps') {
                    state.handsRepsTeam = action.payload.getHandsReps;
                } else {
                    state.handsRepsTeam = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }
    function getHandsRepsTeam() {
        var { pending, fulfilled, rejected } = extraActions.getHandsRepsTeam;
        return {
            [pending]: (state) => {
                
            
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS reps') {
                    state.handsReps = action.payload.getHandsReps;
                } else {
                    state.handsReps = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getHandsMH() {
        var { pending, fulfilled, rejected } = extraActions.getHandsMH;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved HandS MH reps') {
                    state.handsMentalHealth = action.payload.getHandsMH;
                } else {
                    state.handsMentalHealth = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function updateHandsInfo() {
        var { pending, fulfilled, rejected } = extraActions.updateHandsInfo;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addHandsRep() {
        var { pending, fulfilled, rejected } = extraActions.addHandsRep;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }
    function addHandsRA() {
        var { pending, fulfilled, rejected } = extraActions.addHandsRA;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function editHandsRep() {
        var { pending, fulfilled, rejected } = extraActions.editHandsRep;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }
    function destroyHandsRep() {
        var { pending, fulfilled, rejected } = extraActions.destroyHandsRep;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }
    function destroyThisDoc() {
        var { pending, fulfilled, rejected } = extraActions.destroyThisDoc;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

}