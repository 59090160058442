import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid'
import { alertActions } from '_store';

export { Alert };

function Alert() {
    const dispatch = useDispatch();
    const location = useLocation();
    const alerts = useSelector(x => x.alert.values);
    useEffect(() => {
        dispatch(alertActions.clear());
    }, [location]);

    useEffect(() => {
        // Clear each alert after 5 seconds
        alerts.forEach(alert => {
            setTimeout(() => {
                dispatch(alertActions.clear(alert.id));
            }, 5000);
        });
    }, [alerts, dispatch]);

    if (!alerts) return null;


    return (
        <div className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
            <div className="flex w-full flex-col items-center space-y-2 sm:items-end">
                {alerts.map((alert) => (
                    <div key={alert.id} className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-${alert.type}-50 shadow-lg ring-1 ring-black ring-opacity-5`}>
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`animate-wiggle h-6 w-6 text-${alert.type}-400`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                                    </svg>

                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className={`text-sm font-semibold text-${alert.type}-800`}>
                                        {alert.title}
                                    </p>
                                    <p className={`mt-1 text-sm text-${alert.type}-700`}>
                                        {alert.message}
                                    </p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className={`inline-flex rounded-md text-${alert.type}-400 hover:text-${alert.type}-500 focus:outline-none focus:ring-2 focus:ring-${alert.type}-500 focus:ring-offset-2`}
                                        onClick={() => dispatch(alertActions.clear(alert.id))}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
}