import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import { get } from 'lodash';

const name = 'adminHub';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const cacheDuration = 3600000;
export const adminHubActions = { ...slice.actions, ...extraActions };
export const adminHubReducer = slice.reducer;

const token = JSON.parse(localStorage.getItem('token'));
const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

function setCache(key, data) {
    const item = { data, timestamp: Date.now() };
    sessionStorage.setItem(key, JSON.stringify(item));
    clearExpiredCacheItems();
}

function getCache(key) {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);
    if (Date.now() - item.timestamp > cacheDuration) {
        sessionStorage.removeItem(key);
        return null;
    }
    return item.data;
}

function clearExpiredCacheItems() {
    const now = Date.now();
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        try {
            const itemStr = sessionStorage.getItem(key);
            const item = JSON.parse(itemStr);
            if (item && item.timestamp && (now - item.timestamp > cacheDuration)) {
                sessionStorage.removeItem(key);
                console.log(`Cache item with key '${key}' has been removed due to expiration.`);
                i--;
            }
        } catch (e) {
            console.error(`Error reading or parsing sessionStorage item with key '${key}':`, e);
        }
    }
}

function clearCache(key) {
    sessionStorage.removeItem(key);
}

function createInitialState() {
    return {
        isLoading: false,
        loading: false,
        error: false,
        isFuseError: false,
        isCached: false,
        isOverCached: false,
        adminHubSearch: [],
        adminHubQuery: [],
        adminHubDataAll: [],
        adminHubUploads: [],
        adminHubBackups: [],
        adminHubAnnouncements: [],
        adminHubAllUsers: [],
        selectedData: [],
        salesData: [],
        dataGMWeekly: [],
        salesDashYTD: [],
        salesDashGM: [],
        salesPriorDate: '',
        salesYTDDate: '',
        searchItems: [],
        siteSettings: [],
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_adminHub_API_URL;

    return {
        ...basicActions(),
        ...postActions(),
    };

    function basicActions() {
        return {
            getAll: createAsyncThunk(
                `/${name}/getAll`,
                async ({ dispatch }) => {
                    const cacheKey = `thcache_adminHub`;
                    const cachedData = getCache(cacheKey);
                    if (cachedData) {
                        return { ...cachedData, isCached: true };
                    }
                    const response = await fetchWrapper.get(`${baseUrl}/adminHub`);
                    if (response.message === 'Success') {
                        setCache(cacheKey, response);
                    }
                    return { ...response, isCached: false };
                }
            ),
            getUploads: createAsyncThunk(
                `${name}/getUploads`,
                async () => await fetchWrapper.get(baseUrl + "/adminhub/getUploads")
            ),
            getAdminHubBackups: createAsyncThunk(
                `${name}/getAdminHubBackups`,
                async () => await fetchWrapper.get(baseUrl + "/adminhub/getAdminHubBackups")
            ),
            getAdminHubAnnouncements: createAsyncThunk(
                `${name}/getAdminHubAnnouncements`,
                async () => await fetchWrapper.get(baseUrl + "/adminhub/getAdminHubAnnouncements")
            ),
            getAllUsers: createAsyncThunk(
                'adminHub/getAllUsers',
                async ({ searchTerm = '', pageSize = 10, page = 1, orderBy, orderDirection }) => {
                    const searchQuery = searchTerm ? `&searchTerm=${searchTerm}` : '';
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/getAllUsers?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}${searchQuery}`);
                    return response;
                }
            ),
            getDataByWeek: createAsyncThunk(
                'adminHub/getDataByWeek',
                async (date) => {
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/getDataByWeek?date=${date}`);
                    return response;
                }
            ),
            getHeadersAndTotalsForWeek: createAsyncThunk(
                'adminHub/getHeadersAndTotalsForWeek',
                async () => {
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/getHeadersAndTotalsForWeek`);
                    return response;
                }
            ),
            getSalesData: createAsyncThunk(
                'adminHub/getSalesData',
                async ({ searchTerm = '', pageSize = 10, page = 1, orderBy, orderDirection }) => {
                    const searchQuery = searchTerm ? `&searchTerm=${searchTerm}` : '';
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/getSalesData?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}${searchQuery}`);
                    return response;
                }
            ),
            deleteSale: createAsyncThunk(
                'adminHub/deleteSale',
                async (id) => {
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/deleteSale/${id}`);
                    return response;
                }
            ),
            getSearchItems: createAsyncThunk(
                'adminHub/getSearchItems',
                async ({ searchTerm = '', pageSize = 10, currentPage = 1, orderBy, orderDirection = 'asc' }) => {
                    const searchQuery = searchTerm ? `&searchTerm=${searchTerm}` : '';
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/getSearchItems?page=${currentPage}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}${searchQuery}`);
                    return response;
                }
            ),
            destroyThisSearch: createAsyncThunk(
                'adminHub/destroyThisSearch',
                async (id) => {
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/destroyThisSearch/${id}`);
                    return response;
                }
            ),
            getSiteSettings: createAsyncThunk(
                'adminHub/getSiteSettings',
                async () => {
                    const response = await fetchWrapper.get(`${baseUrl}/adminhub/getSiteSettings`);
                    return response;
                }
            ),
        };
    }

    function postActions() {
        return {
            usersRolesSave: createAsyncThunk(
                `/${name}/usersRolesSave`,
                async (form) => {
                    const response = await fetchWrapper.post(`${baseUrl}/adminhub/usersRolesSave`, form);
                    return response;
                }
            ),
            createBackup: createAsyncThunk(
                `/${name}/createBackup`,
                async () => {
                    const response = await fetchWrapper.post(`${baseUrl}/adminhub/createBackup`);
                    return response;
                }
            ),
            addNewRole: createAsyncThunk(
                `/${name}/addNewRole`,
                async (form) => {
                    const response = await fetchWrapper.post(`${baseUrl}/adminhub/addNewRole`, form);
                    return response;
                }
            ),
            uploadData: createAsyncThunk(
                `/${name}/uploadData`,
                async (form) => {
                    const response = await fetchWrapper.post(`${baseUrl}/adminhub/uploadData`, form);
                    return response;
                }
            ),
            updateSearchItem: createAsyncThunk(
                `/${name}/updateSearchItem`,
                async (form) => {
                    const response = await fetchWrapper.post(`${baseUrl}/adminhub/updateSearchItem`, form);
                    return response;
                }
            ),
            saveSearchItem: createAsyncThunk(
                `/${name}/saveSearchItem`,
                async (form) => {
                    const response = await fetchWrapper.post(`${baseUrl}/adminhub/saveSearchItem`, form);
                    return response;
                }
            ),
            updateSiteSettings: createAsyncThunk(
                `/${name}/updateSiteSettings`,
                async (form) => {
                    const response = await fetchWrapper.post(`${baseUrl}/adminhub/updateSiteSettings`, form);
                    return response;
                }
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
        ...postReducers(),
    };

    function basicReducers() {
        return {
            ...getAll(),
            ...getUploads(),
            ...getAdminHubBackups(),
            ...getAdminHubAnnouncements(),
            ...getAllUsers(),
            ...getDataByWeek(),
            ...getHeadersAndTotalsForWeek(),
            ...getSalesData(),
            ...deleteSale(),
            ...getSearchItems(),
            ...destroyThisSearch(),
            ...getSiteSettings(),

        };
    }

    function postReducers() {
        return {
            ...usersRolesSave(),
            ...createBackup(),
            ...addNewRole(),
            ...saveSearchItem(),
            ...updateSearchItem(),
            ...updateSiteSettings(),
        };
    }

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.adminHubDataAll = action.payload.data;
                } else {
                    state.adminHubDataAll = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getUploads() {
        var { pending, fulfilled, rejected } = extraActions.getUploads;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.adminHubUploads = action.payload.data;
                } else {
                    state.adminHubUploads = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getAdminHubBackups() {
        var { pending, fulfilled, rejected } = extraActions.getAdminHubBackups;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.adminHubBackups = action.payload.data;
                } else {
                    state.adminHubBackups = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getAdminHubAnnouncements() {
        var { pending, fulfilled, rejected } = extraActions.getAdminHubAnnouncements;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.adminHubAnnouncements = action.payload.data;
                } else {
                    state.adminHubAnnouncements = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function usersRolesSave() {
        var { pending, fulfilled, rejected } = extraActions.usersRolesSave;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Roles updated successfully') {

                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function createBackup() {
        var { pending, fulfilled, rejected } = extraActions.createBackup;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {

                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function addNewRole() {
        var { pending, fulfilled, rejected } = extraActions.addNewRole;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Role added successfully') {

                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getAllUsers() {
        var { pending, fulfilled, rejected } = extraActions.getAllUsers;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.adminHubAllUsers = action.payload.data;
                } else {
                    state.adminHubAllUsers = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getDataByWeek() {
        var { pending, fulfilled, rejected } = extraActions.getDataByWeek;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.selectedData = action.payload.data;
                } else {
                    state.selectedData = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getHeadersAndTotalsForWeek() {
        var { pending, fulfilled, rejected } = extraActions.getHeadersAndTotalsForWeek;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.dataGMWeekly = action?.payload?.dataGMWeekly.length >= 1 ? JSON.parse(action?.payload?.dataGMWeekly[0]?.totals) : []
                    state.salesPriorDate = action?.payload?.dateStartOfWeek || ''
                    // state.salesDashYTD = JSON.parse(action.payload.dataYTD[0]?.totals || [])
                    state.salesDashGM = action?.payload?.dataGM.length >= 1 ? JSON.parse(action?.payload?.dataGM[0]?.totals) : []
                    state.salesYTDDate = action?.payload?.dateEndOfWeek || ''
                } else {
                    state.dataGMWeekly = [];
                    state.salesDashYTD = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getSalesData() {
        var { pending, fulfilled, rejected } = extraActions.getSalesData;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.salesData = action.payload.data;
                } else {
                    state.salesData = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function deleteSale() {
        var { pending, fulfilled, rejected } = extraActions.deleteSale;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Data deleted successfully') {

                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getSearchItems() {
        var { pending, fulfilled, rejected } = extraActions.getSearchItems;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.searchItems = action.payload.data;
                } else {
                    state.searchItems = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function destroyThisSearch(){
        var { pending, fulfilled, rejected } = extraActions.destroyThisSearch;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Search item deleted successfully') {

                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function saveSearchItem() {
        var { pending, fulfilled, rejected } = extraActions.saveSearchItem;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Search item saved successfully') {

                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function updateSearchItem() {
        var { pending, fulfilled, rejected } = extraActions.updateSearchItem;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Search item updated successfully') {

                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getSiteSettings() {
        var { pending, fulfilled, rejected } = extraActions.getSiteSettings;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.siteSettings = action.payload.data[0];
                } else {
                    state.siteSettings = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function updateSiteSettings() {
        var { pending, fulfilled, rejected } = extraActions.updateSiteSettings;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Site settings updated successfully') {
                    
                } else {

                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

}