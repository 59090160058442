import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import axios from 'axios';

const name = 'council';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const councilActions = { ...slice.actions, ...extraActions };
export const councilReducer = slice.reducer;

function createInitialState() {
    return {
        councilDash: {},
        councilList: {},
        councilNewsList: {},
        councilVidList: {},
        councilNewsItem: {},
        usersList: {},
        meetingsList: {},
        thisMeeting: {},
        documentsList: {},
        overviewDeets: {},
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_council_API_URL;

    return {
        getDashDetails: getDashDetails(),
        getCouncilNews: getCouncilNews(),
        getThisCouncilNews: getThisCouncilNews(),
        addCouncilNews: addCouncilNews(),
        addVideoLinks: addVideoLinks(),
        destroyCouncilVideo: destroyCouncilVideo(),
        editCouncilNews: editCouncilNews(),
        destroyCouncilNews: destroyCouncilNews(),
        getCouncilTeam: getCouncilTeam(),
        addCouncilMember: addCouncilMember(),
        editCouncilMember: editCouncilMember(),
        destroyCouncilMember: destroyCouncilMember(),
        userList: userList(),
        getAllMeetings: getAllMeetings(),
        getCouncilOverview: getCouncilOverview(),
        getThisMeeting: getThisMeeting(),
        addCouncilMeeting: addCouncilMeeting(),
        addMeetingAttendees: addMeetingAttendees(),
        addCouncilMeetingDocs: addCouncilMeetingDocs(),
        addCouncilFileDocs: addCouncilFileDocs(),
        editCouncilMeeting: editCouncilMeeting(),
        destroyCouncilMeeting: destroyCouncilMeeting(),
        getAllDocs: getAllDocs(),
        getAllVids: getAllVids(),
        destroyThisDoc: destroyThisDoc(),
        updateCouncilInfo: updateCouncilInfo(),
    };

    function getDashDetails() {
        return createAsyncThunk(
            `/${name}/getDashDetails`,
            async () => await fetchWrapper.get(`${baseUrl}/getDashDetails`)
        );
    }
    function getCouncilOverview() {
        return createAsyncThunk(
            `/${name}/getCouncilOverview`,
            async () => await fetchWrapper.get(`${baseUrl}/getCouncilOverview`)
        );
    }

    function getAllMeetings() {
        return createAsyncThunk(
            `/${name}/getAllMeetings`,
            async () => await fetchWrapper.get(`${baseUrl}/getAllMeetings`)
        );
    }

    function getThisMeeting() {
        return createAsyncThunk(
            `/${name}/getThisMeeting`,
            async (meetID) => await fetchWrapper.get(`${baseUrl}/getThisMeeting/${meetID}`)
        );
    }

    function getAllDocs() {
        return createAsyncThunk(
            `/${name}/getAllDocs`,
            async () => await fetchWrapper.get(`${baseUrl}/getAllDocs`)
        );
    }

    function userList() {
        return createAsyncThunk(
            `/${name}/getUserList`,
            async (sendingTo) => await fetchWrapper.get(`${baseUrl}/getUserList/` + sendingTo)
        );
    }

    function getCouncilNews() {
        return createAsyncThunk(
            `/${name}/getCouncilNews`,
            async () => await fetchWrapper.get(`${baseUrl}/news`)
        );
    }
    function getAllVids() {
        return createAsyncThunk(
            `/${name}/getAllVids`,
            async () => await fetchWrapper.get(`${baseUrl}/getAllVids`)
        );
    }
    function getCouncilTeam() {
        return createAsyncThunk(
            `/${name}/getCouncilTeam`,
            async () => await fetchWrapper.get(`${baseUrl}/team`)
        );
    }

    function getThisCouncilNews() {
        return createAsyncThunk(
            `/${name}/getThisCouncilNews`,
            async ({ id }) => await fetchWrapper.get(`${baseUrl}/news/${id}`)
        );
    }

    function addCouncilMeeting() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addCouncilMeeting`,
            async (data) => await
                axios.post(`${baseUrl}/meetings/addCouncilMeeting`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function updateCouncilInfo() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateCouncilInfo`,
            async (data) => await
                axios.post(`${baseUrl}/updateCouncilInfo`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function addMeetingAttendees() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addMeetingAttendees`,
            async (data) => await
                axios.post(`${baseUrl}/meetings/addMeetingAttendees`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function addCouncilMeetingDocs() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addCouncilMeetingDocs`,
            async (data) => await
                axios.post(`${baseUrl}/meetings/addCouncilMeetingDocs`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function addCouncilFileDocs() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addCouncilFileDocs`,
            async (data) => await
                axios.post(`${baseUrl}/addCouncilFileDocs`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function addVideoLinks() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addVideoLinks`,
            async (data) => await
                axios.post(`${baseUrl}/addVideoLinks`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function destroyCouncilVideo() {
        return createAsyncThunk(
            `/${name}/destroyCouncilVideo`,
            async ({ tribe_council_videos_id }) => await fetchWrapper.post(`${baseUrl}/destroyCouncilVideo`, { tribe_council_videos_id })
        );
    }

    function addCouncilNews() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addCouncilNews`,
            async (data) => await
                axios.post(`${baseUrl}/news/addCouncilNews`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function editCouncilMeeting() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editCouncilMeeting`,
            async (data) => await
                axios.post(`${baseUrl}/meetings/editCouncilMeeting`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function editCouncilNews() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editCouncilNews`,
            async (data) => await
                axios.post(`${baseUrl}/news/editCouncilNews`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyCouncilNews() {
        return createAsyncThunk(
            `/${name}/destroyCouncilNews`,
            async ({ tribe_council_news_id }) => await fetchWrapper.post(`${baseUrl}/news/destroyCouncilNews`, { tribe_council_news_id })
        );
    }

    function destroyCouncilMeeting() {
        return createAsyncThunk(
            `/${name}/destroyCouncilMeeting`,
            async ({ tribe_council_meetings_id }) => await fetchWrapper.post(`${baseUrl}/meetings/destroyCouncilMeeting`, { tribe_council_meetings_id })
        );
    }

    function destroyThisDoc() {
        return createAsyncThunk(
            `/${name}/destroyThisDoc`,
            async ({ docID }) => await fetchWrapper.post(`${baseUrl}/meetings/destroyThisDoc`, { docID })
        );
    }

    function addCouncilMember() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addCouncilMember`,
            async (data) => await
                axios.post(`${baseUrl}/team/saveNewTeamMember`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function editCouncilMember() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editCouncilMember`,
            async (data) => await
                axios.post(`${baseUrl}/team/editThisTeamMember`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyCouncilMember() {
        return createAsyncThunk(
            `/${name}/destroyCouncilMember`,
            async ({ member_id }) => await fetchWrapper.post(`${baseUrl}/team/destroyTeamMember`, { member_id })
        );
    }
}


function createExtraReducers() {
    return {
        ...getDashDetails(),
        ...getCouncilOverview(),
        ...getCouncilNews(),
        ...getThisCouncilNews(),
        ...addCouncilNews(),
        ...editCouncilNews(),
        ...destroyCouncilNews(),
        ...getCouncilTeam(),
        ...addCouncilMember(),
        ...editCouncilMember(),
        ...destroyCouncilMember(),
        ...userList(),
        ...getAllMeetings(),
        ...getThisMeeting(),
        ...getAllDocs(),
        ...getAllVids(),
        ...addCouncilMeeting(),
        ...addMeetingAttendees(),
        ...addCouncilMeetingDocs(),
        ...addCouncilFileDocs(),
        ...editCouncilMeeting(),
        ...destroyCouncilMeeting(),
        ...destroyThisDoc(),
        ...updateCouncilInfo(),
        ...addVideoLinks(),
        ...destroyCouncilVideo()
    };

    function getAllMeetings() {
        var { pending, fulfilled, rejected } = extraActions.getAllMeetings;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved meetings') {
                    state.meetingsList = action.payload.meetings;
                } else {
                    state.meetingsList = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function getThisMeeting() {
        var { pending, fulfilled, rejected } = extraActions.getThisMeeting;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved meeting') {
                    state.thisMeeting = action.payload.meeting;
                } else {
                    state.thisMeeting = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }
    function getAllDocs() {
        var { pending, fulfilled, rejected } = extraActions.getAllDocs;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved council docs') {
                    state.documentsList = action.payload.docsList;
                } else {
                    state.documentsList = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }
    function userList() {
        var { pending, fulfilled, rejected } = extraActions.userList;
        return {
            [pending]: (state) => {
                state.usersList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.usersList = action.payload;
            },
            [rejected]: (state, action) => {
                state.usersList = { error: action.error };
            }
        };
    }

    function getDashDetails() {
        var { pending, fulfilled, rejected } = extraActions.getDashDetails;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Council Dash Details retrieved') {
                    state.councilDash = action.payload.dash;
                } else {
                    state.councilDash = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function getCouncilOverview() {
        var { pending, fulfilled, rejected } = extraActions.getCouncilOverview;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Employee Council Details retrieved') {
                    state.overviewDeets = action.payload.dash;
                } else {
                    state.overviewDeets = [];
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function getAllVids() {
        var { pending, fulfilled, rejected } = extraActions.getAllVids;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved council videos') {
                    state.councilVidList = action.payload.councilVidsList;
                } else {
                    state.councilVidList = null;
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }
    function addVideoLinks() {
        var { pending, fulfilled, rejected } = extraActions.addVideoLinks;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function destroyCouncilVideo() {
        var { pending, fulfilled, rejected } = extraActions.destroyCouncilVideo;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function getCouncilNews() {
        var { pending, fulfilled, rejected } = extraActions.getCouncilNews;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved council news') {
                    state.councilNewsList = action.payload.councilNewsList;
                } else {
                    state.councilNewsList = null;
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function getThisCouncilNews() {
        var { pending, fulfilled, rejected } = extraActions.getThisCouncilNews;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved council news item') {
                    state.councilNewsItem = action.payload.councilNewsItem;
                } else {
                    state.councilNewsItem = null;
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addCouncilMeetingDocs() {
        var { pending, fulfilled, rejected } = extraActions.addCouncilMeetingDocs;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addCouncilFileDocs() {
        var { pending, fulfilled, rejected } = extraActions.addCouncilFileDocs;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addCouncilMeeting() {
        var { pending, fulfilled, rejected } = extraActions.addCouncilMeeting;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addMeetingAttendees() {
        var { pending, fulfilled, rejected } = extraActions.addMeetingAttendees;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function editCouncilMeeting() {
        var { pending, fulfilled, rejected } = extraActions.editCouncilMeeting;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function destroyCouncilMeeting() {
        var { pending, fulfilled, rejected } = extraActions.destroyCouncilMeeting;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addCouncilNews() {
        var { pending, fulfilled, rejected } = extraActions.addCouncilNews;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function editCouncilNews() {
        var { pending, fulfilled, rejected } = extraActions.editCouncilNews;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function destroyCouncilNews() {
        var { pending, fulfilled, rejected } = extraActions.destroyCouncilNews;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function updateCouncilInfo() {
        var { pending, fulfilled, rejected } = extraActions.updateCouncilInfo;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getCouncilTeam() {
        var { pending, fulfilled, rejected } = extraActions.getCouncilTeam;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved team') {
                    state.councilList = action.payload.councilTeam;
                } else {
                    state.councilList = null;
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addCouncilMember() {
        var { pending, fulfilled, rejected } = extraActions.addCouncilMember;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function editCouncilMember() {
        var { pending, fulfilled, rejected } = extraActions.editCouncilMember;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function destroyCouncilMember() {
        var { pending, fulfilled, rejected } = extraActions.destroyCouncilMember;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function destroyThisDoc() {
        var { pending, fulfilled, rejected } = extraActions.destroyThisDoc;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

}
