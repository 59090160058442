import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'media';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const mediaActions = { ...slice.actions, ...extraActions };
export const mediaReducer = slice.reducer;

function createInitialState() {

    return {
        loading: false,
        error: null,
        mediaFolders: [],
        mediaFolder: [],
        mediaFiles: [],
        mediaFile: {},
        mediaFoldersLoading: false,
        mediaFoldersError: null,
        mediaFilesLoading: false,
        mediaFilesError: null,
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_MEDIA_API_URL;

    return {
        ...basicActions(),
        ...postActions(),
    };

    function basicActions() {
        return {
            landingPage: createAsyncThunk(
                `/${name}/landingPage`,
                async () => await fetchWrapper.get(`${baseUrl}/mediamanager/landingPage`)
            ),
            getAllFolders: createAsyncThunk(
                `/${name}/getAllFolders`,
                async () => await fetchWrapper.get(`${baseUrl}/mediamanager/getAllFolders`)
            ),
            getMediaByFolder: createAsyncThunk(
                `/${name}/getMediaByFolder`,
                async (folderID) => await fetchWrapper.get(`${baseUrl}/mediamanager/getMediaByFolder/${folderID}`)
            ),
            getAllMedia: createAsyncThunk(
                `/${name}/getAllMedia`,
                async () => await fetchWrapper.get(`${baseUrl}/mediamanager/getAllMedia`)
            ),
            getAllMediaFiltered: createAsyncThunk(
                `/${name}/getAllMediaFiltered`,
                async (filter) => await fetchWrapper.get(`${baseUrl}/mediamanager/getAllMediaFiltered${filter}`)
            ),
            getMediaBySubFolder: createAsyncThunk(
                `/${name}/getMediaBySubFolder`,
                async ({mainfolderID, mainsubfolderID}) => await fetchWrapper.get(`${baseUrl}/mediamanager/getMediaBySubFolder/${mainfolderID}/${mainsubfolderID}`)
            ),
        };
    }

    function postActions(){
        return {
            createMedia: createAsyncThunk(
                `/${name}/createMedia`,
                async (data) => await fetchWrapper.post(`${baseUrl}/mediamanager/createMedia`, data)
            ),
            updateMedia: createAsyncThunk(
                `/${name}/updateMedia`,
                async (data) => await fetchWrapper.put(`${baseUrl}/mediamanager/updateMedia`, data)
            ),
            deleteMedia: createAsyncThunk(
                `/${name}/deleteMedia`,
                async ({fileLocation}) => await fetchWrapper.post(`${baseUrl}/mediamanager/deleteMedia`, {fileLocation})
            ),
            unDeleteMedia: createAsyncThunk(
                `/${name}/unDeleteMedia`,
                async (mediaId) => await fetchWrapper.post(`${baseUrl}/mediamanager/unDeleteMedia/${mediaId}`)
            ),
            assignMedia: createAsyncThunk(
                `/${name}/assignMedia`,
                async ({mediaId, userId}) => await fetchWrapper.put(`${baseUrl}/mediamanager/assignMedia/${mediaId}/${userId}`)
            ),
            createFolder: createAsyncThunk(
                `/${name}/createFolder`,
                async (data) => await fetchWrapper.post(`${baseUrl}/mediamanager/createFolder`, data)
            ),
            updateFolder: createAsyncThunk(
                `/${name}/updateFolder`,
                async (data) => await fetchWrapper.put(`${baseUrl}/mediamanager/updateFolder`, data)
            ),
            deleteFolder: createAsyncThunk(
                `/${name}/deleteFolder`,
                async ({folderLocation}) => await fetchWrapper.post(`${baseUrl}/mediamanager/deleteFolder`, {folderLocation})
            ),
            unDeleteFolder: createAsyncThunk(
                `/${name}/unDeleteFolder`,
                async (folderId) => await fetchWrapper.put(`${baseUrl}/mediamanager/unDeleteFolder/${folderId}`)
            ),
            removeShare: createAsyncThunk(
                `/${name}/removeShare`,
                async ({mediaId}) => await fetchWrapper.put(`${baseUrl}/mediamanager/removeShare/${mediaId}`)
            ),
        }
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
        ...postReducers(),
    };

    function basicReducers() {
        return {
            ...landingPage(),
            ...getAllFolders(),
            ...getMediaByFolder(),
            ...getAllMedia(),
            ...getAllMediaFiltered(),
            ...getMediaBySubFolder(),
        };
    }

    function postReducers(){
        return {
            ...createMedia(),
            ...updateMedia(),
            ...deleteMedia(),
            ...unDeleteMedia(),
            ...assignMedia(),
            ...createFolder(),
            ...updateFolder(),
            ...deleteFolder(),
            ...unDeleteFolder(),
            ...removeShare(),
        }
    }

    function landingPage() {
        var { pending, fulfilled, rejected } = extraActions.landingPage;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media retrieved successfully') {
                    state.mediaFiles = action.payload.media;
                } else {
                    state.mediaFiles = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getAllFolders() {
        var { pending, fulfilled, rejected } = extraActions.getAllFolders;
        return {
            [pending]: (state) => {
                state.mediaFoldersLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media folders retrieved successfully') {
                    state.mediaFolders = action.payload.media;
                } else {
                    state.mediaFolders = [];
                }
                state.mediaFoldersLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFoldersLoading = false;
                state.mediaFoldersError = action.error;
            }
        };
    }

    function getMediaByFolder() {
        var { pending, fulfilled, rejected } = extraActions.getMediaByFolder;
        return {
            [pending]: (state) => {
                state.mediaFilesLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media retrieved successfully') {
                    state.mediaFiles = action.payload.media.media;
                } else {
                    state.mediaFiles = [];
                }
                state.mediaFilesLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFilesLoading = false;
                state.mediaFilesError = action.error;
            }

        }
    }

    function getAllMedia() {
        var { pending, fulfilled, rejected } = extraActions.getAllMedia;
        return {
            [pending]: (state) => {
                state.mediaFilesLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media retrieved successfully') {
                    state.mediaFiles = action.payload.media;
                } else {
                    state.mediaFiles = [];
                }
                state.mediaFilesLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFilesLoading = false;
                state.mediaFilesError = action.error;
            }
        };
    }

    function getAllMediaFiltered() {
        var { pending, fulfilled, rejected } = extraActions.getAllMediaFiltered;
        return {
            [pending]: (state) => {
                state.mediaFilesLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media retrieved successfully') {
                    state.mediaFiles = action.payload.media;
                } else {
                    state.mediaFiles = [];
                }
                state.mediaFilesLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFilesLoading = false;
                state.mediaFilesError = action.error;
            }
        };
    }

    function getMediaBySubFolder() {
        var { pending, fulfilled, rejected } = extraActions.getMediaBySubFolder;
        return {
            [pending]: (state) => {
                state.mediaFilesLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media retrieved successfully') {
                    state.mediaFolder = action.payload.media;
                } else {
                    state.mediaFolder = [];
                }
                state.mediaFilesLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFilesLoading = false;
                state.mediaFilesError = action.error;
            }
        };

    }

    function createMedia() {
        var { pending, fulfilled, rejected } = extraActions.createMedia;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media created successfully') {
                    state.mediaFile = action.payload.media;
                } else {
                    state.mediaFile = {};
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function updateMedia() {
        var { pending, fulfilled, rejected } = extraActions.updateMedia;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media updated successfully') {
                    state.mediaFile = action.payload.media;
                } else {
                    state.mediaFile = {};
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function deleteMedia() {
        var { pending, fulfilled, rejected } = extraActions.deleteMedia;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media deleted successfully') {
                    state.mediaFile = action.payload.media;
                } else {
                    state.mediaFile = {};
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function unDeleteMedia() {
        var { pending, fulfilled, rejected } = extraActions.unDeleteMedia;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media undeleted successfully') {
                    state.mediaFile = action.payload.media;
                } else {
                    state.mediaFile = {};
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function assignMedia() {
        var { pending, fulfilled, rejected } = extraActions.assignMedia;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media assigned successfully') {
                    state.mediaFile = action.payload.media;
                } else {
                    state.mediaFile = {};
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function createFolder() {
        var { pending, fulfilled, rejected } = extraActions.createFolder;
        return {
            [pending]: (state) => {
                state.mediaFoldersLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media folder created successfully') {
                    state.mediaFolders = action.payload.media;
                } else {
                    state.mediaFolders = [];
                }
                state.mediaFoldersLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFoldersLoading = false;
                state.mediaFoldersError = action.error;
            }
        };
    }

    function updateFolder() {
        var { pending, fulfilled, rejected } = extraActions.updateFolder;
        return {
            [pending]: (state) => {
                state.mediaFoldersLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media folder updated successfully') {
                    state.mediaFolders = action.payload.media;
                } else {
                    state.mediaFolders = [];
                }
                state.mediaFoldersLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFoldersLoading = false;
                state.mediaFoldersError = action.error;
            }
        };
    }

    function deleteFolder() {
        var { pending, fulfilled, rejected } = extraActions.deleteFolder;
        return {
            [pending]: (state) => {
                state.mediaFoldersLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Folder deleted successfully') {
                    state.mediaFolders = action.payload.media;
                } else {
                    state.mediaFolders = [];
                }
                state.mediaFoldersLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFoldersLoading = false;
                state.mediaFoldersError = action.error;
            }
        };
    }

    function unDeleteFolder() {
        var { pending, fulfilled, rejected } = extraActions.unDeleteFolder;
        return {
            [pending]: (state) => {
                state.mediaFoldersLoading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Folder undeleted successfully') {
                    state.mediaFolders = action.payload.media;
                } else {
                    state.mediaFolders = [];
                }
                state.mediaFoldersLoading = false;
            },
            [rejected]: (state, action) => {
                state.mediaFoldersLoading = false;
                state.mediaFoldersError = action.error;
            }
        };
    }

    function removeShare() {
        var { pending, fulfilled, rejected } = extraActions.removeShare;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Media share removed successfully') {
                    state.mediaFiles = state.mediaFiles.filter(media => media._id !== action.payload.media.id);
                } else {
                    state.mediaFile = {};
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    

}