import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { fetchWrapper } from '_helpers'
import { getApiUrl } from '_helpers/url'

const name = 'community'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, extraReducers })

export const communityActions = { ...slice.actions, ...extraActions }
export const communityReducer = slice.reducer

function createInitialState() {
  return {
    communityAll: {},
    communityFiltered: {},
    communityOne: {},
    topics: {},
    topicsList: {},
    oneTopic: {},
    searchListing: {},
  }
}

function createExtraActions() {
  const baseUrl = getApiUrl(`/api/communityapp`)

  return {
    getRidOfPost: getRidOfPost(),
    getRidOfComment: getRidOfComment(),
    savingNewPost: savingNewPost(),
    savingNewTopic: savingNewTopic(),
    savingCommunityComment: savingCommunityComment(),
    getThisPost: getThisPost(),
    getAllTopics: getAllTopics(),
    getTopic: getTopic(),
    getTopicReduced: getTopicReduced(),
    communityAll: communityAll(),
    communityAllReduced: communityAllReduced(),
    updateThisPost: updateThisPost(),
    markAnswered: markAnswered(),
    runForumSearch: runForumSearch(),
  }

  function getRidOfPost() {
    return createAsyncThunk(
      `/${name}/getRidOfPost`,
      async ({ tribe_communities_id }) =>
        await fetchWrapper.post(`${baseUrl}/getRidOfPost`, {
          tribe_communities_id,
        })
    )
  }
  function runForumSearch() {
    return createAsyncThunk(
      `/${name}/runForumSearch`,
      async ({ searchTerm }) =>
        await fetchWrapper.post(`${baseUrl}/runForumSearch`, { searchTerm })
    )
  }
  function getRidOfComment() {
    return createAsyncThunk(
      `/${name}/getRidOfComment`,
      async ({ tribe_community_comments_id, tribe_communities_id }) =>
        await fetchWrapper.post(`${baseUrl}/getRidOfComment`, {
          tribe_community_comments_id,
          tribe_communities_id,
        })
    )
  }

  function updateThisPost() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }
    return createAsyncThunk(
      `/${name}/updateThisPost`,
      async (data) =>
        await axios
          .post(`${baseUrl}/updateThisPost`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }
  function savingNewPost() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/savingNewPost`,
      async (data) =>
        await axios
          .post(`${baseUrl}/savingNewPost`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }
  function savingNewTopic() {
    return createAsyncThunk(
      `/${name}/savingNewTopic`,
      async ({ tribe_community_topics_name, tribe_communities_id }) =>
        await fetchWrapper.post(`${baseUrl}/savingNewTopic`, {
          tribe_community_topics_name,
          tribe_communities_id,
        })
    )
  }

  function markAnswered() {
    return createAsyncThunk(
      `/${name}/markAnswered`,
      async ({
        tribe_community_comments_id,
        tribe_communities_id,
        incoming,
        answered,
      }) =>
        await fetchWrapper.post(`${baseUrl}/markAnswered`, {
          tribe_community_comments_id,
          tribe_communities_id,
          incoming,
          answered,
        })
    )
  }

  function savingCommunityComment() {
    return createAsyncThunk(
      `/${name}/savingCommunityComment`,
      async ({ tribe_communities_comment, tribe_communities_id }) =>
        await fetchWrapper.post(`${baseUrl}/savingCommunityComment`, {
          tribe_communities_comment,
          tribe_communities_id,
        })
    )
  }
  function getThisPost() {
    return createAsyncThunk(
      `/${name}/getThisPost`,
      async (id) => await fetchWrapper.get(`${baseUrl}/getThisPost/` + id)
    )
  }
  function getAllTopics() {
    return createAsyncThunk(
      `/${name}/getAllTopics`,
      async (id) => await fetchWrapper.get(`${baseUrl}/getAllTopics`)
    )
  }

  function getTopic() {
    return createAsyncThunk(
      `/${name}/getTopic`,
      async (topicID) =>
        await fetchWrapper.get(`${baseUrl}/getTopic?topicID=` + topicID)
    )
  }

  function getTopicReduced() {
    return createAsyncThunk(
      `/${name}/getTopicReduced`,
      async (pageURL) => await fetchWrapper.get(`${pageURL}`)
    )
  }

  function communityAll() {
    return createAsyncThunk(
      `/${name}/communityAll`,
      async () => await fetchWrapper.get(`${baseUrl}`)
    )
  }
  function communityAllReduced() {
    return createAsyncThunk(
      `/${name}/communityAllReduced`,
      async (dataUrl) => await fetchWrapper.get(`${dataUrl}`)
    )
  }
}

function createExtraReducers() {
  return {
    ...getRidOfPost(),
    ...getRidOfComment(),
    ...savingNewPost(),
    ...savingNewTopic(),
    ...markAnswered(),
    ...savingCommunityComment(),
    ...getThisPost(),
    ...getAllTopics(),
    ...getTopic(),
    ...getTopicReduced(),
    ...communityAll(),
    ...communityAllReduced(),
    ...updateThisPost(),
    ...runForumSearch(),
  }

  function updateThisPost() {
    var { pending, fulfilled, rejected } = extraActions.updateThisPost
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }
  function getRidOfPost() {
    var { pending, fulfilled, rejected } = extraActions.getRidOfPost
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }
  function runForumSearch() {
    var { pending, fulfilled, rejected } = extraActions.runForumSearch
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Search Returned') {
          state.searchListing = action.payload.searchResults
        } else {
          state.searchListing = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getRidOfComment() {
    var { pending, fulfilled, rejected } = extraActions.getRidOfComment
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (
          action.payload.message ===
          'Successfully deleted tribe community comment'
        ) {
          state.communityOne = action.payload.topic
        } else {
          state.communityOne = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function savingCommunityComment() {
    var { pending, fulfilled, rejected } = extraActions.savingCommunityComment
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Comment added!') {
          state.communityOne = action.payload.topic
        } else {
          state.communityOne = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getThisPost() {
    var { pending, fulfilled, rejected } = extraActions.getThisPost
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved topic') {
          state.communityOne = action.payload.topic
        } else {
          state.communityOne = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function getAllTopics() {
    var { pending, fulfilled, rejected } = extraActions.getAllTopics
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved topics') {
          state.topicsList = action.payload.alltopics
        } else {
          state.topicsList = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function getTopic() {
    var { pending, fulfilled, rejected } = extraActions.getTopic
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved topics') {
          state.communityFiltered = action.payload.communities
          state.oneTopic = action.payload.topic
        } else {
          state.communityFiltered = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getTopicReduced() {
    var { pending, fulfilled, rejected } = extraActions.getTopicReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved topics') {
          state.communityFiltered = action.payload.communities
          state.oneTopic = action.payload.topic
        } else {
          state.communityFiltered = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function communityAll() {
    var { pending, fulfilled, rejected } = extraActions.communityAll
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved communities') {
          state.communityAll = action.payload.community
        } else {
          state.communityAll = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function communityAllReduced() {
    var { pending, fulfilled, rejected } = extraActions.communityAllReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved communities') {
          state.communityAll = action.payload.community
        } else {
          state.communityAll = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function savingNewPost() {
    var { pending, fulfilled, rejected } = extraActions.savingNewPost
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        state.community = action.payload.community
      },
      [rejected]: (state, action) => {},
    }
  }
  function savingNewTopic() {
    var { pending, fulfilled, rejected } = extraActions.savingNewTopic
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Topic added!') {
          state.topics = action.payload.topics
        } else {
          state.topics = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
  function markAnswered() {
    var { pending, fulfilled, rejected } = extraActions.markAnswered
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully updated') {
          state.communityOne = action.payload.topic
        } else {
          state.communityOne = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }
}
