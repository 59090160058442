import React, {Fragment, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {authActions} from '_store';
import useHasRole from '_components/useHasRole';
import {Disclosure} from '@headlessui/react'
import {ChevronRightIcon} from '@heroicons/react/20/solid'


export default function Navigation() {
    const canAccessIT = useHasRole(['IT', 'Administrator', 'Operations Support']);
    const canAccessSuperAdmin = useHasRole(['Administrator']);
    const [IsOpenMenu, setIsOpenMenu] = useState(false);
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const mainURL = window.location.origin;

    const secondarynavigation = [
        {name: 'Profile', href: 'settings', icon: "/images/detailed/Profile.svg", current: false, requiresAuth: true},
        {
            name: 'Help Center',
            href: 'help/feedback',
            icon: "/images/detailed/help.svg",
            current: false,
            requiresAuth: true
        },
    ]

    const loggedOutNavigation = [
        {name: 'Login', href: 'login', icon: "/images/detailed/Profile.svg", current: false, requiresAuth: false},
    ]

    const adminOptions = [
        {
            name: 'Admin',
            href: 'bananaadmin',
            icon: "/images/detailed/admincog2.svg",
            current: false,
            requiresAuth: true,
            restricted: true,
            roles: ['IT', 'Administrator', 'Operations Support']
        },
        {
            name: 'FormBuilder',
            icon: "/images/detailed/Appraisal.svg",
            current: false,
            requiresAuth: true,
            children: [
                {name: 'FormBuilder', href: 'formbuilder/home'},
                {name: 'Build Form', href: 'formbuilder/build'}
            ],
        }
    ]

    const superAdminOptions = [
        {
            name: 'Super Admin',
            href: 'adminhub/main/data-import',
            icon: "/images/detailed/tioaoa.svg",
            current: false,
            requiresAuth: true,
            restricted: true,
            roles: ['Administrator']
        }
    ]

    const navigation = [
        {name: 'Home', href: 'home', icon: "/images/detailed/Home.svg", current: true, requiresAuth: true},
        {name: 'Announcements', href: 'noticeboard', icon: "/images/detailed/News.svg", current: false,},
        {
            name: 'Appraisals',
            href: 'appraisals/view/all',
            icon: "/images/detailed/Appraisal2.svg",
            current: false,
            requiresAuth: true
        },
        {name: 'Community', href: 'community/main', icon: "/images/detailed/Community.svg", current: false},
        {
            name: 'Employee Council',
            icon: "/images/detailed/Council.svg",
            current: false,
            requiresAuth: true,
            children: [
                {name: 'Home', href: 'EmployeeCouncil'},
                {name: 'Employee Council', href: 'EmployeeCouncil/ViewCouncil'},
                {name: 'Council Documents', href: 'EmployeeCouncil/documents'},
                {name: 'Council News', href: 'EmployeeCouncil/allnews'},
                {name: 'Community Posts', href: 'community/viewbytopic/6040434a-7f35-4a2d-8f47-b215e9e125d0'}
            ],
        },
        {
            name: 'Environment',
            icon: "/images/detailed/enviro.svg",
            current: false,
            requiresAuth: true,
            children: [
                {name: 'Home', href: 'environment/main'},
                {name: 'Environmental News', href: 'environment/news'},
                {name: 'Environmental Team', href: 'environment/team'},
                {name: 'Community Posts', href: 'community/viewbytopic/6040421b-7c46-4a2d-8f47-b215e9e125d0'}
            ],
        },
        {
            name: 'Foundation',
            href: 'wiki/home/category/7',
            icon: "/images/detailed/foundation.svg",
            current: false,
            requiresAuth: true
        },
        {
            name: 'Health and Safety',
            href: 'HS/home',
            icon: "/images/detailed/hands.svg",
            current: false,
            requiresAuth: true
        },
        {name: 'IT', href: 'IT/ITMP', icon: "/images/detailed/IT.svg", current: false, requiresAuth: true},
        {name: 'Kudos', href: 'kudos', icon: "/images/detailed/Kudos.svg", current: false, requiresAuth: true},
        {
            name: 'Learning Library',
            href: 'learning/home',
            icon: "/images/detailed/LLI.svg",
            current: false,
            requiresAuth: true
        },
        {name: 'Links', href: 'links', icon: "/images/detailed/Links.svg", current: false, requiresAuth: true},
        {
            name: 'Messaging',
            href: 'messageapp',
            icon: "/images/detailed/Messaging.svg",
            current: false,
            requiresAuth: true
        },
        {
            name: 'Tribe Adventures',
            icon: "/images/detailed/Announcements.svg",
            current: false,
            requiresAuth: true,
            children: [
                {name: 'Home', href: 'TribeAdventures'},
                {name: 'Adventure Kit Store', href: 'equipment/home'},
                {name: 'Upcoming Adventures', href: 'TribeAdventuresMain'},
                {name: 'Previous Adventures', href: 'TribeAdventures/blog '},
                {name: 'Adventure Fund Stories', href: 'TribeAdventures/overseas'}
            ],
        },
        {name: 'QR Codes', href: 'QRCode/home', icon: "/qrcode.svg", current: false, requiresAuth: true},
        {
            name: 'Tribe Central',
            href: 'central/home',
            icon: "/images/detailed/EOFull.svg",
            current: false,
            requiresAuth: true
        },
        {
            name: 'Vacancies',
            href: 'vacancies',
            icon: "/images/detailed/Vacancies.svg",
            current: false,
            requiresAuth: true
        },
        {
            name: 'Wiki',
            icon: "/images/detailed/wiki.svg",
            current: false,
            requiresAuth: true,
            children: [
                {name: 'Home', href: 'wiki/home/dash'},
                {name: 'Categories', href: 'wiki/home/categories'},
            ],
        },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return <>
        <div className='noIframeShow'>
            <div className={`${IsOpenMenu ? "" : "hidden"} relative z-40 `}>
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
                <div className="fixed inset-0 flex z-40">
                    <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 goblue">
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                            <button onClick={() => setIsOpenMenu((IsOpenMenu) => !IsOpenMenu)} type="button"
                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Close sidebar</span>
                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <div className="flex-shrink-0 flex items-center px-4">
                            &nbsp;
                        </div>
                        <div className="mt-2 flex-1 h-0 flex flex-grow overflow-y-auto">
                            <div className="flex flex-col flex-grow overflow-y-auto">
                                {authUser &&
                                    <nav className="flex-grow mt-2 p-2 space-y-1">
                                        {authUser && authUser?.role_id !== 12099 ?
                                            <NavLink
                                                className='text-white flex items-center p-1 font-semibold rounded-md'
                                                to="/home">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
                                                     fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                     strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
                                                </svg>
                                            </NavLink>
                                            : ''}
                                        {authUser && authUser?.role_id === 12076 || authUser && authUser?.role_id === 12001 ?
                                            <NavLink
                                                className='text-white flex items-center p-1 font-semibold rounded-md'
                                                to="/sessions">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
                                                     fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                     strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"/>
                                                </svg>
                                            </NavLink>
                                            : ''
                                        }
                                        {authUser && authUser?.role_id === 12076 || authUser && authUser?.role_id === 12099 ?
                                            <NavLink
                                                className='text-white flex items-center p-1 font-semibold rounded-md'
                                                to="/test/fmx/sessions">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
                                                     fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                     strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"/>
                                                </svg>
                                            </NavLink>
                                            : ''
                                        }

                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="directory">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor"
                                                 className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"/>
                                            </svg>
                                        </NavLink>
                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="environment/main">
                                            <img className="flex-shrink-0 h-6 w-6" src="/images/detailed/Stats.svg"/>
                                        </NavLink>

                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="noticeboard">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor"
                                                 className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"/>
                                            </svg>
                                        </NavLink>
                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="kudos">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor"
                                                 className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"/>
                                            </svg>
                                        </NavLink>
                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="links">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor"
                                                 className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"/>
                                            </svg>
                                        </NavLink>
                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="settings">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor"
                                                 className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"/>
                                            </svg>
                                        </NavLink>
                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="vacancies">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor"
                                                 className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"/>
                                            </svg>
                                        </NavLink>

                                        {canAccessIT ?
                                            <NavLink
                                                className='text-white flex items-center p-1 font-semibold rounded-md'
                                                to="bananaadmin">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor"
                                                     className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"/>
                                                </svg>
                                            </NavLink>
                                            : ''
                                        }

                                        <NavLink className='text-white flex items-center p-1 font-semibold rounded-md'
                                                 to="settings">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"/>
                                            </svg>
                                        </NavLink>

                                    </nav>
                                }
                                <div className="flex-shrink-0 block w-full">
                                    <NavLink className="text-white flex items-center p-1 font-semibold rounded-md"
                                             to="help/feedback">
                                        <svg className="text-gray-400 group-hover:text-gray-500 h-6 w-6"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        Help
                                    </NavLink>
                                    {!authUser &&
                                        <div>
                                            <NavLink
                                                className="text-white flex items-center p-1 font-semibold rounded-md"
                                                to="login">
                                                <svg className="text-gray-400 group-hover:text-gray-500 h-6 w-6"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="2" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                                </svg>
                                                Login
                                            </NavLink>

                                            <NavLink
                                                className="text-white flex items-center p-1 font-semibold rounded-md"
                                                to="register">
                                                <svg className="text-gray-400 group-hover:text-gray-500 h-6 w-6"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="2" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                                </svg>
                                                Register
                                            </NavLink>
                                        </div>
                                    }
                                    {authUser &&
                                        <button className="text-white flex items-center p-1 font-semibold rounded-md"
                                                onClick={logout}>
                                            <svg className="text-gray-400 group-hover:text-gray-500 h-6 w-6"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="2" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                            </svg>
                                            Logout
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-shrink-0 w-14">
                    </div>
                </div>
            </div>
            <div
                className="hidden md:flex md:w-10 group z-50 transition-all group duration-75 md:hover:w-60 md:flex-col md:fixed md:inset-y-0">
                <div
                    className="flex flex-col flex-grow pt-3 bg-white border-right shadow-lg overflow-y-auto scrollbar-hide">
                    <div className="mt-1 flex-1 flex flex-col">
                        {authUser &&
                            <nav className="flex-1 px-2 pb-4 space-y-1">
                                <ul className="flex flex-1 flex-col">
                                    <li>
                                        <ul className="-mx-2 w-max">
                                            {navigation.map((item) => (
                                                <li key={item.name} className='min-w-max'>
                                                    {!item.children ? (
                                                        <NavLink
                                                            to={`../` + item.href}
                                                            className={classNames(
                                                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                'group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-semibold text-gray-700 items-center justify-start'
                                                            )}
                                                        >
                                                            <img src={item.icon}
                                                                 className="h-8 w-8 shrink-0 text-gray-400"/>
                                                            <span
                                                                className='hidden group-hover:inline-block transition-all duration-300'> {item.name}</span>

                                                        </NavLink>
                                                    ) : (
                                                        <Disclosure as="div">
                                                            {({open}) => (
                                                                <>
                                                                    <Disclosure.Button
                                                                        className={classNames(
                                                                            item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                            'flex items-center w-full text-left rounded-md p-1 justify-start gap-x-3 text-sm leading-6 font-semibold text-gray-700'
                                                                        )}
                                                                    >
                                                                        <img src={item.icon}
                                                                             className="h-8 w-8 shrink-0 text-gray-400"/>
                                                                        <span
                                                                            className='hidden group-hover:inline-block transition-all duration-300'>
                                                                            {item.name}
                                                                        </span>
                                                                        <ChevronRightIcon
                                                                            className={classNames(
                                                                                open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                                                'ml-auto h-5 w-5 shrink-0'
                                                                            )}

                                                                        />
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                                        {item.children.map((subItem) => (
                                                                            <li key={subItem.name}>
                                                                                <Disclosure.Button
                                                                                    as="a"
                                                                                    href={mainURL + "/" + subItem.href}
                                                                                    className={classNames(
                                                                                        subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                                        'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                                                                    )}
                                                                                >
                                                                                    {subItem.name}
                                                                                </Disclosure.Button>
                                                                            </li>
                                                                        ))}
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>

                                    <li className='min-w-max'>
                                        <ul className="-mx-2">
                                            {authUser &&
                                                secondarynavigation?.map((item) => (
                                                    <li key={item.name}>
                                                        <NavLink
                                                            to={item.href}
                                                            className={classNames(
                                                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                'group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-semibold text-gray-700 items-center justify-start'
                                                            )}
                                                        >
                                                            <img src={item.icon}
                                                                 className="h-8 w-8 shrink-0 text-gray-400"/>
                                                            <span
                                                                className='hidden group-hover:inline-block transition-all duration-300'>
                                                                {item.name}
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>

                                    <li className='min-w-max mt-5'>
                                        <ul className="-mx-2">
                                            {canAccessIT &&
                                                adminOptions?.map((item) => (
                                                    <li key={item.name} className='min-w-max'>
                                                        {!item.children ? (
                                                            <NavLink
                                                                to={`../` + item.href}
                                                                className={classNames(
                                                                    item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                    'group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-semibold text-gray-700 items-center justify-start'
                                                                )}
                                                            >
                                                                <img src={item.icon}
                                                                     className="h-8 w-8 shrink-0 text-gray-400"/>
                                                                <span
                                                                    className='hidden group-hover:inline-block transition-all duration-300'> {item.name}</span>

                                                            </NavLink>
                                                        ) : (
                                                            <Disclosure as="div">
                                                                {({open}) => (
                                                                    <>
                                                                        <Disclosure.Button
                                                                            className={classNames(
                                                                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                                'flex items-center w-full text-left rounded-md p-1 justify-start gap-x-3 text-sm leading-6 font-semibold text-gray-700'
                                                                            )}
                                                                        >
                                                                            <img src={item.icon}
                                                                                 className="h-8 w-8 shrink-0 text-gray-400"/>
                                                                            <span
                                                                                className='hidden group-hover:inline-block transition-all duration-300'>
                                                                                {item.name}
                                                                            </span>
                                                                            <ChevronRightIcon
                                                                                className={classNames(
                                                                                    open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                                                    'ml-auto h-5 w-5 shrink-0'
                                                                                )}

                                                                            />
                                                                        </Disclosure.Button>
                                                                        <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                                            {item.children.map((subItem) => (
                                                                                <li key={subItem.name}>
                                                                                    <Disclosure.Button
                                                                                        as="a"
                                                                                        href={mainURL + "/" + subItem.href}
                                                                                        className={classNames(
                                                                                            subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                                            'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                                                                        )}
                                                                                    >
                                                                                        {subItem.name}
                                                                                    </Disclosure.Button>
                                                                                </li>
                                                                            ))}
                                                                        </Disclosure.Panel>
                                                                    </>
                                                                )}
                                                            </Disclosure>
                                                        )}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>

                                    <li className='min-w-max mt-5'>
                                        <ul className="-mx-2">
                                            {canAccessSuperAdmin &&
                                                superAdminOptions?.map((item) => (
                                                    <li key={item.name} className='min-w-max'>
                                                        {!item.children ? (
                                                            <NavLink
                                                                to={`../` + item.href}
                                                                className={classNames(
                                                                    item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                    'group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-semibold text-gray-700 items-center justify-start'
                                                                )}
                                                            >
                                                                <img src={item.icon}
                                                                     className="h-8 w-8 shrink-0 text-gray-400"/>
                                                                <span
                                                                    className='hidden group-hover:inline-block transition-all duration-300'> {item.name}</span>

                                                            </NavLink>
                                                        ) : (
                                                            <Disclosure as="div">
                                                                {({open}) => (
                                                                    <>
                                                                        <Disclosure.Button
                                                                            className={classNames(
                                                                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                                'flex items-center w-full text-left rounded-md p-1 justify-start gap-x-3 text-sm leading-6 font-semibold text-gray-700'
                                                                            )}
                                                                        >
                                                                            <img src={item.icon}
                                                                                 className="h-8 w-8 shrink-0 text-gray-400"/>
                                                                            <span
                                                                                className='hidden group-hover:inline-block transition-all duration-300'>
                                                                                {item.name}
                                                                            </span>
                                                                            <ChevronRightIcon
                                                                                className={classNames(
                                                                                    open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                                                    'ml-auto h-5 w-5 shrink-0'
                                                                                )}
                                                                            />
                                                                        </Disclosure.Button>
                                                                        <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                                            {item.children.map((subItem) => (
                                                                                <li key={subItem.name}>
                                                                                    <Disclosure.Button
                                                                                        as="a"
                                                                                        href={mainURL + "/" + subItem.href}
                                                                                        className={classNames(
                                                                                            subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                                            'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                                                                        )}
                                                                                    >
                                                                                        {subItem.name}
                                                                                    </Disclosure.Button>
                                                                                </li>
                                                                            ))}
                                                                        </Disclosure.Panel>
                                                                    </>
                                                                )}
                                                            </Disclosure>
                                                        )}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>

                                    {authUser && (
                                        <li className='min-w-max mt-5'>
                                            <ul className="-mx-2 ">
                                                <li className="mt-auto" onClick={logout}>
                                                    <span
                                                        className={'hover:bg-gray-50 group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-semibold text-gray-700 items-center justify-start'}>
                                                        <img src="/images/detailed/LogoutGreen.svg"
                                                             className="h-8 w-8 shrink-0 text-gray-400"/>
                                                        <span
                                                            className='hidden group-hover:inline-block transition-all duration-300'>
                                                            Log Out
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </li>
                                    )}
                                    {!authUser &&
                                        loggedOutNavigation?.map((item) => (
                                            <li className="mt-auto min-w-max" key={item.name}>
                                                <NavLink
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                        'group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-semibold text-gray-700 items-center justify-start'
                                                    )}
                                                >
                                                    <img src={item.icon} className="h-8 w-8 shrink-0 text-gray-400"/>
                                                    <span
                                                        className='hidden group-hover:inline-block transition-all duration-300'>
                                                        {item.name}
                                                    </span>
                                                </NavLink>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </nav>
                        }

                    </div>
                </div>
            </div>
        </div>
    </>
}