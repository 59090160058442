import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FirstPage from 'FirstPage/FirstPage';

function PrivateRoute({ children }) {
    const { user: authUser } = useSelector(x => x.auth);
    const location = useLocation();

    if (!authUser) {
        localStorage.setItem('returnUrl', location.pathname);
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    if (!authUser?.first_time_screen) {
        return <FirstPage />
    }
    return children;
}

export { PrivateRoute };