import { configureStore } from '@reduxjs/toolkit';

import { alertReducer } from './alert.slice';
import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';

import { statsReducer } from './stats.slice';
import { statsTestReducer } from './statstest.slice';
import { stripeReducer } from './stripe.slice';
import { staffPayReducer } from './staffpay.slice';
// import { messagesReducer } from './messages.slice';
import { adminReducer } from './admin.slice';
import { messageappReducer } from './messageapp.slice';
import { kudosReducer } from './kudos.slice';
import { linksReducer } from './links.slice';
// import { newsReducer } from './news.slice';
import { vacancyReducer } from './vacancy.slice';
import { noticesReducer } from './notices.slice';
import { searchReducer } from './search.slice';
import { adventureReducer } from './adventures.slice';
import { councilReducer } from './council.slice';
import { socialReducer } from './social.slice';
import { communityReducer } from './community.slice';
import { handsReducer } from './hands.slice';
import { helpdeskReducer } from './helpdesk.slice';
import { itteamReducer } from './itteam.slice';
import { mdnewsReducer } from './mdnews.slice';
import { enviroReducer } from './enviro.slice';
import { appraisalsReducer } from './appraisals.slice';
import { equipmentReducer } from './equipment.slice';
import { formBuilderReducer } from './formbuilder.slice';
import { faqReducer } from './faq.slice';
import { sroDataReducer } from './srodata.slice';
import { learningLibraryReducer } from './llapp.slice';
import { gasAppReducer } from './gas.slice';
import { tasksReducer } from './tasks.slice';
import { opsLinksReducer } from './opslinks.slice';
import { wikiReducer } from './wiki.slice';
import { mediaReducer } from './media.slice';
import { aiReducer } from './ai.slice';
import { qrReducer } from './qr.slice';
import { fuseReducer } from './fuse.slice';
import { thsmReducer } from './thsm.slice';
import { whistleReducer } from './whistle.slice';
import { adminHubReducer } from './adminhub.slice';

export * from './alert.slice';
export * from './auth.slice';
export * from './users.slice';
export * from './stats.slice';
export * from './statstest.slice';
export * from './stripe.slice';
export * from './staffpay.slice';
// export * from './messages.slice';
export * from './admin.slice';
export * from './messageapp.slice';
export * from './kudos.slice';
export * from './links.slice';
export * from './llapp.slice';
// export * from './news.slice';
export * from './vacancy.slice';
export * from './notices.slice';
export * from './search.slice';
export * from './social.slice';
export * from './adventures.slice';
export * from './council.slice';
export * from './community.slice';
export * from './hands.slice';
export * from './helpdesk.slice';
export * from './itteam.slice';
export * from './mdnews.slice';
export * from './enviro.slice';
export * from './appraisals.slice';
export * from './equipment.slice';
export * from './formbuilder.slice';
export * from './faq.slice';
export * from './srodata.slice';
export * from './gas.slice';
export * from './tasks.slice';
export * from './opslinks.slice';
export * from './wiki.slice';
export * from './media.slice';
export * from './ai.slice';
export * from './qr.slice';
export * from './fuse.slice';
export * from './thsm.slice';
export * from './whistle.slice';
export * from './adminhub.slice';

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        adminapp: adminReducer,
        auth: authReducer,
        gasapp: gasAppReducer,
        linksapp: linksReducer,
        kudosapp: kudosReducer,
        // messages: messagesReducer,
        messageapp: messageappReducer,
        // newsapp: newsReducer,
        noticesapp: noticesReducer,
        stats: statsReducer,
        statsTest: statsTestReducer,
        stripe: stripeReducer,
        staffpayapp: staffPayReducer,
        users: usersReducer,
        vacancyapp: vacancyReducer,
        searchapp: searchReducer,
        socialapp: socialReducer,
        councilapp: councilReducer,
        adventureapp: adventureReducer,
        communityapp: communityReducer,
        handsapp: handsReducer,
        helpdeskapp: helpdeskReducer,
        itapp: itteamReducer,
        mdnewsapp: mdnewsReducer,
        enviroapp: enviroReducer,
        appraisalsapp: appraisalsReducer,
        equipmentapp: equipmentReducer,
        formbuilderapp: formBuilderReducer,
        srodataapp: sroDataReducer,
        faqapp: faqReducer,
        llapp: learningLibraryReducer,
        opslinksapp: opsLinksReducer,
        tasksapp: tasksReducer,
        wikiapp: wikiReducer,
        mediamanagerapp: mediaReducer,
        aiapp: aiReducer,
        qrcodeapp: qrReducer,
        fuseapp: fuseReducer,
        thsmapp: thsmReducer,
        whistleapp: whistleReducer,
        adminhubapp: adminHubReducer,
    },
});