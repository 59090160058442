import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'admin';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const adminActions = { ...slice.actions, ...extraActions };
export const adminReducer = slice.reducer;

function createInitialState() {
    return {
        usersList: {},
        alertsList: {},
        status: 'idle',
        error: null,
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_admin_API_URL;

    return {
        getAlerts: getAlerts(),
        getUsersReduced: getUsersReduced(),
        getUsersFilterReduced: getUsersFilterReduced(),
        saveAlert: saveAlert(),
        editAlert: editAlert(),
        destroyAlert: destroyAlert(),
        destroyThisAlert: destroyThisAlert(),
        updateUser: updateUser(),
        destroyUser: destroyUser(),
        uploadUsers: uploadUsers(),
        canIStart: canIStart(),
        iAmStarting: iAmStarting(),
    };

    function canIStart() {
        return createAsyncThunk(
            `/${name}/canIStart`,
            async () => await fetchWrapper.get(`${baseUrl}/canIStart`)
        );
    }

    function iAmStarting() {
        return createAsyncThunk(
            `/${name}/iAmStarting`,
            async () => await fetchWrapper.get(`${baseUrl}/iAmStarting`)
        );
    }

    function saveAlert() {
        return createAsyncThunk(
            `/${name}/saveAlert`,
            async ({ alert, active_at, channel_id }) => await fetchWrapper.post(`${baseUrl}/saveAlert`, { alert, active_at, channel_id })
        );
    }

    function editAlert() {
        return createAsyncThunk(
            `/${name}/editAlert`,
            async ({ alert, active_at, channel_id, id }) => await fetchWrapper.post(`${baseUrl}/editAlert`, { alert, active_at, channel_id, id })
        );
    }

    function destroyAlert() {
        return createAsyncThunk(
            `/${name}/destroyAlert`,
            async ({ alerts }) => await fetchWrapper.post(`${baseUrl}/destroyAlert`, { alerts })
        );
    }

    function destroyThisAlert() {
        return createAsyncThunk(
            `/${name}/destroyThisAlert`,
            async (id) => await fetchWrapper.get(`${baseUrl}/destroyThisAlert/` + id )
        );
    }

    function updateUser() {
        return createAsyncThunk(
            `/${name}/updateUser`,
            async ({ firstName, lastName, email, role_id, id }) => await fetchWrapper.post(`${baseUrl}/updateUser`, { firstName, lastName, email, role_id, id })
        );
    }
    function destroyUser() {
        return createAsyncThunk(
            `/${name}/destroyUser`,
            async ({ users }) => await fetchWrapper.post(`${baseUrl}/destroyUser`, { users })
        );
    }

    function getAlerts() {
        return createAsyncThunk(
            `/${name}/getAlerts`,
            async () => await fetchWrapper.get(`${baseUrl}/getAlerts`)
        );
    }

    function uploadUsers() {
        return createAsyncThunk(
            `/${name}/uploadUsers`,
            async () => await fetchWrapper.post(`${baseUrl}/casuallyUpdate`, {})
        );
    }

    function getUsersReduced() {
        return createAsyncThunk(
            `/${name}/getUsersReduced`,
            async ({ pageSet, limitSet }) => await fetchWrapper.get(`${baseUrl}/getUsersReduced?page=` + String(pageSet) + "&limit=" + String(limitSet))
        );
    }
    function getUsersFilterReduced() {
        return createAsyncThunk(
            `/${name}/getUsersFilterReduced`,
            async ({ filter, type, pageSet, limitSet }) => await fetchWrapper.get(`${baseUrl}/getUsersReduced?filter=` + String(filter) + "&type=" + String(type) + "&page=" + String(pageSet) + "&limit=" + String(limitSet))
        );
    }
}

function createExtraReducers() {
    return {
        ...getAlerts(),
        ...getUsersReduced(),
        ...getUsersFilterReduced(),
        ...saveAlert(),
        ...editAlert(),
        ...destroyAlert(),
        ...destroyThisAlert(),
        ...updateUser(),
        ...destroyUser(),
        ...uploadUsers(),
        ...canIStart(),
        ...iAmStarting(),
    };

    function canIStart() {
        var { pending, fulfilled, rejected } = extraActions.canIStart;
        return {
            [pending]: (state) => {
                state.status = 'loading';;
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function iAmStarting() {
        var { pending, fulfilled, rejected } = extraActions.iAmStarting;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
               state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function saveAlert() {
        var { pending, fulfilled, rejected } = extraActions.saveAlert;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Alert created') {
                    state.status = 'succeeded';
                    state.alertsList = action.payload.alerts;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function editAlert() {
        var { pending, fulfilled, rejected } = extraActions.editAlert;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Alert updated') {
                    state.status = 'succeeded';
                    state.alertsList = action.payload.alerts;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function destroyAlert() {
        var { pending, fulfilled, rejected } = extraActions.destroyAlert;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Successfully deleted alert!") {
                    state.status = 'succeeded';
                    var array = [...state.alertsList]
                    var anotherOne = action.payload.alerts
                    const newArr = array.filter(i => !anotherOne.includes(i.id))
                    void (state.alertsList = newArr)
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function destroyThisAlert() {
        var { pending, fulfilled, rejected } = extraActions.destroyThisAlert;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Successfully deleted alert!") {
                    
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    
    function updateUser() {
        var { pending, fulfilled, rejected } = extraActions.updateUser;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'User updated') {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function destroyUser() {
        var { pending, fulfilled, rejected } = extraActions.destroyUser;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Successfully deleted user!") {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function getAlerts() {
        var { pending, fulfilled, rejected } = extraActions.getAlerts;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Alerts retrieved') {
                    state.alertsList = action.payload.alerts;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function uploadUsers() {
        var { pending, fulfilled, rejected } = extraActions.uploadUsers;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getUsersFilterReduced() {
        var { pending, fulfilled, rejected } = extraActions.getUsersFilterReduced;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Users retrieved') {
                    state.usersList = action.payload.users;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function getUsersReduced() {
        var { pending, fulfilled, rejected } = extraActions.getUsersReduced;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Users retrieved') {
                    state.usersList = action.payload.users;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

}