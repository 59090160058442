import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import axios from 'axios';

const name = 'learningLibrary';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state) => initialState,
    },
    extraReducers
});

export const learningLibraryActions = { ...slice.actions, ...extraActions };
export const learningLibraryReducer = slice.reducer;

function createInitialState() {
    return {
        landingPage: null,
        categories: [],
        featuredResources: [],
        fetchedCategory: [],
        fetching: false,
        myLikes:[],
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_LEARNING_LIBRARY_API_URL;

    return {
        getLandingPageData: getLandingPageData(),
        getFetchedCategory: getFetchedCategory(),
        updateLandingPageData: updateLandingPageData(),
        addCategory: addCategory(),
        updateCategory: updateCategory(),
        deleteCategory: deleteCategory(),
        addLikeToResource: addLikeToResource(),
        addResource: addResource(),
        updateResource: updateResource(),
        deleteResource: deleteResource(),
        getMyLikes: getMyLikes(),
    };

    function getMyLikes(){
        return createAsyncThunk(
            `/${name}/getMyLikes`,
            async () => {
                const response = await fetchWrapper.get(`${baseUrl}/getMyLikes`);
                if (response.message === 'Likes retrieved') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getLandingPageData() {
        return createAsyncThunk(
            `/${name}/getLandingPageData`,
            async () => {
                const response = await fetchWrapper.get(`${baseUrl}/getLandingPageData`);
                if (response.message === 'Landing page retrieved') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function addLikeToResource() {
        return createAsyncThunk(
            `/${name}/addLikeToResource`,
            async (resourceId) => {
                const response = await fetchWrapper.post(`${baseUrl}/resources/${resourceId}/likes`);
                if (response.message === 'Resource liked successfully' || response.message === 'Resource unliked successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getFetchedCategory() {
        return createAsyncThunk(
            `/${name}/getFetchedCategory`,
            async (id) => {
                const response = await fetchWrapper.get(`${baseUrl}/categories/${id}`);
                if (response.message === 'Category successfully retrieved') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateLandingPageData() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateLandingPageData`,
            async (data) => await
                axios.post(`${baseUrl}/updateLandingPageData`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function addCategory() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/addCategory`,
            async (data) => await
                axios.post(`${baseUrl}/categories`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function updateCategory() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/updateCategory`,
            async (data) => await
                axios.post(`${baseUrl}/updateCategory`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function deleteCategory() {
        return createAsyncThunk(
            `/${name}/deleteCategory`,
            async (id) => {
                const response = await fetchWrapper.delete(`${baseUrl}/categories/${id}`);
                if (response.message === 'Category deleted successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function addResource() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/addResource`,
            async (data) => await
                axios.post(`${baseUrl}/resources`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function updateResource() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };
        return createAsyncThunk(
            `/${name}/updateResource`,
            async (data) => await
                axios.post(`${baseUrl}/updateResource`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function deleteResource() {
        return createAsyncThunk(
            `/${name}/deleteResource`,
            async (id) => {
                const response = await fetchWrapper.delete(`${baseUrl}/resources/${id}`);
                if (response.message === 'Resource deleted successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

}

function createExtraReducers() {
    return {
        ...getLandingPageData(),
        ...getFetchedCategory(),
        ...updateLandingPageData(),
        ...addCategory(),
        ...updateCategory(),
        ...deleteCategory(),
        ...addLikeToResource(),
        ...addResource(),
        ...updateResource(),
        ...deleteResource(),
        ...getMyLikes(),
    };

    function getMyLikes() {
        var { pending, fulfilled, rejected } = extraActions.getMyLikes;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Likes retrieved') {
                    state.myLikes = action.payload.likes;
                    state.fetching = false;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function getLandingPageData() {
        var { pending, fulfilled, rejected } = extraActions.getLandingPageData;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                state.landingPage = action.payload.data.landingPage;
                state.categories = action.payload.data.categories;
                state.featuredResources = action.payload.data.featuredResources;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function getFetchedCategory() {
        var { pending, fulfilled, rejected } = extraActions.getFetchedCategory;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Category successfully retrieved') {
                    state.fetchedCategory = action.payload.category;
                    state.fetching = false;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function updateLandingPageData() {
        var { pending, fulfilled, rejected } = extraActions.updateLandingPageData;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addCategory() {
        var { pending, fulfilled, rejected } = extraActions.addCategory;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function updateCategory() {
        var { pending, fulfilled, rejected } = extraActions.updateCategory;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addLikeToResource() {
        var { pending, fulfilled, rejected } = extraActions.addLikeToResource;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function addResource() {
        var { pending, fulfilled, rejected } = extraActions.addResource;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function updateResource() {
        var { pending, fulfilled, rejected } = extraActions.updateResource;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function deleteResource() {
        var { pending, fulfilled, rejected } = extraActions.deleteResource;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }
    function deleteCategory() {
        var { pending, fulfilled, rejected } = extraActions.deleteCategory;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }
    

}

