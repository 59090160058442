import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchWrapper } from '_helpers';

const name = 'messageapp';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const messageappActions = { ...slice.actions, ...extraActions };
export const messageappReducer = slice.reducer;

function createInitialState() {
    return {
        error: {},
        messageapp: {},
        online: false,
        suggestions: {},
        allchannels: {},
        mychannels: {},
        subbedchannels: {},
        notifications: {},
        DMList: {},
        usersList: {},
        locationsList: {},
        channelSearch: {}
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_messageapp_API_URL;

    return {
        messageBox: messageBox(),
        saveNewIM: saveNewIM(),
        isOnline: isOnline(),
        isOffline: isOffline(),
        getSuggestions: getSuggestions(),
        getAllChannels: getAllChannels(),
        getMyChannels: getMyChannels(),
        getSubbedChannels: getSubbedChannels(),
        markAsRead: markAsRead(),
        getAllNotifications: getAllNotifications(),
        getNotifications: getNotifications(),
        createChannel: createChannel(),
        getDMList: getDMList(),
        sendNewMessage: sendNewMessage(),
        getUsers: getUsers(),
        getChannels: getChannels(),
        joinChannel: joinChannel(),
        adminLeaveChannel: adminLeaveChannel(),
        leaveChannel: leaveChannel(),
        leaveChat: leaveChat(),
        sendChannelMessage: sendChannelMessage(),
        inviteToChannel: inviteToChannel(),
        acceptRequest: acceptRequest(),
        declineInvite: declineInvite(),
        getUsersInvite: getUsersInvite(),
        getSitesInvite: getSitesInvite(),
        uploadMessageImage: uploadMessageImage(),
        uploadChannelImage: uploadChannelImage(),
        updateArray: updateArray(),
        updateChannelDetails: updateChannelDetails(),
        inviteTeamToChannel: inviteTeamToChannel(),
        getOrNewChannelId: getOrNewChannelId(),
    };

    function updateArray(){
        return createAsyncThunk(
            `/${name}/updateArray`,
            async (arg) => { 
                return arg
            }
        );
    }

    function uploadMessageImage() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/uploadMessageImage`,
            async (data) => await
                axios.post(`${baseUrl}/uploadMessageImage`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function uploadChannelImage() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/uploadChannelImage`,
            async (data) => await
                axios.post(`${baseUrl}/uploadChannelImage`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function getDMList() {
        return createAsyncThunk(
            `${name}/messageapp/getDMList`,
            async () => await fetchWrapper.get(baseUrl + '/getDMList')
        );
    }

    function acceptRequest() {
        return createAsyncThunk(
            `${name}/messageapp/acceptRequest`,
            async ({ id }) => await fetchWrapper.get(baseUrl + '/acceptinvite/' + id)
        );
    }

    function declineInvite() {
        return createAsyncThunk(
            `${name}/messageapp/declineInvite`,
            async ({ id }) => await fetchWrapper.get(baseUrl + '/declineinvite/' + id)
        );
    }

    function adminLeaveChannel() {
        return createAsyncThunk(
            `/${name}/adminremovefrom`,
            async ({ channel_id, user_id }) => await fetchWrapper.post(`${baseUrl}/adminremovefrom`, { channel_id, user_id })
        );
    }
    function inviteToChannel() {
        return createAsyncThunk(
            `/${name}/inviteToChannel`,
            async ({ channel_id, user_id }) => await fetchWrapper.post(`${baseUrl}/invitetochannel`, { channel_id, user_id })
        );
    }
    
    function inviteTeamToChannel() {
        return createAsyncThunk(
            `/${name}/inviteTeamToChannel`,
            async ({ location, channel_id }) => await fetchWrapper.post(`${baseUrl}/inviteTeamToChannel`, { location, channel_id })
        );
    }
    function leaveChannel() {
        return createAsyncThunk(
            `/${name}/leavechannel`,
            async ({ channel_id }) => await fetchWrapper.post(`${baseUrl}/leavechannel`, { channel_id })
        );
    }
    function getOrNewChannelId() {
        return createAsyncThunk(
            `/${name}/getOrNewChannelId`,
            async ({ sender, receiver, channelName }) => await fetchWrapper.post(`${baseUrl}/getOrNewChannelId`, { sender, receiver, channelName })
        );
    }
    
    function leaveChat() {
        return createAsyncThunk(
            `/${name}/leavechat`,
            async ({ channel_id }) => await fetchWrapper.post(`${baseUrl}/leavechat`, { channel_id })
        );
    }

    function joinChannel() {
        return createAsyncThunk(
            `/${name}/joinchannel`,
            async ({ channel_id }) => await fetchWrapper.post(`${baseUrl}/joinchannel`, { channel_id })
        );
    }

    function createChannel() {
        return createAsyncThunk(
            `/${name}/createchannel`,
            async ({ channelName, description, type }) => await fetchWrapper.post(`${baseUrl}/createchannel`, { channelName, description, type })
        );
    }
    function getUsers() {
        return createAsyncThunk(
            `/${name}/getUsers`,
            async (sendingTo) => await fetchWrapper.get(`${baseUrl}/getUsers/` + sendingTo)
        );
    }
    function getUsersInvite() {
        return createAsyncThunk(
            `/${name}/getUsersInvite`,
            async ({ sendingTo, channel }) => await fetchWrapper.get(`${baseUrl}/getUsersInvite/` + channel + '/' + sendingTo)
        );
    }
    function getSitesInvite() {
        return createAsyncThunk(
            `/${name}/getSitesInvite`,
            async ({ sendingTo, channel }) => await fetchWrapper.get(`${baseUrl}/getSitesInvite/` + channel + '/' + sendingTo)
        );
    }
    
    function getChannels() {
        return createAsyncThunk(
            `/${name}/getChannels`,
            async (channelID) => await fetchWrapper.get(`${baseUrl}/getChannels/` + channelID)
        );
    }

    function sendNewMessage() {
        return createAsyncThunk(
            `/${name}/goapp`,
            async ({ receiver, message }) => await fetchWrapper.post(`${baseUrl}/goapp`, { receiver, message })
        );
    }

    function markAsRead() {
        return createAsyncThunk(
            `${name}/messageapp/markasread`,
            async ({ notiID }) => await fetchWrapper.get(baseUrl + '/markasread/' + notiID)
        );
    }

    function getNotifications() {
        return createAsyncThunk(
            `${name}/messageapp/notifications`,
            async () => await fetchWrapper.get(baseUrl + '/notifications')
        );
    }

    function getAllNotifications() {
        return createAsyncThunk(
            `${name}/messageapp/allnotifications`,
            async () => await fetchWrapper.get(baseUrl + '/allnotifications')
        );
    }

    function getAllChannels() {
        return createAsyncThunk(
            `${name}/messageapp/getallchannels`,
            async () => await fetchWrapper.get(baseUrl + '/getallchannels')
        );
    }
    function getMyChannels() {
        return createAsyncThunk(
            `${name}/messageapp/getmychannels`,
            async () => await fetchWrapper.get(baseUrl + '/getmychannels')
        );
    }

    function getSubbedChannels() {
        return createAsyncThunk(
            `${name}/messageapp/getchannels`,
            async () => await fetchWrapper.get(baseUrl + '/getchannels')
        );
    }

    function getSuggestions() {
        return createAsyncThunk(
            `${name}/messageapp/suggestions`,
            async () => await fetchWrapper.get(baseUrl + '/suggestions')
        );
    }
    function isOnline() {
        return createAsyncThunk(
            `${name}/messageapp/online`,
            async ({ user_id }) => await fetchWrapper.get(baseUrl + '/online/' + user_id)
        );
    }

    function isOffline() {
        return createAsyncThunk(
            `${name}/messageapp/offline`,
            async ({ id }) => await fetchWrapper.get(baseUrl + '/offline/' + id)
        );
    }
    function messageBox() {
        return createAsyncThunk(
            `${name}/messageapp`,
            async ({ id }) => await fetchWrapper.get(baseUrl + '/goapp/' + id)
        );
    }
    function saveNewIM() {
        return createAsyncThunk(
            `/${name}/messageapp`,
            async ({ sent_to, user_id, message }) => await fetchWrapper.post(`${baseUrl}`, { sent_to, user_id, message })
        );
    }
    function sendChannelMessage() {
        return createAsyncThunk(
            `/${name}/sendChannelMessage`,
            async ({ channel_id, message }) => await fetchWrapper.post(`${baseUrl}`, { channel_id, message })
        );
    }

    function updateChannelDetails() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateChannelDetails`,
            async (data) => await
                axios.post(`${baseUrl}/updateChannelDetails`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
}

function createExtraReducers() {
    return {
        ...messageBox(),
        ...saveNewIM(),
        ...isOnline(),
        ...isOffline(),
        ...getSuggestions(),
        ...getAllChannels(),
        ...getSubbedChannels(),
        ...getMyChannels(),
        ...markAsRead(),
        ...getAllNotifications(),
        ...getNotifications(),
        ...createChannel(),
        ...getDMList(),
        ...sendNewMessage(),
        ...getUsers(),
        ...getUsersInvite(),
        ...getSitesInvite(),
        ...joinChannel(),
        ...adminLeaveChannel(),
        ...leaveChannel(),
        ...leaveChat(),
        ...getChannels(),
        ...sendChannelMessage(),
        ...inviteToChannel(),
        ...inviteTeamToChannel(),
        ...acceptRequest(),
        ...declineInvite(),
        ...uploadMessageImage(),
        ...uploadChannelImage(),
        ...updateArray(),
        ...updateChannelDetails(),
        ...getOrNewChannelId(),
    };

    function getOrNewChannelId() {
        var { pending, fulfilled, rejected } = extraActions.getOrNewChannelId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function uploadChannelImage() {
        var { pending, fulfilled, rejected } = extraActions.uploadChannelImage;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function uploadMessageImage() {
        var { pending, fulfilled, rejected } = extraActions.uploadMessageImage;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function inviteToChannel() {
        var { pending, fulfilled, rejected } = extraActions.inviteToChannel;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {

            }
        };
    }
    function inviteTeamToChannel() {
        var { pending, fulfilled, rejected } = extraActions.inviteTeamToChannel;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {

            }
        };
    }
    
    function declineInvite() {
        var { pending, fulfilled, rejected } = extraActions.declineInvite;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function acceptRequest() {
        var { pending, fulfilled, rejected } = extraActions.acceptRequest;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }


    function getUsers() {
        var { pending, fulfilled, rejected } = extraActions.getUsers;
        return {
            [pending]: (state) => {
                state.usersList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.usersList = action.payload;
            },
            [rejected]: (state, action) => {
                state.usersList = { error: action.error };
            }
        };
    }
    function getUsersInvite() {
        var { pending, fulfilled, rejected } = extraActions.getUsersInvite;
        return {
            [pending]: (state) => {
                state.usersList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.usersList = action.payload;
            },
            [rejected]: (state, action) => {
                state.usersList = { error: action.error };
            }
        };
    }
    function getSitesInvite() {
        var { pending, fulfilled, rejected } = extraActions.getSitesInvite;
        return {
            [pending]: (state) => {
                state.locationsList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.locationsList = action.payload;
            },
            [rejected]: (state, action) => {
                state.locationsList = { error: action.error };
            }
        };
    }
    
    function getChannels() {
        var { pending, fulfilled, rejected } = extraActions.getChannels;
        return {
            [pending]: (state) => {
                state.channelSearch = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.channelSearch = action.payload;
            },
            [rejected]: (state, action) => {
                state.channelSearch = { error: action.error };
            }
        };
    }

    function joinChannel() {
        var { pending, fulfilled, rejected } = extraActions.joinChannel;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function adminLeaveChannel() {
        var { pending, fulfilled, rejected } = extraActions.adminLeaveChannel;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function leaveChannel() {
        var { pending, fulfilled, rejected } = extraActions.leaveChannel;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function leaveChat() {
        var { pending, fulfilled, rejected } = extraActions.leaveChat;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function updateArray() {
        var { pending, fulfilled, rejected } = extraActions.updateArray;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                var array = [...state.messageapp.otherMessages, action.payload.e.message[0]]
                void (state.messageapp.otherMessages = array)
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function sendChannelMessage() {
        var { pending, fulfilled, rejected } = extraActions.sendChannelMessage;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function sendNewMessage() {
        var { pending, fulfilled, rejected } = extraActions.sendNewMessage;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getDMList() {
        var { pending, fulfilled, rejected } = extraActions.getDMList;
        return {
            [pending]: (state) => {
                state.DMList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.DMList = action.payload;
            },
            [rejected]: (state, action) => {
                state.DMList = { error: action.error };
            }
        };
    }

    function createChannel() {
        var { pending, fulfilled, rejected } = extraActions.createChannel;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getAllNotifications() {
        var { pending, fulfilled, rejected } = extraActions.getAllNotifications;
        return {
            [pending]: (state) => {
                state.notifications = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.notifications = action.payload;
            },
            [rejected]: (state, action) => {
                state.notifications = { error: action.error };
            }
        };
    }

    function getNotifications() {
        var { pending, fulfilled, rejected } = extraActions.getNotifications;
        return {
            [pending]: (state) => {
                state.notifications = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.notifications = action.payload;
            },
            [rejected]: (state, action) => {
                state.notifications = { error: action.error };
            }
        };
    }

    function markAsRead() {
        var { pending, fulfilled, rejected } = extraActions.markAsRead;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getAllChannels() {
        var { pending, fulfilled, rejected } = extraActions.getAllChannels;
        return {
            [pending]: (state) => {
                state.allchannels = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.allchannels = action.payload;
            },
            [rejected]: (state, action) => {
                state.allchannels = { error: action.error };
            }
        };
    }

    function getMyChannels() {
        var { pending, fulfilled, rejected } = extraActions.getMyChannels;
        return {
            [pending]: (state) => {
                state.mychannels = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.mychannels = action.payload;
            },
            [rejected]: (state, action) => {
                state.mychannels = { error: action.error };
            }
        };
    }

    function getSubbedChannels() {
        var { pending, fulfilled, rejected } = extraActions.getSubbedChannels;
        return {
            [pending]: (state) => {
                state.subbedchannels = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subbedchannels = action.payload;
            },
            [rejected]: (state, action) => {
                state.subbedchannels = { error: action.error };
            }
        };
    }


    function getSuggestions() {
        var { pending, fulfilled, rejected } = extraActions.getSuggestions;
        return {
            [pending]: (state) => {
                state.suggestions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.suggestions = action.payload;
            },
            [rejected]: (state, action) => {
                state.suggestions = { error: action.error };
            }
        };
    }
    function messageBox() {
        var { pending, fulfilled, rejected } = extraActions.messageBox;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                state.messageapp = action.payload;
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function isOnline() {
        var { pending, fulfilled, rejected } = extraActions.isOnline;
        return {
            [pending]: (state) => {
                state.online = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.online = true;
            },
            [rejected]: (state, action) => {
                state.online = { error: action.error };
            }
        };
    }
    function isOffline() {
        var { pending, fulfilled, rejected } = extraActions.isOffline;
        return {
            [pending]: (state) => {
                state.online = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.online = false;
            },
            [rejected]: (state, action) => {
                state.online = { error: action.error };
            }
        };
    }
    function saveNewIM() {
        var { pending, fulfilled, rejected } = extraActions.saveNewIM;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function updateChannelDetails() {
        var { pending, fulfilled, rejected } = extraActions.updateChannelDetails;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
}