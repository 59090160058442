import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'faq';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const faqActions = { ...slice.actions, ...extraActions };
export const faqReducer = slice.reducer;
function createInitialState() {
    return {
        faqList: [],
        thisFAQ: [],
        faqCats: [],
        faqTopics: [],
        status: 'idle',
        error: null,
        isLoading: false,
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_faq_API_URL;

    return {
        fetchFaqList: fetchFaqList(),
        fetchFaqTopics: fetchFaqTopics(),
        fetchFaqCatsByTopic: fetchFaqCatsByTopic(),
        getOneFAQ: getOneFAQ(),
        saveNewFAQ: saveNewFAQ(),
        deleteFAQ: deleteFAQ(),
        editFAQ: editFAQ(),
    };

    function editFAQ() {
        return createAsyncThunk(
            `/${name}/editFAQ`,
            async (data) => {
                const response = await fetchWrapper.post(`${baseUrl}/editFAQ`, data);
                if (response.message === 'FAQs updated successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function deleteFAQ() {
        return createAsyncThunk(
            `/${name}/deleteFAQ`,
            async (id) => {
                const response = await fetchWrapper.get(`${baseUrl}/delete/${id}`);
                if (response.message === 'FAQ deleted successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getOneFAQ() {
        return createAsyncThunk(
            `/${name}/getOneFAQ`,
            async (name) => {
                const response = await fetchWrapper.get(`${baseUrl}/show/${name}`);
                if (response.message === 'Successfully retrieved faq') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function fetchFaqTopics() {
        return createAsyncThunk(
            `/${name}/fetchFaqTopics`,
            async () => {
                const response = await fetchWrapper.get(`${baseUrl}/fetchFaqTopics`);
                if (response.message === 'Successfully retrieved topics') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function fetchFaqCatsByTopic() {
        return createAsyncThunk(
            `/${name}/fetchFaqCats`,
            async (name) => {
                const response = await fetchWrapper.get(`${baseUrl}/fetchFaqCatsByTopic/${name}`);
                if (response.message === 'Successfully retrieved categories') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function fetchFaqList() {
        return createAsyncThunk(
            `/${name}/fetchFaqList`,
            async (name) => {
                const response = await fetchWrapper.get(`${baseUrl}/show/${name}`);
                if (response.message === 'Successfully retrieved list') {
                    return response.data;
                }
                throw new Error(response.message);
            }
        );
    }

    function saveNewFAQ() {
        return createAsyncThunk(
            `/${name}/saveNewFAQ`,
            async (postedData) => {
                const response = await fetchWrapper.post(`${baseUrl}/saveNewFAQ`, postedData);
                console.log(response.message)
                if (response.message === 'Successfully added FAQ sections') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }
}

function createExtraReducers() {
    return {
        ...fetchFaqList(),
        ...fetchFaqTopics(),
        ...fetchFaqCatsByTopic(),
        ...getOneFAQ(),
        ...saveNewFAQ(),
        ...deleteFAQ(),
        ...editFAQ(),
    };

    function editFAQ() {
        var { pending, fulfilled, rejected } = extraActions.editFAQ;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function deleteFAQ() {
        var { pending, fulfilled, rejected } = extraActions.deleteFAQ;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getOneFAQ() {
        var { pending, fulfilled, rejected } = extraActions.getOneFAQ;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.thisFAQ = action.payload.faq;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function fetchFaqTopics() {
        var { pending, fulfilled, rejected } = extraActions.fetchFaqTopics;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.faqTopics = action.payload.faqTopics;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function fetchFaqCatsByTopic() {

        var { pending, fulfilled, rejected } = extraActions.fetchFaqCatsByTopic;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.faqCats = action.payload.faqCats;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function fetchFaqList() {
        var { pending, fulfilled, rejected } = extraActions.fetchFaqList;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.faqList = action.payload.faqList;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function saveNewFAQ() {
        var { pending, fulfilled, rejected } = extraActions.saveNewFAQ;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

}