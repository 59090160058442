import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'staffPay';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const staffPayActions = { ...slice.actions, ...extraActions };
export const staffPayReducer = slice.reducer;

function createInitialState() {
    return {
        staffPay: {},
        refunds: {},
        returnedLink: {},
        intent: {},
        error: null,
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_staffpay_API_URL;

    return {
        fetchProducts: fetchProducts(),
        fetchPaymentLinks: fetchPaymentLinks(),
        createNew: createNew(),
        getProduct: getProduct(),
        fetchPaymentIntent: fetchPaymentIntent(),
        refundThisPayment: refundThisPayment(),
        searchPaymentLinks: searchPaymentLinks(),
    };    

    function fetchProducts() {
        return createAsyncThunk(
            `${name}/fetchProducts`,
            async ({limit}) => await fetchWrapper.get(baseUrl + '/fetchProducts/' + limit)
        );
    }
    function fetchPaymentLinks() {
        return createAsyncThunk(
            `${name}/fetchPaymentLinks`,
            async ({limit}) => await fetchWrapper.get(baseUrl + '/fetchPaymentLinks/' + limit)
        );
    }
    function fetchPaymentIntent() {
        return createAsyncThunk(
            `${name}/fetchPaymentIntent`,
            async ({limit}) => await fetchWrapper.get(baseUrl + '/fetchPaymentIntent/' + limit)
        );
    }
    function getProduct() {
        return createAsyncThunk(
            `${name}/getProduct`,
            async ({ staffPayID }) => await fetchWrapper.get(baseUrl + '/getProduct' + staffPayID)
        );
    }
    function searchPaymentLinks(){
        return createAsyncThunk(
            `${name}/searchPaymentLinks`,
            async ({ searchFor }) => await fetchWrapper.get(baseUrl + '/searchPaymentLinks/' + searchFor)
        );
    }

    function createNew() {
        return createAsyncThunk(
            `${name}/newProduct`,
            async ( { formData } ) => await fetchWrapper.post(`${baseUrl}/newProduct`, { formData })

        );
    }
    function refundThisPayment() {
        return createAsyncThunk(
            `${name}/refundThisPayment`,
            async ( { refundedAmountOf, refundTypeof, intent } ) => await fetchWrapper.post(`${baseUrl}/refundThisPayment`, { refundedAmountOf, refundTypeof, intent })

        );
    }   

}

function createExtraReducers() {
    return {
        ...fetchProducts(),
        ...fetchPaymentLinks(),
        ...refundThisPayment(),
        ...getProduct(),
        ...createNew(),
        ...fetchPaymentIntent(),
        ...refundThisPayment(),
        ...searchPaymentLinks(),
    };

    
    function fetchProducts() {
        var { pending, fulfilled, rejected } = extraActions.fetchProducts;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                state.staffPay = action.payload;
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function fetchPaymentLinks() {
        var { pending, fulfilled, rejected } = extraActions.fetchPaymentLinks;
        return {
            [pending]: (state) => {
               
            },
            [fulfilled]: (state, action) => {
                var arr1 = action.payload[0].data
                var arr2 = action.payload[1].data;
                
                arr1.filter(function(item){
                    return arr2.filter(function(arr2_item){
                       return arr2_item.payment_intent === item.payment_intent;
                    }).length === 0
                });

                var rightNow = arr1.map(item => {
                    const item2 = arr2.find(i2 => i2.payment_intent === item.payment_intent);
                     var thisThing = item2 ? { ...item, ...item2 } : item;
                     thisThing.metadata = item.metadata
                    return thisThing;
                  });
                  var refundsNow = arr2.map(item => {
                    const item2 = arr1.find(i2 => i2.payment_intent === item.payment_intent);
                    return item2 ? { ...item, ...item2 } : item;
                  });

                state.staffPay = rightNow
                state.refunds = refundsNow
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function fetchPaymentIntent() {
        var { pending, fulfilled, rejected } = extraActions.fetchPaymentIntent;
        return {
            [pending]: (state) => {
                state.intent = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.intent = action.payload;
            },
            [rejected]: (state, action) => {
                state.intent = { error: action.error };
            }
        };
    }
    function createNew() {
        var { pending, fulfilled, rejected } = extraActions.createNew;
        return {
            [pending]: (state) => {
                state.returnedLink = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.returnedLink = action.payload;
            },
            [rejected]: (state, action) => {
                state.returnedLink = { error: action.error };
            }
        };
    }

    function getProduct() {
        var { pending, fulfilled, rejected } = extraActions.getProduct;
        return {
            [pending]: (state) => {
                state.staffPay = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.staffPay = action.payload;
            },
            [rejected]: (state, action) => {
                state.staffPay = { error: action.error };
            }
        };
    }
    function refundThisPayment(){
        var { pending, fulfilled, rejected } = extraActions.refundThisPayment;
        return {
            [pending]: (state) => {
                state.error = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.error = { error: action.error };
            }
        };
    }

    function searchPaymentLinks(){
        var { pending, fulfilled, rejected } = extraActions.searchPaymentLinks;
        return {
            [pending]: (state) => {
                state.error = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.error = { error: action.error };
            }
        };
    }
}