import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchWrapper } from '_helpers';

const name = 'mdnews';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const mdnewsActions = { ...slice.actions, ...extraActions };
export const mdnewsReducer = slice.reducer;


function createInitialState() {
    return {
        mdnewsList: {},
        homemdnewsList: {},
        thisMDNews: {},
        fetching: false,
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_mdnews_API_URL;
    
    return {
        mdnewsList: mdnewsList(),
        getHomeNews: getHomeNews(),
        mdnewsGet: mdnewsGet(),
        saveMDNews: saveMDNews(),
        editMDNews: editMDNews(),
        deleteThisMDNews: deleteThisMDNews(),
    };

    function deleteThisMDNews() {
        return createAsyncThunk(
            `/${name}/deleteThisMDNews`,
            async ( {mdnews_id} ) => await fetchWrapper.post(`${baseUrl}/deleteThisMDNews`,  {mdnews_id} )
        );
    }
    
    function saveMDNews() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/saveMDNews`,
            async (data) => await
                axios.post(`${baseUrl}/saveMDNews`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function editMDNews() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editMDNews`,
            async (data) => await
                axios.post(`${baseUrl}/editMDNews`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function mdnewsList() {
        return createAsyncThunk(
            `/${name}/getMDNews`,
            async () => await fetchWrapper.get(`${baseUrl}/getMDNews`)
        );
    }
    function getHomeNews() {
        return createAsyncThunk(
            `/${name}/getHomeNews`,
            async () => await fetchWrapper.get(`${baseUrl}/getHomeNews`)
        );
    }
    
    function mdnewsGet() {
        return createAsyncThunk(
            `/${name}/mdnewsGet`,
            async (mdnewsID) => await fetchWrapper.get(`${baseUrl}/fetchThisMDNews/` + mdnewsID)
        );
    }

}

function createExtraReducers() {
    return {
        ...getHomeNews(),
        ...mdnewsList(),
        ...mdnewsGet(),
        ...saveMDNews(),
        ...editMDNews(),
        ...deleteThisMDNews()
    };

    
    function saveMDNews() {
        var { pending, fulfilled, rejected } = extraActions.saveMDNews;
        return {
            [pending]: (state) => {
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "MDNews added!") {
                    
                }
                state.fetching = false
            },
            [rejected]: (state, action) => {
                state.mdnewsList = { error: action.error };
                state.fetching = false;
            }
        };
    }

    function editMDNews() {
        var { pending, fulfilled, rejected } = extraActions.editMDNews;
        return {
            [pending]: (state) => {
                
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "MDNews added!") {
                    
                }
                state.fetching = false
            },
            [rejected]: (state, action) => {
                
                state.fetching = false;
            }
        };
    }

    function deleteThisMDNews() {
        var { pending, fulfilled, rejected } = extraActions.deleteThisMDNews;
        return {
            [pending]: (state) => {
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "MDNews removed!") {
                   
                }
                state.fetching = false
            },
            [rejected]: (state, action) => {
                state.mdnewsList = { error: action.error };
                state.fetching = false;
            }
        };
    }

    function mdnewsList() {
        var { pending, fulfilled, rejected } = extraActions.mdnewsList;
        return {
            [pending]: (state) => {
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "MD News retrieved") {
                    state.mdnewsList = action.payload.MDNews
                    state.fetching = false
                } else {
                    
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false
            }
        };
    }
    function getHomeNews() {
        var { pending, fulfilled, rejected } = extraActions.getHomeNews;
        return {
            [pending]: (state) => {
                
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "MD News retrieved") {
                    state.homemdnewsList = action.payload.MDNews
                    state.fetching = false
                } else {
                    
                }
            },
            [rejected]: (state, action) => {
                
                state.fetching = false
            }
        };
    }

    function mdnewsGet() {
        var { pending, fulfilled, rejected } = extraActions.mdnewsGet;
        return {
            [pending]: (state) => {
                
                state.fetching = true
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "MD News retrieved") {
                    state.thisMDNews = action.payload.ThisMDNews
                    state.fetching = false
                } else {
                    state.fetching = false
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

}