import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import {fetchWrapper} from '_helpers'

import {endOfMonth, format, startOfToday} from 'date-fns'
import {getApiUrl} from '_helpers/url'

const name = 'stats'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({name, initialState, extraReducers})

export const statActions = {...slice.actions, ...extraActions}
export const statsReducer = slice.reducer

function createInitialState() {
    return {
        stats: {},
        userCal: {},
    }
}

function createExtraActions() {
    const baseUrl = getApiUrl(`/api/userstats`)

    return {
        userStats: userStats(),
        removeThisLine: removeThisLine(),
        getUserCalendar: getUserCalendar(),
    }

    function getUserCalendar() {
        var currentDate = new Date()
        let SoW = format(startOfToday(currentDate), 'yyyy-MM-dd')
        let EoM = format(endOfMonth(currentDate), 'yyyy-MM-dd')
        return createAsyncThunk(
            `/${name}/calendar/events`,
            async (graph) =>
                await axios
                    .get(
                        `https://graph.microsoft.com/beta/me/calendarview?startdatetime=${SoW}T00:01&enddatetime=${EoM}T23:59&$top=200&$orderby=start/dateTime&$select=subject,organizer,start,end,location,onlineMeeting`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'X-Requested-With': 'XMLHttpRequest',
                                Authorization: `Bearer ` + graph,
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data
                    })
        )
    }

    function userStats() {
        return createAsyncThunk(
            `${name}/userstats`,
            async ({siteName}) => await fetchWrapper.get(baseUrl + '/' + siteName)
        )
    }

    function removeThisLine() {
        return createAsyncThunk(
            `${name}/removeThisLine`,
            async ({id}) =>
                await fetchWrapper.delete(baseUrl + '/removeThisLine/' + id)
        )
    }
}

function createExtraReducers() {
    return {
        ...userStats(),
        ...removeThisLine(),
        ...getUserCalendar(),
    }

    function removeThisLine() {
        var {pending, fulfilled, rejected} = extraActions.removeThisLine
        return {
            [pending]: (state) => {
            },
            [fulfilled]: (state, action) => {
            },
            [rejected]: (state, action) => {
            },
        }
    }

    function userStats() {
        var {pending, fulfilled, rejected} = extraActions.userStats
        return {
            [pending]: (state) => {
                state.stats = {loading: true}
            },
            [fulfilled]: (state, action) => {
                state.stats = action.payload
            },
            [rejected]: (state, action) => {
                state.stats = {error: action.error}
            },
        }
    }

    function getUserCalendar() {
        var {pending, fulfilled, rejected} = extraActions.getUserCalendar
        return {
            [pending]: (state) => {
                state.userCal = {loading: true}
            },
            [fulfilled]: (state, action) => {
                if (action.payload.value) {
                    state.userCal = action.payload.value
                } else {
                    state.userCal = null
                }
            },
            [rejected]: (state, action) => {
                state.userCal = {error: action.error}
            },
        }
    }
}
