import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '_services';

import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { history } from '_helpers';
import { PrivateRoute } from '_components';
import * as serviceWorker from '../serviceWorker';
// import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Navigation from './Nav';
import LoadingSpinner from "../_components/Loading";

// function isDesktop() {
//     return !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
// }

// if (isDesktop()) {
//     const beamsClient = new PusherPushNotifications.Client({
//         instanceId: "b63f09f5-edf3-4568-8982-42e5bf697385",
//     });

//     beamsClient
//         .start()
//         .then((beamsClient) => beamsClient.getDeviceId())
//         .then((deviceId) =>
//             console.log("Successfully registered with Beams. Device ID:", deviceId)
//         )
//         .then(() => beamsClient.addDeviceInterest("debug-TribeHub"))
//         .then(() => beamsClient.addDeviceInterest("TribeHub"))
//         .then(() => beamsClient.getDeviceInterests())
//         .then((interests) => console.log("Current interests:", interests))
//         .catch(console.error);
// }

const LoginPage = React.lazy(() => import('LoginPage/LoginPage'));
const FirstPage = React.lazy(() => import('FirstPage/FirstPage'));

const ForgotPassword = React.lazy(() => import('ForgotPassword/ForgotPassword'));
const PasswordReset = React.lazy(() => import('PasswordReset/PasswordReset'));
const RegisterPage = React.lazy(() => import('RegisterPage/RegisterPage'));

const HomePage = React.lazy(() => import('HomePage/HomePage'));
const NewHome = React.lazy(() => import('HomePage/NewHome'));
const Directory = React.lazy(() => import('Directory/DirectoryMain'));

const Environment = React.lazy(() => import('Environment/Environment'));
const EnvMain = React.lazy(() => import('Environment/EnvMain'));
const EnvTeam = React.lazy(() => import('Environment/EnvTeam'));
const EnvNews = React.lazy(() => import('Environment/EnvNews'));
const ViewEnvNews = React.lazy(() => import('Environment/ViewEnvNews'));

const MDNews = React.lazy(() => import('MDNews/MDNews'));
const ViewMDNews = React.lazy(() => import('MDNews/ViewMDNews'));

const Profile = React.lazy(() => import('Users/Profile'));

const ForumMain = React.lazy(() => import('Forum/ForumMain'));
const ForumPost = React.lazy(() => import('Forum/ForumPost'));
const ForumTopic = React.lazy(() => import('Forum/ForumTopic'));
const ForumLandingMain = React.lazy(() => import('Forum/ForumLandingMain'));

const Admin = React.lazy(() => import('Admin/Admin'));
const Messaging = React.lazy(() => import('Messaging/Messaging'));
// const Inbox = React.lazy(() => import('Inbox/Inbox'));

const EmployeeCouncil = React.lazy(() => import('EmployeeCouncil/CouncilLanding'));
const ECThisMeeting = React.lazy(() => import('EmployeeCouncil/ThisMeeting'));
const ECEditMeeting = React.lazy(() => import('EmployeeCouncil/EditMeeting'));
const ECMeetings = React.lazy(() => import('EmployeeCouncil/MeetingsLanding'));
const ECDocuments = React.lazy(() => import('EmployeeCouncil/DocumentsLanding'));

const ViewCouncilNews = React.lazy(() => import('EmployeeCouncil/ViewCouncilNews'));
const EditCouncilNews = React.lazy(() => import('EmployeeCouncil/EditCouncilNews'));
const AddCouncilNews = React.lazy(() => import('EmployeeCouncil/AddCouncilNews'));

const AllCouncilNews = React.lazy(() => import('EmployeeCouncil/AllCouncilNews'));
const AllCouncilVids = React.lazy(() => import('EmployeeCouncil/AllCouncilVideos'));

const ViewCouncil = React.lazy(() => import('EmployeeCouncil/ViewCouncil'));

const TribeAdventures = React.lazy(() => import('TribeAdventures/AdventureLanding'));
const AddAdventure = React.lazy(() => import('TribeAdventures/AddAdventure'));
const EditAdventure = React.lazy(() => import('TribeAdventures/EditAdventure'));
const TribeAdventuresMain = React.lazy(() => import('TribeAdventures/TribeAdventures'));
const TribeAdventure = React.lazy(() => import('TribeAdventures/TribeAdventure'));

const BlogLanding = React.lazy(() => import('TribeAdventures/Blog/BlogLanding'));
const BlogView = React.lazy(() => import('TribeAdventures/Blog/BlogView'));
const BlogAdd = React.lazy(() => import('TribeAdventures/Blog/BlogAdd'));
const BlogEdit = React.lazy(() => import('TribeAdventures/Blog/BlogEdit'));

const OverseasLanding = React.lazy(() => import('TribeAdventures/Overseas/OverseasLanding'));
const OverseasView = React.lazy(() => import('TribeAdventures/Overseas/OverseasView'));
const OverseasAdd = React.lazy(() => import('TribeAdventures/Overseas/OverseasAdd'));
const OverseasEdit = React.lazy(() => import('TribeAdventures/Overseas/OverseasEdit'));

const StripePage = React.lazy(() => import('StripePage/StripePage'));

const StaffPay = React.lazy(() => import('StaffPay/StaffPay'));

const SROSearchTemplate = React.lazy(() => import('SROSearch/SROSearchTemplate'));
const SROSearchContainer = React.lazy(() => import('SROSearch/SROSearchContainer'));
const SROSearch = React.lazy(() => import('SROSearch/SROSearch'));
const SROCustomerSearch = React.lazy(() => import('SROSearch/SROCustomerSearch'));
const SROVoucherSearch = React.lazy(() => import('SROSearch/SROVoucherSearch'));
const SROJointSearch = React.lazy(() => import('SROSearch/SROJointSearch'));

const SiteSettings = React.lazy(() => import('SiteSettings/SiteSettings'));
const SettingsPage = React.lazy(() => import('SettingsPage/SettingsPage'));
const Links = React.lazy(() => import('Links/Links'));
const NewLink = React.lazy(() => import('Links/NewLink'));

const Kudos = React.lazy(() => import('Kudos/Kudos'));
const ViewKudos = React.lazy(() => import('Kudos/ViewKudos'));
const ViewKudosHash = React.lazy(() => import('Kudos/ViewKudosHash'));

const Notices = React.lazy(() => import('Notices/Notices'));
const NewNotice = React.lazy(() => import('Notices/NewNotice'));
const ViewNotice = React.lazy(() => import('Notices/ViewNotice'));
const EditNotice = React.lazy(() => import('Notices/EditNotice'));

const Vacancies = React.lazy(() => import('Vacancies/Vacancies'));
const ViewVacancy = React.lazy(() => import('Vacancies/ViewVacancy'));
const NewVacancy = React.lazy(() => import('Vacancies/NewVacancy'));
const EditVacancy = React.lazy(() => import('Vacancies/EditVacancy'));

const AppraisalTemplate = React.lazy(() => import('Appraisals/Template'));
const AppraisalsContainer = React.lazy(() => import('Appraisals/AppraisalsContainer'));
const AppraisalDetail = React.lazy(() => import('Appraisals/AppraisalDetail'));
const AppraisalFeedbackRequests = React.lazy(() => import('Appraisals/AppraisalFeedbackRequests'));
const AppraisalAddFeedback = React.lazy(() => import('Appraisals/AppraisalAddFeedback'));
const AppraisalUpdateSelfReflection = React.lazy(() => import('Appraisals/AppraisalUpdateSelfReflection'));
const AppraisalReview = React.lazy(() => import('Appraisals/AppraisalReview'));

const AppraisalAdmin = React.lazy(() => import('Appraisals/AppraisalAdmin'));

const Duty = React.lazy(() => import('Duty/Duty'));

const ITTemplate = React.lazy(() => import('IT/Template'));
const ITContainer = React.lazy(() => import('IT/ITContainer'));
const ITTeam = React.lazy(() => import('IT/ITTeam'));
const ITCC = React.lazy(() => import('IT/ITCC'));

const HelpPageTemplate = React.lazy(() => import('HelpPage/Template'));
const HelpList = React.lazy(() => import('HelpPage/HelpList'));
const HelpFAQ = React.lazy(() => import('HelpPage/HelpFAQ'));
const HelpTopic = React.lazy(() => import('HelpPage/HelpTopic'));
const HelpFeedback = React.lazy(() => import('HelpPage/HelpFeedback'));
const HelpFeedbackView = React.lazy(() => import('HelpPage/HelpFeedbackView'));
const DevBoard = React.lazy(() => import('HelpPage/DevBoard'));
const ParticipantJourney = React.lazy(() => import('HelpPage/ParticipantJourney'));

const EquipmentTemplate = React.lazy(() => import('Equipment/EquipmentTemplate'));
const EquipmentContainer = React.lazy(() => import('Equipment/EquipmentContainer'));
const EquipmentAvailability = React.lazy(() => import('Equipment/EquipmentAvailability'));
const EquipmentView = React.lazy(() => import('Equipment/EquipmentView'));
const EquipmentHire = React.lazy(() => import('Equipment/EquipmentHire'));
const EquipmentAdmin = React.lazy(() => import('Equipment/EquipmentAdmin'));
const EquipmentReports = React.lazy(() => import('Equipment/EquipmentReports'));
const EquipmentCatalogue = React.lazy(() => import('Equipment/EquipmentCatalogue'));

const FormBuilderTemplate = React.lazy(() => import('FormBuilder/FormBuilderTemplate'));
const FormBuilderContainer = React.lazy(() => import('FormBuilder/FormBuilderContainer'));
const FormBuilderBuild = React.lazy(() => import('FormBuilder/FormBuilderBuild'));
const FormBuilderForms = React.lazy(() => import('FormBuilder/FormBuilderForms'));
const FormBuilderFormView = React.lazy(() => import('FormBuilder/FormBuilderFormView'));

const FMXTemplate = React.lazy(() => import('FMX/FMXTemplate'));
const FMXContainer = React.lazy(() => import('FMX/FMXContainer'));
const AddFAQForm = React.lazy(() => import('FMX/utils/AddFAQForm'));
const EditFAQ = React.lazy(() => import('FMX/utils/EditFAQ'));
const FAQMain = React.lazy(() => import('FMX/FMXMain'));

const HelpTemplate = React.lazy(() => import('Help/HelpTemplate'));
const HelpContainer = React.lazy(() => import('Help/HelpContainer'));
const HelpAddFAQForm = React.lazy(() => import('Help/utils/AddFAQForm'));
const HelpEditFAQ = React.lazy(() => import('Help/utils/EditFAQ'));

const HandSTemplate = React.lazy(() => import('HandS/HandSTemplate'));
const HandSContainer = React.lazy(() => import('HandS/HandSContainer'));
const ViewNews = React.lazy(() => import('HandS/New/ViewNews'));

const LearningLibraryTemplate = React.lazy(() => import('LearningLibrary/LLTemplate'));
const LearningLibraryContainer = React.lazy(() => import('LearningLibrary/LLContainer'));
const ViewCategory = React.lazy(() => import('LearningLibrary/pages/LLCategory'));

const GASContainer = React.lazy(() => import('GAS/GAS_Container'));
const GASTemplate = React.lazy(() => import('GAS/GAS_Template'));

const TaskContainer = React.lazy(() => import('Task/Task_Container'));
const TaskTemplate = React.lazy(() => import('Task/Task_Template'));

const OpsLinksContainer = React.lazy(() => import('OpsLinks/OpsLinksContainer'));
const OpsLinksTemplate = React.lazy(() => import('OpsLinks/OpsLinksTemplate'));

const WikiTemplate = React.lazy(() => import('Wiki/Main/WikiTemplate'));
const WikiContainer = React.lazy(() => import('Wiki/Main/WikiContainer'));
const WikiCategories = React.lazy(() => import('Wiki/Pages/Categories/WikiCategories'));
const WikiViewCategory = React.lazy(() => import('Wiki/Pages/Category/WikiViewCategory'));
const WikiViewSubCategory = React.lazy(() => import('Wiki/Pages/SubCategory/WikiViewSubCategory'));
const WikiArticle = React.lazy(() => import('Wiki/Pages/Articles/WikiArticle'));
const WikiDash = React.lazy(() => import('Wiki/Pages/Dashboard/WikiDash'));
const WikiSearch = React.lazy(() => import('Wiki/Pages/Search/WikiSearch'));
const WikiCreate = React.lazy(() => import('Wiki/Pages/Create/WikiCreate'));
const EditBuilder = React.lazy(() => import('Wiki/Pages/Edit/EditBuilder'));
const MediaTemplate = React.lazy(() => import('Media/Templates/MediaTemplate'));
const MediaContainer = React.lazy(() => import('Media/Templates/MediaContainer'));
const MediaFolder = React.lazy(() => import('Media/Files/MediaFolder'));
const MediaSubFolder = React.lazy(() => import('Media/Files/MediaSubFolder'));

const AITemplate = React.lazy(() => import('AI/AITemplate'));
const AIContainer = React.lazy(() => import('AI/AIContainer'));

const QRTemplate = React.lazy(() => import('QR/Main/QRTemplate'));
const QRContainer = React.lazy(() => import('QR/Main/QRContainer'));

const DamTemplate = React.lazy(() => import('DAM/Templates/DamTemplate'));
const DamContainer = React.lazy(() => import('DAM/Templates/DamContainer'));
const DamLibrary = React.lazy(() => import('DAM/Pages/Library/DamLibrary'));
const DamFiltered = React.lazy(() => import('DAM/Pages/Filter/FilterFiles'));
const DamUploadLinks = React.lazy(() => import('DAM/Pages/Uploads/UploadLinks'));
const DamPrintRequests = React.lazy(() => import('DAM/Pages/Requests/PrintRequests'));
const DamDigitalRequests = React.lazy(() => import('DAM/Pages/Requests/DigitalRequests'));

const FuseTemplate = React.lazy(() => import('Fuse/Templates/FuseTemplate'));
const FuseContainer = React.lazy(() => import('Fuse/Templates/FuseContainer'));
const FuseUnpaid = React.lazy(() => import('Fuse/Pages/Unpaid/FuseUnpaid'));
const FuseDashboard = React.lazy(() => import('Fuse/Pages/Dashboard/DashBoard'));

const DemoTemplate = React.lazy(() => import('Demo/Templates/DemoTemplate'));
const DemoContainer = React.lazy(() => import('Demo/Templates/DemoContainer'));
const DemoOrder = React.lazy(() => import('Demo/Pages/Order/DemoOrder'));
const DemoCalls = React.lazy(() => import('Demo/Pages/Calls/CallUploader'));

const PeopleTemplate = React.lazy(() => import('People/Templates/PeopleTemplate'));
const PeopleContainer = React.lazy(() => import('People/Templates/PeopleContainer'));
const PeopleDashboard = React.lazy(() => import('People/Pages/Dashboard/PeopleDashboard'));
const SiteData = React.lazy(() => import('People/Pages/SiteData/SiteData'));

const THSMTemplate = React.lazy(() => import('TribeSocial/Templates/THSMTemplate'));
const THSMContainer = React.lazy(() => import('TribeSocial/Templates/THSMContainer'));
const THSMDashboard = React.lazy(() => import('TribeSocial/Pages/Dashboard/THSMDashboard'));
const THSMExplore = React.lazy(() => import('TribeSocial/Pages/Explore/THSMExplore'));
const THSMProfile = React.lazy(() => import('TribeSocial/Pages/Profile/THSMProfile'));
const THSMGift = React.lazy(() => import('TribeSocial/Pages/Gift/THSMGift'));
const THSMMyProfile = React.lazy(() => import('TribeSocial/Pages/MyProfile/THSMMyProfile'));

const DataTemplate = React.lazy(() => import('Data/Templates/DataTemplate'));
const DataContainer = React.lazy(() => import('Data/Templates/DataContainer'));
const DataDashboard = React.lazy(() => import('Data/Pages/Dashboard/DataDashboard'));

const AdminHubTemplate = React.lazy(() => import('AdminHub/Templates/AdminHubTemplate'));
const AdminHubContainer = React.lazy(() => import('AdminHub/Templates/AdminHubContainer'));
const AdminHubHome = React.lazy(() => import('AdminHub/Pages/Home/AdminHubHome'));
const AdminHubRoles = React.lazy(() => import('AdminHub/Pages/User/AdminHubRoles'));
const AdminHubPeopleData = React.lazy(() => import('AdminHub/Pages/Data/AdminHubPeopleData'));

const AdminHubUserData = React.lazy(() => import('AdminHub/Pages/Data/AdminHubUserData'));
const AdminHubSalesData = React.lazy(() => import('AdminHub/Pages/Financials/AdminHubSalesData'));
const AdminHubDataCharts = React.lazy(() => import('AdminHub/Pages/Financials/AdminHubDataCharts'));
const AdminHubNews = React.lazy(() => import('AdminHub/Pages/Modules/AdminHubNews'));

const AdminHubCouncil = React.lazy(() => import('AdminHub/Pages/Modules/AdminHubCouncil'));
const AdminHubAdventures = React.lazy(() => import('AdminHub/Pages/Modules/AdminHubAdventures'));
const AdminHubBackups = React.lazy(() => import('AdminHub/Pages/Data/AdminHubBackups'));
const AdminHubUserManage = React.lazy(() => import('AdminHub/Pages/User/AdminHubUserManage'));
const AdminHubAlerts = React.lazy(() => import('AdminHub/Pages/System/AdminHubAlerts'));
const AdminHubWhistle = React.lazy(() => import('AdminHub/Pages/Data/AdminHubWhistle'));
const AdminHubSystemActivity = React.lazy(() => import('AdminHub/Pages/System/AdminHubSystemActivity'));
const AdminHubSystemOnCall = React.lazy(() => import('AdminHub/Pages/System/AdminHubSystemOnCall'));
const AdminHubSearchPatterns = React.lazy(() => import('AdminHub/Pages/System/AdminHubSearch'));
const AdminHubMedia = React.lazy(() => import('AdminHub/Pages/Data/Media/AdminHubMedia'));
const AdminHubMediaFolder = React.lazy(() => import('AdminHub/Pages/Data/Media/AdminHubMediaFolder'));
const AdminHubMediaSubFolder = React.lazy(() => import('AdminHub/Pages/Data/Media/AdminHubMediaSubFolder'));
const AdminHubSiteSettings = React.lazy(() => import('AdminHub/Pages/System/AdminHubSiteSettings'));

const SDContainer = React.lazy(() => import('SalesData/Main/SDContainer'));
const SDTemplate = React.lazy(() => import('SalesData/Main/SDTemplate'));

export default function Main() {
    const { user: authUser } = useSelector(x => x.auth);
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <>
            <div className="h-screen">
                {authUser?.first_time_screen ?
                    <>
                        <Navigation />
                    </>
                    : ''
                }
                <div className="md:pl-10">
                    <div className="mx-auto flex flex-col">
                        <Alert />
                        <Routes>
                            <Route exact path="/home" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><HomePage /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/newhome" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><NewHome /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/directory" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Directory /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/duty" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Duty /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/environment" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Environment /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EnvMain /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="team" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EnvTeam /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="news" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EnvNews /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="viewEnvNews/:envNewsID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ViewEnvNews /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/profile/:userID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Profile /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/bananaadmin" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Admin /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/stripe" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><StripePage /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/staffpay" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><StaffPay /></PrivateRoute>
                                </React.Suspense>
                            } />

                            {/* <Route exact path="/inbox" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Inbox /></PrivateRoute>
                                </React.Suspense>
                            } /> */}
                            <Route exact path="/messageapp" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Messaging /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/sitesettings" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><SiteSettings /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/settings" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><SettingsPage /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/links" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Links /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/links/create" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><NewLink /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/kudos" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Kudos /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/kudos/:kudosID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ViewKudos /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/kudos/hashtags/:hashID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ViewKudosHash /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/noticeboard" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Notices /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/noticeboard/:noticeID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ViewNotice /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/noticeboard/create" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><NewNotice /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/noticeboard/editNotice/:noticeID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><EditNotice /></PrivateRoute>
                                </React.Suspense>
                            } />


                            <Route exact path="/vacancies" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><Vacancies /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/vacancies/:vacancyID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ViewVacancy /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/vacancies/create" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><NewVacancy /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/vacancies/editVacancy/:vacancyID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><EditVacancy /></PrivateRoute>
                                </React.Suspense>
                            } />


                            <Route exact path="/EmployeeCouncil" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><EmployeeCouncil /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/EmployeeCouncil/meetings" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ECMeetings /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/meetings/:meetID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ECThisMeeting /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/meetingedit/:meetID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ECEditMeeting /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/EmployeeCouncil/documents" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ECDocuments /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/news/:newsID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ViewCouncilNews /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/allvideos" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><AllCouncilVids /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/editnews/:newsID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><EditCouncilNews /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/addnews" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><AddCouncilNews /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/allnews" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><AllCouncilNews /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/EmployeeCouncil/ViewCouncil" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ViewCouncil /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/TribeAdventures" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><TribeAdventures /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventuresMain" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><TribeAdventuresMain /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventure/:adventureID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><TribeAdventure /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/addadventure" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><AddAdventure /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/editadventure/:adventureID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><EditAdventure /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/blog" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><BlogLanding /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/blog/view/:blogID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><BlogView /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/blog/blogadd/:adventureID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><BlogAdd /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/blog/blogadd" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><BlogAdd /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/blog/blogedit/:blogID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><BlogEdit /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/TribeAdventures/overseas" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><OverseasLanding /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/overseas/view/:blogID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><OverseasView /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/overseas/overseasadd/:adventureID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><OverseasAdd /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/overseas/overseasadd" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><OverseasAdd /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/TribeAdventures/overseas/overseasedit/:blogID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><OverseasEdit /></PrivateRoute>
                                </React.Suspense>
                            } />


                            <Route exact path="/mdnews" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><MDNews /></PrivateRoute>
                                </React.Suspense>
                            } />
                            <Route exact path="/mdnews/:MDNewsID" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ViewMDNews /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/login" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <LoginPage />
                                </React.Suspense>
                            } />
                            <Route exact path="/" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <LoginPage />
                                </React.Suspense>
                            } />
                            <Route exact path="/forgot" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <ForgotPassword />
                                </React.Suspense>
                            } />
                            <Route exact path="/register" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <RegisterPage />
                                </React.Suspense>
                            } />

                            <Route exact path="/community" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ForumMain /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ForumLandingMain /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="view/:postID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ForumPost /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="viewbytopic/:topicID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ForumTopic /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/appraisals" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><AppraisalTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="administration" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AppraisalAdmin /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="view/all" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AppraisalsContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="viewOne/:id" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AppraisalDetail /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="selfreflection" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AppraisalUpdateSelfReflection /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="feedbackRequests" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AppraisalFeedbackRequests /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="review" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AppraisalReview /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="addFeedback" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AppraisalAddFeedback /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/IT" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><ITTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="ITMP" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ITContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="ITTeam" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ITTeam /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="ITCC" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ITCC /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>


                            <Route exact path="/help" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><HelpPageTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="help" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpList /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="FAQ" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpFAQ /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="feedback" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpFeedback /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="feedbackview/:fbID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpFeedbackView /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="viewbytopic/:topicID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpTopic /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="board" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><DevBoard /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="smile" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <ParticipantJourney />
                                    </React.Suspense>
                                } />
                            </Route>


                            <Route exact path="/HelpPage" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><FAQMain /></PrivateRoute>
                                </React.Suspense>
                            }></Route>

                            <Route exact path="/fmx" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><FMXTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path=":pageID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><FMXContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="AddFAQForm" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AddFAQForm /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="edit/:faqId" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EditFAQ /></PrivateRoute>
                                    </React.Suspense>
                                } />

                            </Route>

                            <Route exact path="/help" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><HelpTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path=":pageID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="AddFAQForm" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpAddFAQForm /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="edit/:faqId" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HelpEditFAQ /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>


                            <Route exact path="/central" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><GASTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><GASContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/tasks" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><TaskTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><TaskContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/mediamanager" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><MediaTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="media" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><MediaContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="folder/:folderID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><MediaFolder /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="folder/:folderID/:subfolderID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><MediaSubFolder /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/wiki" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><WikiTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><WikiContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>

                                    <Route exact path="dash" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><WikiDash /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="search" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><WikiSearch /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="categories" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><WikiCategories /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="category/:id" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><WikiViewCategory /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="subcategories/:category_id/:id" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><WikiViewSubCategory /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="article/:id" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><WikiArticle /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                </Route>

                                <Route exact path="createNew" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><WikiCreate /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="editWiki/:id" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EditBuilder /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/opslinks" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><OpsLinksTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><OpsLinksContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/HS" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><HandSTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><HandSContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="news/:id" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ViewNews /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/learning" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><LearningLibraryTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><LearningLibraryContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="category/:id" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><ViewCategory /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/Hub_AI" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><AITemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AIContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>


                            <Route exact path="/tribesocial" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><THSMTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><THSMContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>
                                    <Route exact path="home" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><THSMDashboard /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="explore" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><THSMExplore /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="gift" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><THSMGift /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="profile/:userID" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><THSMProfile /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="myProfile" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><THSMMyProfile /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                </Route>
                            </Route>

                            <Route exact path="/data" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><DataTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><DataContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>
                                    <Route exact path="dash" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><DataDashboard /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                </Route>
                            </Route>

                            <Route exact path="/adminhub" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><AdminHubTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><AdminHubContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>
                                    <Route exact path="home" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubHome /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="roles" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubRoles /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="people-data" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubPeopleData /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="data-import" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubUserData /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="sales-data" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubSalesData /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="sales-charts" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubDataCharts /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="manage-news" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubNews /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="manage-adventures" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubAdventures /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="manage-council" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubCouncil /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="backups" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubBackups /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="manage-users" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubUserManage /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="system-alerts" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubAlerts /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="system-activity" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubSystemActivity /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="system-oncall" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubSystemOnCall /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="system-search-patterns" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubSearchPatterns /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="manage-media" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubMedia /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="manage-media/folder/:folderID" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubMediaFolder /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="manage-media/folder/:folderID/:subfolderID" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubMediaSubFolder /></PrivateRoute>
                                        </React.Suspense>
                                    } />

                                    <Route exact path="system-settings" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubSiteSettings /></PrivateRoute>
                                        </React.Suspense>
                                    } />


                                    <Route exact path="whistle-data" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><AdminHubWhistle /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                </Route>
                            </Route>
                            
                            <Route exact path="/SalesData" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><SDTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><SDContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>


                            <Route exact path="/QRCode" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><QRTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><QRContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/fuse" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><FuseTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><FuseContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>
                                    <Route exact path="dashboard" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><FuseDashboard /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="unpaid" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><FuseUnpaid /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                </Route>
                            </Route>

                            <Route exact path="/demo" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><DemoTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><DemoContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>
                                    <Route exact path="order/:orderID" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><DemoOrder /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                </Route>

                                <Route exact path="calls" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><DemoCalls /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/people" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><PeopleTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><PeopleContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>
                                    <Route exact path="dash" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><PeopleDashboard /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="sites" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><SiteData /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                </Route>
                            </Route>

                            <Route exact path="/DAM" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><DamTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><DamContainer /></PrivateRoute>
                                    </React.Suspense>
                                }>
                                    <Route exact path="library" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><DamLibrary /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="filterfiles/:filtered" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><DamFiltered /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="uploadlinks" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><DamUploadLinks /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="printrequests" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><DamPrintRequests /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                    <Route exact path="digitalrequests" element={
                                        <React.Suspense fallback={<LoadingSpinner />}>
                                            <PrivateRoute><DamDigitalRequests /></PrivateRoute>
                                        </React.Suspense>
                                    } />
                                </Route>
                            </Route>

                            <Route exact path="/equipment" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><EquipmentTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EquipmentContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="availability" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EquipmentAvailability /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="view/:itemID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EquipmentView /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="catalogue" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EquipmentCatalogue /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="reports" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EquipmentReports /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="hire" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EquipmentHire /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="admin" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><EquipmentAdmin /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/formbuilder" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><FormBuilderTemplate /></PrivateRoute>
                                </React.Suspense>
                            }>
                                <Route exact path="home" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><FormBuilderContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="build" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><FormBuilderBuild /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="forms" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><FormBuilderForms /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="forms/:formID" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><FormBuilderFormView /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/SROSearch" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><SROSearch /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route exact path="/SRO" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><SROSearchTemplate /></PrivateRoute>
                                </React.Suspense>
                            } >
                                <Route exact path="main" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><SROSearchContainer /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="SROCustomerSearch" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><SROCustomerSearch /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="SROVoucherSearch" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><SROVoucherSearch /></PrivateRoute>
                                    </React.Suspense>
                                } />

                                <Route exact path="SROSearch" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><SROSearch /></PrivateRoute>
                                    </React.Suspense>
                                } />
                                <Route exact path="all" element={
                                    <React.Suspense fallback={<LoadingSpinner />}>
                                        <PrivateRoute><SROJointSearch /></PrivateRoute>
                                    </React.Suspense>
                                } />
                            </Route>

                            <Route exact path="/firstpage" element={
                                <React.Suspense fallback={<LoadingSpinner />}>
                                    <PrivateRoute><FirstPage /></PrivateRoute>
                                </React.Suspense>
                            } />

                            <Route path="/password-reset/:resetId" element={<PasswordReset />} />
                            <Route path="*" element={!localStorage.getItem('user') ? <Navigate to="/" /> : LoginPage} />
                        </Routes>
                    </div>
                </div>
            </div>

            {serviceWorker.register()}
        </>
    )
}