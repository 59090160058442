import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'search';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const searchActions = { ...slice.actions, ...extraActions };
export const searchReducer = slice.reducer;

function createInitialState() {
    return {
        searchResults: {},
        globalSearch: {}
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_search_API_URL;

    return {
        getSearchResults: getSearchResults(),
        getGlobalSearch: getGlobalSearch(),
    };

    function getSearchResults() {
        return createAsyncThunk(
            `/${name}/getSearchResults`,
            async ({ term }) => await fetchWrapper.get(`${baseUrl}?term=${term}`)
        );
    }

    function getGlobalSearch() {
        return createAsyncThunk(
            `/${name}/getGlobalSearch`,
            async ({ term }) => await fetchWrapper.get(`${baseUrl}/global?term=${term}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getSearchResults(),
        ...getGlobalSearch(),
    };

    
    function getSearchResults() {
        var { pending, fulfilled, rejected } = extraActions.getSearchResults;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Results retrieved') {
                    state.searchResults = action.payload.data;
                }else{
                    state.searchResults = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getGlobalSearch() {
        var { pending, fulfilled, rejected } = extraActions.getGlobalSearch;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Results retrieved') {
                    state.globalSearch = action.payload.dataOther;
                }else{
                    state.globalSearch = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

}