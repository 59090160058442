import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWrapper } from '_helpers'
import { getApiUrl } from '_helpers/url'

const name = 'vacancy'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, extraReducers })

export const vacancyActions = { ...slice.actions, ...extraActions }
export const vacancyReducer = slice.reducer

function createInitialState() {
  return {
    vacancies: {},
    homevacancies: {},
    thisVacancy: {},
  }
}

function createExtraActions() {
  const baseUrl = getApiUrl(`api/vacancyapp`)

  return {
    destroyThisVacancy: destroyThisVacancy(),
    saveNewVacancy: saveNewVacancy(),
    getThisVacancy: getThisVacancy(),
    getAllVacanciesFiltered: getAllVacanciesFiltered(),
    getAllVacancies: getAllVacancies(),
    getAllVacanciesReduced: getAllVacanciesReduced(),
    editThisVacancy: editThisVacancy(),
    getHomeVacancies: getHomeVacancies(),
  }

  function destroyThisVacancy() {
    return createAsyncThunk(
      `/${name}/destroyThisVacancy`,
      async ({ vacancy_id }) =>
        await fetchWrapper.post(`${baseUrl}/vacancy/destroyThisVacancy`, {
          vacancy_id,
        })
    )
  }
  function editThisVacancy() {
    return createAsyncThunk(
      `/${name}/editThisVacancy`,
      async ({
        requirements,
        reports_to,
        title,
        status,
        location,
        vacancy_id,
      }) =>
        await fetchWrapper.post(`${baseUrl}/vacancy/editThisVacancy`, {
          requirements,
          reports_to,
          title,
          status,
          location,
          vacancy_id,
        })
    )
  }

  function saveNewVacancy() {
    return createAsyncThunk(
      `/${name}/saveNewVacancy`,
      async ({ requirements, reports_to, title, location }) =>
        await fetchWrapper.post(`${baseUrl}/vacancy/saveNewVacancy`, {
          requirements,
          reports_to,
          title,
          location,
        })
    )
  }

  function getThisVacancy() {
    return createAsyncThunk(
      `/${name}/getThisVacancy`,
      async (id) => await fetchWrapper.get(`${baseUrl}/vacancy/` + id)
    )
  }

  function getAllVacanciesFiltered() {
    return createAsyncThunk(
      `/${name}/getAllVacanciesFiltered`,
      async ({ filterSet, locationSet, pageSet }) =>
        await fetchWrapper.get(
          `${baseUrl}/vacancy?filter=` +
            String(filterSet) +
            '&location=' +
            String(locationSet) +
            '&limit=' +
            String(pageSet)
        )
    )
  }

  function getAllVacancies() {
    return createAsyncThunk(
      `/${name}/getAllVacancies`,
      async () => await fetchWrapper.get(`${baseUrl}/vacancy`)
    )
  }
  function getAllVacanciesReduced() {
    return createAsyncThunk(
      `/${name}/getAllVacancies`,
      async (incoming) =>
        await fetchWrapper.get(`${baseUrl}/vacancy` + incoming)
    )
  }
  function getHomeVacancies() {
    return createAsyncThunk(
      `/${name}/getHomeVacancies`,
      async () => await fetchWrapper.get(`${baseUrl}/getHomeVacancies`)
    )
  }
}

function createExtraReducers() {
  return {
    ...destroyThisVacancy(),
    ...saveNewVacancy(),
    ...getThisVacancy(),
    ...getAllVacanciesFiltered(),
    ...getAllVacancies(),
    ...getAllVacanciesReduced(),
    ...editThisVacancy(),
    ...getHomeVacancies(),
  }

  function editThisVacancy() {
    var { pending, fulfilled, rejected } = extraActions.editThisVacancy
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }

  function destroyThisVacancy() {
    var { pending, fulfilled, rejected } = extraActions.destroyThisVacancy
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }

  function getThisVacancy() {
    var { pending, fulfilled, rejected } = extraActions.getThisVacancy
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        console.log(action)
        if (action.payload.message === 'Successfully retrieved vacancy') {
          state.thisVacancy = action.payload.vacancy
        } else {
          state.thisVacancy = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getAllVacancies() {
    var { pending, fulfilled, rejected } = extraActions.getAllVacancies
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved vacancies') {
          state.vacancies = action.payload.vacancies
        } else {
          state.vacancy = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getAllVacanciesReduced() {
    var { pending, fulfilled, rejected } = extraActions.getAllVacanciesReduced
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved vacancies') {
          state.vacancies = action.payload.vacancies
        } else {
          state.vacancy = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getAllVacanciesFiltered() {
    var { pending, fulfilled, rejected } = extraActions.getAllVacanciesFiltered
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved vacancies') {
          state.vacancies = action.payload.vacancies
        } else {
          state.vacancy = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function getHomeVacancies() {
    var { pending, fulfilled, rejected } = extraActions.getHomeVacancies
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved vacancies') {
          state.homevacancies = action.payload.vacancies
        } else {
          state.homevacancies = null
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function saveNewVacancy() {
    var { pending, fulfilled, rejected } = extraActions.saveNewVacancy
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {},
      [rejected]: (state, action) => {},
    }
  }
}
