import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchWrapper } from '_helpers';

const name = 'equipment';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const equipmentActions = { ...slice.actions, ...extraActions };
export const equipmentReducer = slice.reducer;

function createInitialState() {
    return {
        status: 'idle',
        error: null,
        equipmentHomeData: [],
        equipmentList: [],
        displayEquipment: [],
        equipmentCategories: [],
        equipmentAvailability: [],
        equipmentMaintenance: [],
        equipmentUsage: [],
        availableEquipment: [],
        previouslyHiredEquipmentReport: [],
        currentHireStatusReport: [],
        getTotalHireDaysPerEquipment: [],
        getHireCountPerEquipment: [],
        getAverageHireDurationPerEquipment: [],
        getMostFrequentlyHiredEquipment: [],
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_equipment_API_URL;

    return {
        equipmentHome: equipmentHome(),
        updateEquipmentAdmin: updateEquipmentAdmin(),
        getThisEquipment: getThisEquipment(),
        getEquipmentCategories: getEquipmentCategories(),
        saveEquipment: saveEquipment(),
        addNewInspection: addNewInspection(),
        updateEquipment: updateEquipment(),
        deleteEquipment: deleteEquipment(),
        requestEquipmentHire: requestEquipmentHire(),
        updateEquipmentHireState: updateEquipmentHireState(),
        getEquipmentList: getEquipmentList(),
        getEquipmentAvailability: getEquipmentAvailability(),
        getEquipmentMaintenanceReport: getEquipmentMaintenanceReport(),
        getEquipmentUsage: getEquipmentUsage(),
        getAvailableEquipment: getAvailableEquipment(),
        getSearchResults: getSearchResults(),
        getGlobalSearch: getGlobalSearch(),
        storeLink: storeLink(),
        storeVideo: storeVideo(),
        updateLink: updateLink(),
        updateVideo: updateVideo(),
        deleteLink: deleteLink(),
        deleteVideo: deleteVideo(),
        updateAdmin: updateAdmin(),
    };

    function storeLink() {
        return createAsyncThunk(
            `/${name}/storeLink`,
            async (linkData) => {
                const response = await fetchWrapper.post(`${baseUrl}/storeLink`, linkData);
                if (response.message === 'Link added successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }
    function storeVideo() {
        return createAsyncThunk(
            `/${name}/storeVideo`,
            async (videoData) => {
                const response = await fetchWrapper.post(`${baseUrl}/storeVideo`, videoData);
                if (response.message === 'Video added successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateAdmin() {
        return createAsyncThunk(
            `/${name}/updateAdmin`,
            async (overviewDetails) => {
                const response = await fetchWrapper.post(`${baseUrl}/updateAdmin`, overviewDetails);
                if (response.message === 'Successfully updated admin details') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateLink() {
        return createAsyncThunk(
            `/${name}/updateLink`,
            async (linkData) => {
                const response = await fetchWrapper.post(`${baseUrl}/updateLink`, linkData);
                if (response.message === 'Link updated successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }
    function updateVideo() {
        return createAsyncThunk(
            `/${name}/updateVideo`,
            async (videoData) => {
                const response = await fetchWrapper.post(`${baseUrl}/updateVideo`, videoData);
                if (response.message === 'Video updated successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function deleteLink() {
        return createAsyncThunk(
            `/${name}/deleteLink`,
            async (linkData) => {
                const response = await fetchWrapper.post(`${baseUrl}/deleteLink`, { id: linkData });
                if (response.message === 'Link deleted successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function deleteVideo() {
        return createAsyncThunk(
            `/${name}/deleteVideo`,
            async (videoData) => {
                const response = await fetchWrapper.post(`${baseUrl}/deleteVideo`, { id: videoData });
                if (response.message === 'Video deleted successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function equipmentHome() {
        return createAsyncThunk(
            `/${name}/equipmentHome`,
            async () => await fetchWrapper.get(`${baseUrl}`)
        );
    }

    function getEquipmentList() {
        return createAsyncThunk(
            `/${name}/getEquipmentList`,
            async () => await fetchWrapper.get(`${baseUrl}/getEquipmentList`)
        );
    }

    function getThisEquipment() {
        return createAsyncThunk(
            `/${name}/getThisEquipment`,
            async (itemID) => await fetchWrapper.get(`${baseUrl}/getThisEquipment/` + itemID)
        );
    }

    function saveEquipment() {

        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/saveEquipment`,
            async (equipmentData) => await
                axios.post(`${baseUrl}/saveEquipment`, equipmentData, config).then(function (response) {
                    if (response.message === 'Successfully created equipment') {
                        return response;
                    }
                    throw new Error(response.message);
                })
        );
    }

    function addNewInspection() {
        return createAsyncThunk(
            `/${name}/addNewInspection`,
            async (inspectionData) => {
                const response = await fetchWrapper.post(`${baseUrl}/addNewInspection`, inspectionData);
                if (response.message === 'Successfully created inspection') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateEquipmentAdmin() {
        return createAsyncThunk(
            `/${name}/updateEquipmentAdmin`,
            async (adminData) => {
                const response = await fetchWrapper.post(`${baseUrl}/updateEquipmentAdmin`, adminData);
                if (response.message === 'Successfully updated equipment admin') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateEquipment() {

        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateEquipment`,
            async (equipmentData) => await
                axios.post(`${baseUrl}/updateEquipment`, equipmentData, config).then(function (response) {
                    if (response.message === 'Successfully updated equipment') {
                        return response;
                    }
                    throw new Error(response.message);
                })
        );
    }

    function deleteEquipment() {
        return createAsyncThunk(
            `/${name}/deleteEquipment`,
            async (id) => {
                const response = await fetchWrapper.get(`${baseUrl}/destroyEquipment/${id}`);
                if (response.message === 'Successfully deleted equipment') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function requestEquipmentHire() {
        return createAsyncThunk(
            `/${name}/requestEquipmentHire`,
            async (equipmentData) => {
                const response = await fetchWrapper.post(`${baseUrl}/requestEquipmentHire`, equipmentData);
                if (response.message === 'Equipment hire requested successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateEquipmentHireState() {
        return createAsyncThunk(
            `/${name}/updateEquipmentHireState`,
            async ({ id, action }) => {
                let endpoint;
                switch (action) {
                    case 'approve':
                        endpoint = 'approveEquipmentHire';
                        break;
                    case 'decline':
                        endpoint = 'rejectEquipmentHire';
                        break;
                    case 'return':
                        endpoint = 'returnEquipmentHire';
                        break;

                    case 'on-hire':
                        endpoint = 'onHireEquipmentHire';
                        break;
                    case 'delete':
                        endpoint = 'deleteRequestHire';
                        break;
                    case 'request':
                        endpoint = 'updateRequestEquipmentHire';
                        break;
                    default:
                        throw new Error(`Invalid action: ${action}`);
                }
                const response = await fetchWrapper.post(`${baseUrl}/${endpoint}`, { id });
                if (response.message === `Equipment hire updated successfully`) {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getSearchResults() {
        return createAsyncThunk(
            `/${name}/getSearchResults`,
            async (equipmentData) => {
                const response = await fetchWrapper.post(`${baseUrl}/getSearchResults`, equipmentData);
                if (response.message === 'Successfully retrieved results') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getGlobalSearch() {
        return createAsyncThunk(
            `/${name}/getGlobalSearch`,
            async (equipmentData) => {
                const response = await fetchWrapper.post(`${baseUrl}/getGlobalSearch`, equipmentData);
                if (response.message === 'Successfully retrieved results') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getEquipmentCategories() {
        return createAsyncThunk(
            `/${name}/getEquipmentList`,
            async () => await fetchWrapper.get(`${baseUrl}/getEquipmentCategories`)
        );
    }

    function getEquipmentAvailability() {
        return createAsyncThunk(
            `/${name}/getEquipmentAvailability`,
            async ({ startDate, endDate }) => await fetchWrapper.get(`${baseUrl}/getEquipmentAvailabilityReport?start_date=${startDate}&end_date=${endDate}`)
        );
    }

    function getEquipmentMaintenanceReport() {
        return createAsyncThunk(
            `/${name}/getEquipmentMaintenanceReport`,
            async () => await fetchWrapper.get(`${baseUrl}/getEquipmentMaintenanceReport`)
        );
    }

    function getEquipmentUsage() {
        return createAsyncThunk(
            `/${name}/getEquipmentUsage`,
            async ({ startDate, endDate, status, staff, equipment }) => await fetchWrapper.get(`${baseUrl}/getEquipmentUsageReport?start_date=${startDate}&end_date=${endDate}&status=${status}&staff=${staff}&equipment=${equipment}`)
        );
    }

    function getAvailableEquipment() {
        return createAsyncThunk(
            `/${name}/getAvailableEquipment`,
            async ({ startDate, endDate, type }) => await fetchWrapper.get(`${baseUrl}/getAvailableEquipment?start_date=${startDate}&end_date=${endDate}&type=${type}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...equipmentHome(),
        ...getThisEquipment(),
        ...getEquipmentList(),
        ...getEquipmentCategories(),
        ...getEquipmentAvailability(),
        ...getEquipmentMaintenanceReport(),
        ...getEquipmentUsage(),
        ...getAvailableEquipment(),
        ...saveEquipment(),
        ...addNewInspection(),
        ...updateEquipment(),
        ...deleteEquipment(),
        ...requestEquipmentHire(),
        ...updateEquipmentHireState(),
        ...updateEquipmentAdmin(),
        ...getSearchResults(),
        ...getGlobalSearch(),
        ...storeLink(),
        ...storeVideo(),
        ...updateLink(),
        ...updateVideo(),
        ...updateAdmin(),
        ...deleteLink(),
        ...deleteVideo(),
    };

    function storeLink() {
        var { pending, fulfilled, rejected } = extraActions.storeLink;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function storeVideo() {
        var { pending, fulfilled, rejected } = extraActions.storeVideo;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function updateLink() {
        var { pending, fulfilled, rejected } = extraActions.updateLink;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function updateAdmin() {
        var { pending, fulfilled, rejected } = extraActions.updateAdmin;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function updateVideo() {
        var { pending, fulfilled, rejected } = extraActions.updateVideo;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function deleteLink() {
        var { pending, fulfilled, rejected } = extraActions.deleteLink;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function deleteVideo() {
        var { pending, fulfilled, rejected } = extraActions.deleteVideo;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }





    function equipmentHome() {
        var { pending, fulfilled, rejected } = extraActions.equipmentHome;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.equipmentHomeData = action.payload.adventureKitData;
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function addNewInspection() {
        var { pending, fulfilled, rejected } = extraActions.addNewInspection;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function saveEquipment() {
        var { pending, fulfilled, rejected } = extraActions.saveEquipment;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }


    function updateEquipmentAdmin() {
        var { pending, fulfilled, rejected } = extraActions.updateEquipmentAdmin;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function updateEquipment() {
        var { pending, fulfilled, rejected } = extraActions.updateEquipment;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function deleteEquipment() {
        var { pending, fulfilled, rejected } = extraActions.deleteEquipment;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function requestEquipmentHire() {
        var { pending, fulfilled, rejected } = extraActions.requestEquipmentHire;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function updateEquipmentHireState() {
        var { pending, fulfilled, rejected } = extraActions.updateEquipmentHireState;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getSearchResults() {
        var { pending, fulfilled, rejected } = extraActions.getSearchResults;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function getGlobalSearch() {
        var { pending, fulfilled, rejected } = extraActions.getGlobalSearch;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function getThisEquipment() {
        var { pending, fulfilled, rejected } = extraActions.getThisEquipment;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.displayEquipment = action.payload;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getEquipmentList() {
        var { pending, fulfilled, rejected } = extraActions.getEquipmentList;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.equipmentList = action.payload;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getEquipmentCategories() {
        var { pending, fulfilled, rejected } = extraActions.getEquipmentCategories;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.equipmentCategories = action.payload;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getEquipmentAvailability() {
        var { pending, fulfilled, rejected } = extraActions.getEquipmentAvailability;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.equipmentAvailability = action.payload;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getEquipmentMaintenanceReport() {
        var { pending, fulfilled, rejected } = extraActions.getEquipmentMaintenanceReport;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.equipmentMaintenance = action.payload;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getAvailableEquipment() {
        const { pending, fulfilled, rejected } = extraActions.getAvailableEquipment;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.availableEquipment = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.availableEquipment = [];
                state.status = 'failed';
            },
        };
    }

    function getEquipmentUsage() {
        const { pending, fulfilled, rejected } = extraActions.getEquipmentUsage;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.equipmentUsage = action.payload.usage_report;
                state.previouslyHiredEquipmentReport = action.payload.getPreviouslyHiredEquipmentReport;
                state.currentHireStatusReport = action.payload.getCurrentHireStatusReport;
                state.getTotalHireDaysPerEquipment = action.payload.getTotalHireDaysPerEquipment;
                state.getHireCountPerEquipment = action.payload.getHireCountPerEquipment;
                state.getAverageHireDurationPerEquipment = action.payload.getAverageHireDurationPerEquipment;
                state.getMostFrequentlyHiredEquipment = action.payload.getMostFrequentlyHiredEquipment;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            },
        };
    }
}