import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import axios from 'axios';

const name = 'thsm';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const thsmActions = { ...slice.actions, ...extraActions };
export const thsmReducer = slice.reducer;

const token = JSON.parse(localStorage.getItem('token'));
const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

function createInitialState() {
    return {
        loading: false,
        error: null,
        allPosts: [],
        thisPost: [],
        thisMessage: [],
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_THSM_API_URL;

    return {
        ...basicActions(),
        ...postActions(),
    };

    function basicActions() {
        return {
            getAllPosts: createAsyncThunk(
                `/${name}/getAllPosts`,
                async (data) => await fetchWrapper.get(`${baseUrl}/tribesocial/getPosts`, data)
            ),
            getThisPost: createAsyncThunk(
                `/${name}/getThisPost`,
                async () => await fetchWrapper.get(`${baseUrl}/tribesocial/get-conversations`)
            ),
        };
    }

    function postActions() {
        return {
            postMessage: createAsyncThunk(
                `/${name}/postMessage`,
                async (data) => await
                axios.post(`${baseUrl}/tribesocial/postMessage`, data, config).then(function (response) {
                    return response.data
                })
            ),
            updatePost: createAsyncThunk(
                `/${name}/updatePost`,
                async (data) => await
                axios.post(`${baseUrl}/tribesocial/updatePost`, data, config).then(function (response) {
                    return response.data
                })
            ),
            giftPoints: createAsyncThunk(
                `/${name}/giftPoints`,
                async (data) => await
                axios.post(`${baseUrl}/tribesocial/giftPoints`, data, config).then(function (response) {
                    return response.data
                })
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
        ...postReducers(),
    };

    function basicReducers() {
        return {
            ...getAllPosts(),
            ...getThisPost(),
        };
    }

    function postReducers() {
        return {
            ...postMessage(),
            ...giftPoints(),
            ...updatePost(),
        };
    }

    function getAllPosts() {
        var { pending, fulfilled, rejected } = extraActions.getAllPosts;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Posts retrieved successfully') {
                    state.allPosts = action.payload.responseFrom;
                } else {
                    state.allPosts = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getThisPost() {
        var { pending, fulfilled, rejected } = extraActions.getThisPost;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Post retrieved successfully') {
                    state.thisPost = action.payload.responseFrom;

                } else {
                    state.thisPost = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function postMessage() {
        var { pending, fulfilled, rejected } = extraActions.postMessage;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Post created successfully') {
                    state.thisMessage = action.payload.responseFrom;
                } else {
                    state.thisMessage = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function updatePost() {
        var { pending, fulfilled, rejected } = extraActions.updatePost;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Post updated successfully') {
                    state.thisMessage = action.payload.responseFrom;
                } else {
                    state.thisMessage = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function giftPoints(){
        var { pending, fulfilled, rejected } = extraActions.giftPoints;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message.includes(' successfully')) {
                    state.thisMessage = action.payload.responseFrom;
                } else {
                    state.thisMessage = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

}