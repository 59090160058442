import React, { useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { authActions, userActions } from '_store';
import { useNavigate } from 'react-router-dom';
import { useToast } from "use-toast-mui";
import useWindowDimensions from '_hooks/useWindowDimensions';
const formReducer = (state, event) => {
    if (event.reset) {
        return {
            polite: 0,
            share: 0,
            values: 0
        }
    }

    return {
        ...state,
        [event.name]: event.value
    }
}
export default function FirstPage() {
    const { winWidth, winHeight } = useWindowDimensions();
    const { user: authUser } = useSelector(x => x.auth);
    const [formData, setFormData] = useReducer(formReducer, {});
    const toast = useToast()
    const navigate = useNavigate();
    let dispatch = useDispatch();

    const updateMyAccepting = async () => {
        try {
            if (formData.polite) {
                try {
                    const accepted = await dispatch(userActions.settingUserAsAgreed());
                    if (accepted.payload.message === 'Acceptance updated') {
                        await dispatch(authActions.refreshUser()).then((res) => {
                            if (res.payload.message === 'Successfully refreshed') {
                                navigate('/home');
                            }
                        });
                    }
                } catch (err) {
                    toast.error(err.message)
                }
            }
            return;
        } catch (error) {
            console.error(error);
        } finally {

        }
    }

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';
        setFormData({
            name: event.target.name,
            value: isCheckbox ? event.target.checked : event.target.value,
        });
    }

    return (

        <div className="flex h-full flex-col bg-white" style={{ height: (winHeight) }}>
            <div className='flex items-center justify-center px-4 py-6'>
                <div className='mx-auto w-full max-w-5xl'>
                    <div className="flex flex-col justify-center py-6 sm:px-6 lg:px-8">
                        <div className="sm:mx-auto sm:w-full sm:max-w-5xl">
                            <img className="mx-auto h-24 w-auto" src="/LogoStacked.svg" alt="Go Ape" />
                        </div>
                    </div>
                    <div className="space-y-6 divide-y divide-gray-200 pt-8 sm:space-y-5 sm:pt-10">
                        <div>
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                You’re nearly in there!
                            </h3>
                            <p className="mt-1 max-w-5xl text-sm text-gray-500">
                                We know you’re excited to get on the Tribe Hub but before you get started, we’ve just got a couple of things we need to make sure you’re in agreement with, so we can ensure everyone can have fun in a safe environment.
                            </p>
                        </div>
                        <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5">

                            <div className="pt-6 sm:pt-5">
                                <div role="group">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4">

                                        <div className="mt-4 sm:col-span-3 sm:mt-0">
                                            <div className="space-y-4">
                                                <div className="relative flex items-start">
                                                    
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label htmlFor="polite" className="font-bold text-goblue">
                                                            When I post on the Tribe Hub I will always be steered by <i>Do The Right Thing</i> <span className='text-xs'>(tick to confirm)</span>:
                                                        </label>
                                                        <ul className="text-gray-500">
                                                            <li>I will be kind and inclusive, and treat everyone with respect and dignity.</li>
                                                            <li>I won’t use language which could in any way be deemed offensive or derogatory.</li>
                                                            <li>I won’t share my personal information – or anyone else’s.</li>
                                                            <li>I won’t share confidential or sensitive company information with anyone outside of Go Ape.</li>
                                                            <li>I won’t breach any policies set out within the company handbook </li>
                                                            <li>I will uphold our company values at all times whilst using the Tribe Hub.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="ml-6 flex h-6 items-center">
                                                        <input
                                                            id="polite" name="polite"
                                                            value={formData.polite || ''}
                                                            onChange={handleChange}
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-goblue focus:ring-goblue"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-6 divide-y divide-gray-200 pt-8 sm:space-y-5 sm:pt-10">
                        <div className='flex'>
                            <div className='flex-1'>&nbsp;</div>
                            {formData.polite
                                ?
                                <button
                                    type="button"
                                    onClick={() => updateMyAccepting()}
                                    className="inline-flex justify-center rounded-md bg-goblue py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-goblue/50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-goblue/60">
                                    Agree and Continue
                                </button>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}