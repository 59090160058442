import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'qr';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const qrActions = { ...slice.actions, ...extraActions };
export const qrReducer = slice.reducer;

function createInitialState() {
    return {
        loading: false,
        error: null,
        qrSearch: [],
        allQR: [],
        allUserQR: [],
        generatedQR: [],
        generatedQRs: [],
        thisQR: [],
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_QR_API_URL;

    return {
        ...basicActions(),
    };

    function basicActions() {
        return {
            generateQR: createAsyncThunk(
                `/${name}/generateQR`,
                async (data) => await fetchWrapper.post(`${baseUrl}/qr/generateQR`, data)
            ),
            generateQRCodeForURLs: createAsyncThunk(
                `/${name}/generateQRCodeForURLs`,
                async (data) => await fetchWrapper.post(`${baseUrl}/qr/generateQRCodeForURLs`, data)
            ),
            getAllUserQR: createAsyncThunk(
                `/${name}/getAllUserQR`,
                async () => await fetchWrapper.get(`${baseUrl}/qr/getAllUserQR`)
            ),
            getAllQR: createAsyncThunk(
                `/${name}/getConversations`,
                async () => await fetchWrapper.get(`${baseUrl}/qr/getAllQR`)
            ),
            getQR: createAsyncThunk(
                `/${name}/getQR`,
                async (id) => await fetchWrapper.get(`${baseUrl}/qr/getQR/${id}`)
            ),
            deleteQR: createAsyncThunk(
                `/${name}/deleteQR`,
                async (id) => await fetchWrapper.get(`${baseUrl}/qr/deleteQR/${id}`)
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
    };

    function basicReducers() {
        return {
            ...generateQR(),
            ...getAllUserQR(),
            ...generateQRCodeForURLs(),
            ...getAllQR(),
            ...getQR(),
            ...deleteQR(),
        };
    }

    function generateQR() {
        var { pending, fulfilled, rejected } = extraActions.generateQR;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully generated QR Code') {
                    state.generatedQR = action.payload.qr_code;
                } else {
                    state.generatedQR = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function generateQRCodeForURLs(){
        var { pending, fulfilled, rejected } = extraActions.generateQRCodeForURLs;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully generated QR Codes') {
                    state.generatedQRs = action.payload.qrCodes;
                } else {
                    state.generatedQRs = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function getAllUserQR() {
        var { pending, fulfilled, rejected } = extraActions.getAllUserQR;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully fetched QR Codes') {
                    state.allUserQR = action.payload.qrCodes;
                } else {
                    state.allUserQR = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }
    
    function getAllQR() {
        var { pending, fulfilled, rejected } = extraActions.getAllQR;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully fetched QR Codes') {
                    state.allQR = action.payload.qrCodes;
                } else {
                    state.allQR = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }
    
    function getQR() {
        var { pending, fulfilled, rejected } = extraActions.getQR;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully fetched QR Code') {
                    state.thisQR = action.payload.qrCode;
                } else {
                    state.thisQR = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }

    function deleteQR(){
        var { pending, fulfilled, rejected } = extraActions.deleteQR;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.thisQR = [];
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }
}