import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '_helpers';

const name = 'formBuilder';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const formBuilderActions = { ...slice.actions, ...extraActions };
export const formBuilderReducer = slice.reducer;

function createInitialState() {
    return {
        availableForms: [],
        availableFields: [],
        thisSelectedForm: [],
        thisFormForCompletion: [],
        thisSelectedFormEntries: [],
        status: 'idle',
        error: null,
    }
}

function createExtraActions() {

    const baseUrl = process.env.REACT_APP_formbuilder_API_URL;

    return {
        availableForms: availableForms(),
        getThisForm: getThisForm(),
        showFormEntries: showFormEntries(),
        availableFields: availableFields(),
        saveForm: saveForm(),
        saveFormCompletion: saveFormCompletion(),
        updateForm: updateForm(),
        deleteForm: deleteForm(),
        getFormForCompletion: getFormForCompletion(),
        fetchFormCSV: fetchFormCSV(),
        getCPDData: getCPDData(),
        getFeedbackData: getFeedbackData(),
        getAttendanceData: getAttendanceData(),
        duplicateForm: duplicateForm(),
        updateFormQRCode: updateFormQRCode(),
        getThisFormHash: getThisFormHash(),
    };

    function duplicateForm() {
        return createAsyncThunk(
            `/${name}/duplicateForm`,
            async (formID) => {
                const response = await fetchWrapper.post(`${baseUrl}/duplicateForm`, { formID });
                if (response.message === 'Successfully duplicated form') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function fetchFormCSV() {
        return createAsyncThunk(
            `${name}/fetchFormCSV`,
            async (formID, { rejectWithValue }) => {
                try {
                    const blob = await fetchWrapper.get(`${baseUrl}/exportToCsv/${formID}`);

                    return blob;
                } catch (e) {
                    return rejectWithValue(e.message);
                }
            }
        );
    }

    function getCPDData() {
        return createAsyncThunk(
            `${name}/getCPDData`,
            async () => {
                try {
                    const blob = await fetchWrapper.get(`${baseUrl}/getCPDData`);
                    return blob;
                } catch (e) {
                    console.log(e.message)
                }
            }
        );
    }

    function getFeedbackData() {
        return createAsyncThunk(
            `${name}/getFeedbackData`,
            async () => {
                try {
                    const blob = await fetchWrapper.get(`${baseUrl}/getFeedbackData`);
                    return blob;
                } catch (e) {
                    console.log(e.message)
                }
            }
        );
    }

    function getAttendanceData() {
        return createAsyncThunk(
            `${name}/getAttendanceData`,
            async () => {
                try {
                    const blob = await fetchWrapper.get(`${baseUrl}/getAttendanceData`);
                    return blob;
                } catch (e) {
                    console.log(e.message)
                }
            }
        );
    }

    function updateFormQRCode() {
        return createAsyncThunk(
            `/${name}/updateFormQRCode`,
            async ({ qrCodeLink, formID }) => {
                const response = await fetchWrapper.post(`${baseUrl}/updateFormQRCode`, { qrCodeLink, formID });
                if (response.message === 'Successfully updated form') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function saveForm() {
        return createAsyncThunk(
            `/${name}/saveForm`,
            async (formData) => {
                const response = await fetchWrapper.post(`${baseUrl}/saveForm`, formData);
                if (response.message === 'Successfully saved form') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function saveFormCompletion() {
        return createAsyncThunk(
            `/${name}/saveFormCompletion`,
            async (formData) => {
                const response = await fetchWrapper.post(`${baseUrl}/saveFormCompletion`, formData);
                if (response.message === 'Successfully saved form submission') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateForm() {
        return createAsyncThunk(
            `/${name}/updateForm`,
            async (formData) => {
                const response = await fetchWrapper.post(`${baseUrl}/updateForm`, formData);
                if (response.message === 'Successfully updated form') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function deleteForm() {
        return createAsyncThunk(
            `/${name}/deleteForm`,
            async (formID) => {
                const response = await fetchWrapper.post(`${baseUrl}/deleteForm`, { id: formID });
                if (response.message === 'Successfully deleted form') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }
    function showFormEntries() {
        return createAsyncThunk(
            `/${name}/showFormEntries`,
            async (entryID) => await fetchWrapper.get(`${baseUrl}/showFormEntries/` + entryID)
        );
    }

    function getThisForm() {
        return createAsyncThunk(
            `/${name}/getThisForm`,
            async (formID) => await fetchWrapper.get(`${baseUrl}/showForm/` + formID)
        );
    }

    function getThisFormHash() {
        return createAsyncThunk(
            `/${name}/getThisFormHash`,
            async (formID) => await fetchWrapper.get(`${baseUrl}/showFormHash/` + formID)
        );
    }

    function getFormForCompletion() {
        return createAsyncThunk(
            `/${name}/getFormForCompletion`,
            async (formID) => await fetchWrapper.get(`${baseUrl}/completeForm/` + formID)
        );
    }

    function availableForms() {
        return createAsyncThunk(
            `/${name}/availableForms`,
            async () => await fetchWrapper.get(`${baseUrl}/availableForms`)
        );
    }

    function availableFields() {
        return createAsyncThunk(
            `/${name}/availableFields`,
            async () => await fetchWrapper.get(`${baseUrl}/availableFields`)
        );
    }

}

function createExtraReducers() {
    return {
        ...availableForms(),
        ...getThisForm(),
        ...getThisFormHash(),
        ...getFormForCompletion(),
        ...showFormEntries(),
        ...availableFields(),
        ...saveForm(),
        ...saveFormCompletion(),
        ...updateForm(),
        ...deleteForm(),
        ...fetchFormCSV(),
        ...getCPDData(),
        ...getFeedbackData(),
        ...getAttendanceData(),
    };

    function getAttendanceData() {
        var { pending, fulfilled, rejected } = extraActions.getAttendanceData;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    const url = window.URL.createObjectURL(new Blob([action.payload], { type: 'text/csv' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `responses-${new Date().toISOString()}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                    state.status = 'succeeded';
                    state.error = null;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function fetchFormCSV() {
        var { pending, fulfilled, rejected } = extraActions.fetchFormCSV;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    const url = window.URL.createObjectURL(new Blob([action.payload], { type: 'text/csv' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `responses-${new Date().toISOString()}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                    state.status = 'succeeded';
                    state.error = null;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getCPDData() {
        var { pending, fulfilled, rejected } = extraActions.getCPDData;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    const url = window.URL.createObjectURL(new Blob([action.payload], { type: 'text/csv' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `responses-${new Date().toISOString()}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                    state.status = 'succeeded';
                    state.error = null;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getFeedbackData() {
        var { pending, fulfilled, rejected } = extraActions.getFeedbackData;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    const url = window.URL.createObjectURL(new Blob([action.payload], { type: 'text/csv' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `responses-${new Date().toISOString()}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                    state.status = 'succeeded';
                    state.error = null;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function saveForm() {
        var { pending, fulfilled, rejected } = extraActions.saveForm;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function saveFormCompletion() {
        var { pending, fulfilled, rejected } = extraActions.saveFormCompletion;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function updateForm() {
        var { pending, fulfilled, rejected } = extraActions.updateForm;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function deleteForm() {
        var { pending, fulfilled, rejected } = extraActions.deleteForm;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getFormForCompletion() {
        var { pending, fulfilled, rejected } = extraActions.getFormForCompletion;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.thisFormForCompletion = action.payload.thisForm;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getThisForm() {
        var { pending, fulfilled, rejected } = extraActions.getThisForm;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.thisSelectedForm = action.payload.thisForm;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getThisFormHash() {
        var { pending, fulfilled, rejected } = extraActions.getThisFormHash;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.thisSelectedForm = action.payload.thisForm;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function showFormEntries() {
        var { pending, fulfilled, rejected } = extraActions.showFormEntries;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.thisSelectedFormEntries = action.payload.thisFormEntries;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }


    function availableForms() {
        var { pending, fulfilled, rejected } = extraActions.availableForms;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.availableForms = action.payload.forms;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function availableFields() {
        var { pending, fulfilled, rejected } = extraActions.availableFields;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.availableFields = action.payload.fields;
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

}