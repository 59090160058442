import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { fetchWrapper } from '_helpers';

const name = 'adventure';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const adventureActions = { ...slice.actions, ...extraActions };
export const adventureReducer = slice.reducer;

function createInitialState() {
    return {
        adventureDash: {},
        blogList: {},
        blogItem: {},
        overseasList: {},
        overseasItem: {},
        galleryList: {},
        galleryItem: {},
        adventureList: {},
        priorList: {},
        adventureItem: {},
        applicationList: {},
        applicationItem: {},
        overviewDeets: {},
        infiniScrollList: {},
        myAnswers: {},
        gettingForm: {},
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_adventure_API_URL;

    return {
        getDashDetails: getDashDetails(),
        updateAdventureInfo: updateAdventureInfo(),
        getBlogPosts: getBlogPosts(),
        getBlogItem: getBlogItem(),
        getAdventureOverview: getAdventureOverview(),
        addNewBlog: addNewBlog(),
        updateThisBlog: updateThisBlog(),
        destroyThisBlog: destroyThisBlog(),
        getMyAnswers: getMyAnswers(),
        getOverseasPosts: getOverseasPosts(),
        getOverseasItem: getOverseasItem(),
        addNewOverseas: addNewOverseas(),
        updateThisOverseas: updateThisOverseas(),
        destroyThisOverseas: destroyThisOverseas(),

        getGallery: getGallery(),
        addToGallery: addToGallery(),
        editGallery: editGallery(),
        destroyGallery: destroyGallery(),

        getAdventures: getAdventures(),
        getAdventure: getAdventure(),
        addAdventure: addAdventure(),
        updateAdventure: updateAdventure(),
        destroyAdventure: destroyAdventure(),
        fetchInfiniScroll: fetchInfiniScroll(),
        getApplications: getApplications(),
        getApplication: getApplication(),
        addApplication: addApplication(),
        updateApplication: updateApplication(),
        updateApplicationStatus: updateApplicationStatus(),
        updatePayment: updatePayment(),
        destroyApplication: destroyApplication(),
    };

    function getMyAnswers(){
        return createAsyncThunk(
            `/${name}/getMyAnswers`,
            async ({userID, formID}) => await fetchWrapper.get(`${baseUrl}/getMyAnswers/${userID}/${formID}`)
        );
    }
    
    function addToGallery() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addToGallery`,
            async (data) => await
                axios.post(`${baseUrl}/addtogallery`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function updateAdventureInfo() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateAdventureInfo`,
            async (data) => await
                axios.post(`${baseUrl}/updateAdventureInfo`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function editGallery() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editGallery`,
            async (data) => await
                axios.post(`${baseUrl}/editgallery`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyGallery() {
        return createAsyncThunk(
            `/${name}/destroyGallery`,
            async ({ tribe_adventure_galleries_id }) => await fetchWrapper.post(`${baseUrl}/destroygallery`, { tribe_adventure_galleries_id })
        );
    }

    function addNewBlog() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addNewBlog`,
            async (data) => await
                axios.post(`${baseUrl}/blog/saveNewBlog`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function getBlogPosts() {
        return createAsyncThunk(
            `/${name}/getBlogPosts`,
            async () => await fetchWrapper.get(`${baseUrl}/blog`)
        );
    }
    function getBlogItem() {
        return createAsyncThunk(
            `/${name}/getBlogItem`,
            async ({ id }) => await fetchWrapper.get(`${baseUrl}/blog/${id}`)
        );
    }

    function getAdventureOverview() {
        return createAsyncThunk(
            `/${name}/getAdventureOverview`,
            async () => await fetchWrapper.get(`${baseUrl}/getAdventureOverview`)
        );
    }
    function updateThisBlog() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateThisBlog`,
            async (data) => await
                axios.post(`${baseUrl}/blog/editThisBlog`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyThisBlog() {
        return createAsyncThunk(
            `/${name}/destroyThisBlog`,
            async ({ blog_id }) => await fetchWrapper.post(`${baseUrl}/blog/destroyThisBlog`, { blog_id })
        );
    }

    function addNewOverseas() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addNewOverseas`,
            async (data) => await
                axios.post(`${baseUrl}/overseas/saveNewOverseas`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function getOverseasPosts() {
        return createAsyncThunk(
            `/${name}/getOverseasPosts`,
            async () => await fetchWrapper.get(`${baseUrl}/overseas`)
        );
    }
    function getOverseasItem() {
        return createAsyncThunk(
            `/${name}/getOverseasItem`,
            async ({ id }) => await fetchWrapper.get(`${baseUrl}/overseas/${id}`)
        );
    }
    function updateThisOverseas() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateThisOverseas`,
            async (data) => await
                axios.post(`${baseUrl}/overseas/editThisOverseas`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyThisOverseas() {
        return createAsyncThunk(
            `/${name}/destroyThisOverseas`,
            async ({ blog_id }) => await fetchWrapper.post(`${baseUrl}/overseas/destroyThisOverseas`, { blog_id })
        );
    }

    function addAdventure() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/addAdventure`,
            async (data) => await
                axios.post(`${baseUrl}/addadventure`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function updateAdventure() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateAdventure`,
            async (data) => await
                axios.post(`${baseUrl}/editadventure`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function destroyAdventure() {
        return createAsyncThunk(
            `/${name}/destroyAdventure`,
            async ({ tribe_adventures_id }) => await fetchWrapper.post(`${baseUrl}/destroyadventure`, { tribe_adventures_id })
        );
    }

    function addApplication() {
        return createAsyncThunk(
            `/${name}/addapplication`,
            async ({ tribe_adventures_id }) => await fetchWrapper.post(`${baseUrl}/addapplication`, { tribe_adventures_id })
        );
    }

    function fetchInfiniScroll() {
        return createAsyncThunk(
            `/${name}/fetchInfiniScroll`,
            async ({filterOf,pageOf,limitOf}) => 
            await fetchWrapper.get(`${baseUrl}/getInfini?query=`  + String(filterOf) + "&page=" + String(pageOf) + "&limit=" + String(limitOf))
        );
    }

    function updateApplication() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/updateApplication`,
            async (data) => await
                axios.post(`${baseUrl}/editapplication`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function updateApplicationStatus() {
        return createAsyncThunk(
            `/${name}/updateapplicationstatus`,
            async ({ tribe_adventures_application_id, status }) => await fetchWrapper.post(`${baseUrl}/updateapplicationstatus`, { tribe_adventures_application_id, status })
        );
    }

    function updatePayment() {
        return createAsyncThunk(
            `/${name}/updatePayment`,
            async ({ tribe_adventures_application_id, paid }) => await fetchWrapper.post(`${baseUrl}/updatepayment`, { tribe_adventures_application_id, paid })
        );
    }

    function destroyApplication() {
        return createAsyncThunk(
            `/${name}/destroyApplication`,
            async ({ application_id }) => await fetchWrapper.post(`${baseUrl}/destroyapplication`, { application_id })
        );
    }
    function getDashDetails() {
        return createAsyncThunk(
            `/${name}/getDashDetails`,
            async () => await fetchWrapper.get(`${baseUrl}/getDashDetails`)
        );
    }


    function getGallery() {
        return createAsyncThunk(
            `/${name}/getGallery`,
            async () => await fetchWrapper.get(`${baseUrl}/gallery`)
        );
    }
    function getAdventures() {
        return createAsyncThunk(
            `/${name}/getAdventures`,
            async () => await fetchWrapper.get(`${baseUrl}/adventures`)
        );
    }
    function getAdventure() {
        return createAsyncThunk(
            `/${name}/getAdventure`,
            async ({ id }) => await fetchWrapper.get(`${baseUrl}/adventure/${id}`)
        );
    }
    function getApplications() {
        return createAsyncThunk(
            `/${name}/applications`,
            async () => await fetchWrapper.get(`${baseUrl}/application`)
        );
    }
    function getApplication() {
        return createAsyncThunk(
            `/${name}/getApplication`,
            async ({ id }) => await fetchWrapper.get(`${baseUrl}/application/${id}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getDashDetails(),
        ...getBlogPosts(),
        ...getBlogItem(),
        ...getAdventureOverview(),
        ...addNewBlog(),
        ...updateThisBlog(),
        ...destroyThisBlog(),
        ...getOverseasPosts(),
        ...getOverseasItem(),
        ...addNewOverseas(),
        ...updateThisOverseas(),
        ...destroyThisOverseas(),
        ...getGallery(),
        ...addToGallery(),
        ...editGallery(),
        ...destroyGallery(),
        ...getAdventures(),
        ...getMyAnswers(),
        ...getAdventure(),
        ...addAdventure(),
        ...updateAdventure(),
        ...destroyAdventure(),
        ...getApplications(),
        ...getApplication(),
        ...addApplication(),
        ...updateApplication(),
        ...updateApplicationStatus(),
        ...updatePayment(),
        ...destroyApplication(),
        ...updateAdventureInfo(),
        ...fetchInfiniScroll(),
    };


    function getDashDetails() {
        var { pending, fulfilled, rejected } = extraActions.getDashDetails;
        return {
            [pending]: (state) => {
                state.adventureDash = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Dash Details retrieved') {
                    state.adventureDash = action.payload.dash;
                } else {
                    state.adventureDash = [];
                }
            },
            [rejected]: (state, action) => {
                state.adventureDash = { error: action.error };
            }
        };
    }

    function fetchInfiniScroll() {
        var { pending, fulfilled, rejected } = extraActions.fetchInfiniScroll;
        return {
            [pending]: (state) => {
                state.infiniScrollList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Adventure Details retrieved') {
                    state.infiniScrollList = action.payload.dash;
                } else {
                    state.infiniScrollList = [];
                }
            },
            [rejected]: (state, action) => {
                state.infiniScrollList = { error: action.error };
            }
        };
    }
    

    function addAdventure() {
        var { pending, fulfilled, rejected } = extraActions.addAdventure;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function addApplication() {
        var { pending, fulfilled, rejected } = extraActions.addApplication;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function addNewBlog() {
        var { pending, fulfilled, rejected } = extraActions.addNewBlog;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getBlogPosts() {
        var { pending, fulfilled, rejected } = extraActions.getBlogPosts;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved blog') {
                    state.blogList = action.payload.blogItems;
                } else {
                    state.blogList = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getBlogItem() {
        var { pending, fulfilled, rejected } = extraActions.getBlogItem;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved blog') {
                    state.blogItem = action.payload.blogItem;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getAdventureOverview() {
        var { pending, fulfilled, rejected } = extraActions.getAdventureOverview;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Adventure Details retrieved') {
                    state.overviewDeets = action.payload.dash.tribeAdventuresDeets;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    
    function getMyAnswers() {
        var { pending, fulfilled, rejected } = extraActions.getMyAnswers;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved answers') {
                    state.myAnswers = action.payload.myAnswers;
                    state.gettingForm = action.payload.gettingForm;
                    
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function updateThisBlog() {
        var { pending, fulfilled, rejected } = extraActions.updateThisBlog;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully edited blog') {
                    
                } else {
                    state.blogItem = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function destroyThisBlog() {
        var { pending, fulfilled, rejected } = extraActions.destroyThisBlog;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                state.blogItem = null;
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getGallery() {
        var { pending, fulfilled, rejected } = extraActions.getGallery;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved gallery') {
                    state.galleryList = action.payload.galleryList;
                } else {
                    state.galleryList = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function addToGallery() {
        var { pending, fulfilled, rejected } = extraActions.addToGallery;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function editGallery() {
        var { pending, fulfilled, rejected } = extraActions.editGallery;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function destroyGallery() {
        var { pending, fulfilled, rejected } = extraActions.destroyGallery;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                state.galleryItem = null;
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getAdventures() {
        var { pending, fulfilled, rejected } = extraActions.getAdventures;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved adventures') {
                    state.priorList = action.payload.adventureList.priorAdventures;
                    state.adventureList = action.payload.adventureList.adventureList;
                } else {
                    state.adventureList = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getAdventure() {
        var { pending, fulfilled, rejected } = extraActions.getAdventure;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved adventure') {
                    state.adventureItem = action.payload.adventureItem;
                } else {
                    state.adventureItem = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }


    function updateAdventure() {
        var { pending, fulfilled, rejected } = extraActions.updateAdventure;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function destroyAdventure() {
        var { pending, fulfilled, rejected } = extraActions.destroyAdventure;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getApplications() {
        var { pending, fulfilled, rejected } = extraActions.getApplications;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved applications') {
                    state.applicationList = action.payload.applicationList;
                } else {
                    state.applicationList = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getApplication() {
        var { pending, fulfilled, rejected } = extraActions.getApplication;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved application') {
                    state.applicationItem = action.payload.applicationItem;
                } else {
                    state.applicationItem = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function updateApplication() {
        var { pending, fulfilled, rejected } = extraActions.updateApplication;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function updateApplicationStatus() {
        var { pending, fulfilled, rejected } = extraActions.updateApplicationStatus;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function updatePayment() {
        var { pending, fulfilled, rejected } = extraActions.updatePayment;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }


    function destroyApplication() {
        var { pending, fulfilled, rejected } = extraActions.destroyApplication;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function addNewOverseas() {
        var { pending, fulfilled, rejected } = extraActions.addNewOverseas;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getOverseasPosts() {
        var { pending, fulfilled, rejected } = extraActions.getOverseasPosts;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved adventure fund activity') {
                    state.overseasList = action.payload.blogItems;
                } else {
                    state.overseasList = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function getOverseasItem() {
        var { pending, fulfilled, rejected } = extraActions.getOverseasItem;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved adventure fund activity') {
                    state.overseasItem = action.payload.blogItem;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function updateThisOverseas() {
        var { pending, fulfilled, rejected } = extraActions.updateThisOverseas;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully edited adventure fund article!') {
                    
                } else {
                    state.overseasItem = null;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function destroyThisOverseas() {
        var { pending, fulfilled, rejected } = extraActions.destroyThisOverseas;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                state.overseasItem = null;
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

    function updateAdventureInfo() {
        var { pending, fulfilled, rejected } = extraActions.updateAdventureInfo;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {
                
            }
        };
    }

}