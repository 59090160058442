import { useSelector } from 'react-redux';

function useHasRole(roles) {
  const authUser = useSelector(state => state.auth.user);
  if (!authUser || !authUser.roles) {
    return false;
  }
  const userRoles = authUser.roles.map(role => role.name);
  return roles.some(role => userRoles.includes(role));
}

export default useHasRole;