import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import axios from 'axios';

const name = 'gasApp';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
    name, initialState, extraReducers
});

export const gasAppActions = { ...slice.actions, ...extraActions };
export const gasAppReducer = slice.reducer;

function removeDuplicateMessages(messages) {
    const uniqueMessagesMap = new Map();
    messages.forEach(message => {
        if (!uniqueMessagesMap.has(message.id)) {
            uniqueMessagesMap.set(message.id, message);
        }
    });
    return Array.from(uniqueMessagesMap.values());
}


function createInitialState() {
    return {
        appList: [],
        entitlementList: [],
        userChannel: [],
        userChannelCount: [],
        digestMessages: [],
        approvalList: [],
        openSubmissions: [],
        approvedSubmissions: [],
        rejectedSubmissions: [],
        completedSubmissions: [],
        appDetails: [],
        channelList: [],
        channelDetails: [],
        dmList: [],
        people: [],
        publicOverview: [],
        channelMessages: [],
        messageThread: [],
        currentRota: [],
        locationSkills: [],
        shiftCount: [],
        siteSkills: [],
        thisSiteUsers: [],
        thisSiteSkills: [],
        thisSiteSkillsOther: [],
        fetching: false,
        staffRota: [],
        userEvents: [],
        userSkills: [],
        error: null,
        pagination: {
            current_page: 1,
            last_page: 1,
            has_more_pages: false,
            per_page: 50,
            total: 0
        }
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_gasApp_API_URL;

    return {
        dashData: dashData(),
        getChannelMessages: getChannelMessages(),
        getMessageThread: getMessageThread(),
        sendReaction: sendReaction(),
        sendNewMessage: sendNewMessage(),
        sendNewThreadMessage: sendNewThreadMessage(),
        appendReceivedMessage: appendReceivedMessage(),
        updateReaction: updateReaction(),
        uploadFile: uploadFile(),
        searchMembers: searchMembers(),
        searchMembersDM: searchMembersDM(),
        clockInOut: clockInOut(),
        addMemberToChannel: addMemberToChannel(),
        createNewChannel: createNewChannel(),
        createNewDM: createNewDM(),
        searchForPublicChannels: searchForPublicChannels(),
        joinPublicChannel: joinPublicChannel(),
        getAppDetails: getAppDetails(),
        addnewSubmission: addnewSubmission(),
        approveSubmission: approveSubmission(),
        rejectSubmission: rejectSubmission(),
        printSubmission: printSubmission(),
        completePermission: completePermission(),
        getUserEntitlements: getUserEntitlements(),
        getDigestMessages: getDigestMessages(),
        updateChannelDetails: updateChannelDetails(),
        getSkillCountsForWeek: getSkillCountsForWeek(),
        getRotaForOneWeek: getRotaForOneWeek(),
        assignAllStaffForWeek: assignAllStaffForWeek(),
        unassignAllStaffForWeek: unassignAllStaffForWeek(),
        closeSiteForWeek: closeSiteForWeek(),
        closeSiteForDay: closeSiteForDay(),
        getStaffShiftCountsForWeek: getStaffShiftCountsForWeek(),
        getSkillsAndUserCountsForLocation: getSkillsAndUserCountsForLocation(),
        getLocationUsersAndSkills: getLocationUsersAndSkills(),
        toggleUserSkill: toggleUserSkill(),
        getUserRotas: getUserRotas(),
        updateUserAvailability: updateUserAvailability(),
        searchForUser: searchForUser(),
        deleteMessage: deleteMessage(),
        updateUserDetails: updateUserDetails(),
        createNewEvent: createNewEvent(),
        updateEvent: updateEvent(),
        getEventsByUserId: getEventsByUserId(),
        multiAssign: multiAssign(),
        submitWeekRequirements: submitWeekRequirements(),
        removeAssignment: removeAssignment()
    };

    function submitWeekRequirements() {
        return createAsyncThunk(
            `/${name}/submitWeekRequirements`,
            async ({required_number_of_staff, date, gas_skill_id, gas_location_id}) => {
                const response = await fetchWrapper.post(`${baseUrl}/rotas/submitWeekRequirements`, {required_number_of_staff, date, gas_skill_id, gas_location_id});
                if (response.message === 'Week requirements submitted successfully.') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function multiAssign() {
        return createAsyncThunk(
            `/${name}/multiAssign`,
            async ({ incomingUsers, date }) => {
                const response = await fetchWrapper.post(`${baseUrl}/rotas/multiAssign`, { incomingUsers, date });
                if (response.message === 'Activity assigned successfully.') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function clockInOut() {
        return createAsyncThunk(
            `/${name}/clockInOut`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/rotas/clockInOut`, payload);
                if (response.message === 'User clocked in/out successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getEventsByUserId() {
        return createAsyncThunk(
            `/${name}/getEventsByUserId`,
            async ({ userId, dateOf }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/getEventsByUserId/${userId}/${dateOf}`);
                if (response.message === 'Events retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function createNewEvent() {
        return createAsyncThunk(
            `/${name}/createNewEvent`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/rotas/createEvent`, payload);
                if (response.message === 'Event created successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateEvent() {
        return createAsyncThunk(
            `/${name}/updateEvent`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/rotas/updateEvent`, payload);
                if (response.message === 'Event updated successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateUserDetails() {
        return createAsyncThunk(
            `/${name}/updateUserDetails`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channels/updateStatus`, payload);
                if (response.message === 'Status updated successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }
    function deleteMessage() {
        return createAsyncThunk(
            `/${name}/deleteMessage`,
            async ({ messageId }) => {
                const response = await fetchWrapper.get(`${baseUrl}/channel/message/delete/${messageId}`);
                if (response.message === 'Message deleted successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function appendReceivedMessage() {
        return createAsyncThunk(
            `/${name}/appendReceivedMessage`,
            async (arg) => {
                return arg
            }
        );
    }

    function updateReaction() {
        return createAsyncThunk(
            `/${name}/updateReaction`,
            async (arg) => {
                return arg
            }
        );
    }

    function dashData() {
        return createAsyncThunk(
            `/${name}/dashData`,
            async (id) => {
                const response = await fetchWrapper.get(`${baseUrl}/dashboard-overview`);
                if (response.message === 'Dashboard overview retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getChannelMessages() {
        return createAsyncThunk(
            `/${name}/getChannelMessages`,
            async ({ channelId, page, isNewThread }) => {
                const response = await fetchWrapper.get(`${baseUrl}/channel/${channelId}/messages?page=${page}&isNewThread=${isNewThread}`);
                if (response.message === 'Successfully retrieved channel messages') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getMessageThread() {
        return createAsyncThunk(
            `/${name}/getMessageThread`,
            async (messageId) => {
                const response = await fetchWrapper.get(`${baseUrl}/channel/messagethread/${messageId}`);
                if (response.message === 'Successfully retrieved messages thread') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function sendReaction() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/sendReaction`,
            async (payload) => await
                axios.post(`${baseUrl}/channel/message/reaction`, payload, config).then(function (response) {
                    return response.data
                })
        );
    }

    function uploadFile() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/uploadFile`,
            async (payload) => await
                axios.post(`${baseUrl}/channel/message/upload`, payload, config).then(function (response) {
                    return response.data
                })
        );
    }

    function sendNewMessage() {
        return createAsyncThunk(
            `/${name}/sendNewMessage`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channel/sendMessage`, payload);
                if (response.message === 'Message sent successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function sendNewThreadMessage() {
        return createAsyncThunk(
            `/${name}/sendNewThreadMessage`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channel/messagethread/addMessage`, payload);
                if (response.message === 'Message sent successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function searchMembers() {
        return createAsyncThunk(
            `/${name}/searchMembers`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channel/${payload?.channelId}/searchMember`, payload);
                if (response.message === 'Co-owners found successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function searchMembersDM() {
        return createAsyncThunk(
            `/${name}/searchMembersDM`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/dm/searchMember`, payload);
                if (response.message === 'Co-owners found successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function addMemberToChannel() {
        return createAsyncThunk(
            `/${name}/addMemberToChannel`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channel/${payload?.channelId}/addMember`, payload);
                if (response.message === 'Successfully added member to channel') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function createNewChannel() {
        return createAsyncThunk(
            `/${name}/createNewChannel`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channel`, payload);
                if (response.message === 'Successfully created channel') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function createNewDM() {
        return createAsyncThunk(
            `/${name}/createNewDM`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/dm`, payload);
                if (response.message === 'Direct message started successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function searchForPublicChannels() {
        return createAsyncThunk(
            `/${name}/searchForPublicChannels`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channels/search`, payload);
                if (response.message === 'Public channels retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function joinPublicChannel() {
        return createAsyncThunk(
            `/${name}/joinPublicChannel`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/channels/joinPublicChannel`, payload);
                if (response.message === 'Successfully joined channel') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getAppDetails() {
        return createAsyncThunk(
            `/${name}/getAppDetails`,
            async (appId) => {
                const response = await fetchWrapper.get(`${baseUrl}/apps/${appId}`);
                if (response.message === 'App details retrieved successfully.') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function addnewSubmission() {
        return createAsyncThunk(
            `/${name}/addnewSubmission`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/apps/start`, payload);
                if (response.message === 'Submission created successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function approveSubmission() {
        return createAsyncThunk(
            `/${name}/approveSubmission`,
            async ({ appId, submissionId }) => {
                const response = await fetchWrapper.get(`${baseUrl}/app-instances/${appId}/steps/approve/${submissionId}`);
                if (response.message === 'Submission approved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function rejectSubmission() {
        return createAsyncThunk(
            `/${name}/rejectSubmission`,
            async ({ appId, submissionId }) => {
                const response = await fetchWrapper.get(`${baseUrl}/app-instances/${appId}/steps/reject/${submissionId}`);
                if (response.message === 'Submission rejected successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function printSubmission() {
        return createAsyncThunk(
            `/${name}/printSubmission`,
            async (submissionId) => {
                const response = await fetchWrapper.get(`${baseUrl}/app-instances/steps/print/${submissionId}`);
                if (response.message === 'Submission printed successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function completePermission() {
        return createAsyncThunk(
            `/${name}/completePermission`,
            async (submissionId) => {
                const response = await fetchWrapper.get(`${baseUrl}/app-instances/steps/complete/${submissionId}`);
                if (response.message === 'Submission completed successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getDigestMessages() {
        return createAsyncThunk(
            `/${name}/getDigestMessages`,
            async () => {
                const response = await fetchWrapper.get(`${baseUrl}/userchannel`);
                if (response.message === 'User digest messages retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getUserEntitlements() {
        return createAsyncThunk(
            `/${name}/getUserEntitlements`,
            async () => {
                const response = await fetchWrapper.get(`${baseUrl}/user/entitlements`);
                if (response.message === 'User entitlements retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function removeAssignment(){
        return createAsyncThunk(
            `/${name}/removeAssignment`,
            async ({ teamId, date, userId }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/removeAssignment/${teamId}/${date}/${userId}`);
                if (response.message === 'Assignment removed successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getRotaForOneWeek() {
        return createAsyncThunk(
            `/${name}/getRotaForOneWeek`,
            async ({ teamId, isoDate }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/getRotaForTeamForWeek/${teamId}/${isoDate}`);
                if (response.message === 'Rota retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function assignAllStaffForWeek() {
        return createAsyncThunk(
            `/${name}/assignAllStaffForWeek`,
            async ({ teamId, isoDate }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/assignAllStaffForWeek/${teamId}/${isoDate}`);
                if (response.message === 'All staff assigned successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function unassignAllStaffForWeek(){
        return createAsyncThunk(
            `/${name}/unassignAllStaffForWeek`,
            async ({ teamId, isoDate }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/unassignAllStaffForWeek/${teamId}/${isoDate}`);
                if (response.message === 'All staff unassigned successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function closeSiteForWeek(){
        return createAsyncThunk(
            `/${name}/closeSiteForWeek`,
            async ({ teamId, isoDate }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/closeSiteForWeek/${teamId}/${isoDate}`);
                if (response.message === 'Site closed successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function closeSiteForDay(){
        return createAsyncThunk(
            `/${name}/closeSiteForDay`,
            async ({ teamId, date }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/closeSiteForDay/${teamId}/${date}`);
                if (response.message === 'Site closed successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getSkillCountsForWeek() {
        return createAsyncThunk(
            `/${name}/getSkillCountsForWeek`,
            async ({ teamId, isoDate }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/getSkillCountsForWeek/${teamId}/${isoDate}`);
                if (response.message === 'Skill counts retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getStaffShiftCountsForWeek() {
        return createAsyncThunk(
            `/${name}/getStaffShiftCountsForWeek`,
            async ({ teamId, isoDate }) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/getStaffShiftCountsForWeek/${teamId}/${isoDate}`);
                if (response.message === 'Staff shift counts retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getSkillsAndUserCountsForLocation() {
        return createAsyncThunk(
            `/${name}/getSkillsAndUserCountsForLocation`,
            async (locationId) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/getSkillsAndUserCountsForLocation/${locationId}`);
                if (response.message === 'Skills and user counts retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function getLocationUsersAndSkills() {
        return createAsyncThunk(
            `/${name}/getLocationUsersAndSkills`,
            async (locationId) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/getLocationUsersAndSkills/${locationId}`);
                if (response.message === 'Location users and skills retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function toggleUserSkill() {
        return createAsyncThunk(
            `/${name}/toggleUserSkill`,
            async ({ userId, skillName }) => {
                const response = await fetchWrapper.post(`${baseUrl}/rotas/toggleSkill`, { userId, skillName });
                if (response.message === 'User skill toggled successfully') {
                    return { userId, skillName, toggled: response.toggled, message: response.message };
                }
                throw new Error(response.message);
            }
        );
    }

    function getUserRotas() {
        return createAsyncThunk(
            `/${name}/getUserRotas`,
            async (isoDate) => {
                const response = await fetchWrapper.get(`${baseUrl}/rotas/getUserRotas/${isoDate}`);
                if (response.message === 'User rotas retrieved successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateChannelDetails() {
        return createAsyncThunk(
            `/${name}/updateChannelDetails`,
            async (editedData) => {
                const response = await fetchWrapper.post(`${baseUrl}/channels/updateDetails`, editedData);
                if (response.message === 'Details updated successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function updateUserAvailability() {
        return createAsyncThunk(
            `/${name}/updateUserAvailability`,
            async (payload) => {
                const response = await fetchWrapper.post(`${baseUrl}/rotas/updateUserAvailability`, payload);
                if (response.message === 'User availability updated successfully') {
                    return response;
                }
                throw new Error(response.message);
            }
        );
    }

    function searchForUser() {
        return createAsyncThunk(
            `${name}/searchForUser`,
            async ({ searchTerm, channelId }) => await fetchWrapper.get(baseUrl + '/channels/searchForUser/' + searchTerm + '/' + channelId)
        );
    }

}

function createExtraReducers() {
    return {
        ...dashData(),
        ...searchForUser(),
        ...getChannelMessages(),
        ...getMessageThread(),
        ...sendReaction(),
        ...sendNewMessage(),
        ...sendNewThreadMessage(),
        ...appendReceivedMessage(),
        ...updateReaction(),
        ...uploadFile(),
        ...searchMembers(),
        ...searchMembersDM(),
        ...addMemberToChannel(),
        ...createNewChannel(),
        ...createNewDM(),
        ...searchForPublicChannels(),
        ...joinPublicChannel(),
        ...getAppDetails(),
        ...addnewSubmission(),
        ...approveSubmission(),
        ...rejectSubmission(),
        ...printSubmission(),
        ...completePermission(),
        ...getDigestMessages(),
        ...getUserEntitlements(),
        ...getRotaForOneWeek(),
        ...assignAllStaffForWeek(),
        ...unassignAllStaffForWeek(),
        ...closeSiteForWeek(),
        ...getSkillCountsForWeek(),
        ...getStaffShiftCountsForWeek(),
        ...getSkillsAndUserCountsForLocation(),
        ...getLocationUsersAndSkills(),
        ...toggleUserSkill(),
        ...getUserRotas(),
        ...updateUserAvailability(),
        ...updateChannelDetails(),
        ...deleteMessage(),
        ...updateUserDetails(),
        ...createNewEvent(),
        ...updateEvent(),
        ...getEventsByUserId(),
        ...clockInOut(),
        ...multiAssign(),
        ...submitWeekRequirements(),
        ...removeAssignment()
    };

    function removeAssignment(){
        var { pending, fulfilled, rejected } = extraActions.removeAssignment;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        }
    }

    function multiAssign() {
        var { pending, fulfilled, rejected } = extraActions.multiAssign;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        }
    }

    function clockInOut() {
        var { pending, fulfilled, rejected } = extraActions.clockInOut;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getEventsByUserId() {
        var { pending, fulfilled, rejected } = extraActions.getEventsByUserId;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.userEvents = action.payload.events;
                state.userSkills = action.payload.skills;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function updateEvent() {
        var { pending, fulfilled, rejected } = extraActions.updateEvent;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.userEvents = action.payload.events;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function createNewEvent() {
        var { pending, fulfilled, rejected } = extraActions.createNewEvent;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                state.userEvents = action.payload.events;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }


    function updateUserDetails() {
        var { pending, fulfilled, rejected } = extraActions.updateUserDetails;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function deleteMessage() {
        var { pending, fulfilled, rejected } = extraActions.deleteMessage;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const messageId = parseInt(action?.payload?.messageID);
                const messageIndex = state.channelMessages.findIndex(msg => msg.id === messageId);
                if (messageIndex !== -1) {
                    state.channelMessages.splice(messageIndex, 1);
                }
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        }
    }

    function dashData() {
        var { pending, fulfilled, rejected } = extraActions.dashData;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.appList = action.payload.apps;
                state.userChannel = action.payload.digest_channel;
                state.dmList = action.payload.direct_messages;
                state.channelList = action.payload.channels;
                state.publicOverview = action.payload.latest_public_channels;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function searchForUser() {
        var { pending, fulfilled, rejected } = extraActions.searchForUser;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

    function getChannelMessages() {
        var { pending, fulfilled, rejected } = extraActions.getChannelMessages;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {

                if (action.payload.isNewThread === "true") {
                    state.channelMessages = [];
                }
                let newMessages = action.payload.messages;
                let messageReverse = newMessages.reverse();
                if (action.payload.isNewThread !== "true" && state.channelMessages && state.channelMessages.length) {
                    messageReverse = action.payload.messages.concat(state.channelMessages);
                }

                state.channelMessages = removeDuplicateMessages(messageReverse);
                state.channelDetails = action.payload.channelDetails;
                state.pagination = action.payload.pagination;
                state.channelList = action.payload.channels;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getMessageThread() {
        var { pending, fulfilled, rejected } = extraActions.getMessageThread;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.messageThread = action.payload.messagethread;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function sendReaction() {
        var { pending, fulfilled, rejected } = extraActions.sendReaction;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const messageId = parseInt(action?.payload?.messageID);
                const reactionType = action?.payload?.type;
                const responseMessage = action?.payload?.message;
                const userId = action?.payload?.userID;
                const messageIndex = state.channelMessages.findIndex(msg => msg.id === messageId);

                if (messageIndex !== -1) {
                    if (responseMessage === 'Reaction added' || responseMessage === 'Reaction restored') {
                        const alreadyExists = state.channelMessages[messageIndex].reactions.some(reaction => reaction.type === reactionType && reaction.user_id === userId);
                        if (!alreadyExists) {
                            state.channelMessages[messageIndex].reactions.push({
                                user_id: userId,
                                type: reactionType
                            });
                        }
                    } else if (responseMessage === 'Reaction removed') {
                        state.channelMessages[messageIndex].reactions = state.channelMessages[messageIndex].reactions.filter(reaction => !(reaction.type === reactionType && reaction.user_id === userId));
                    }
                }

                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function sendNewMessage() {
        var { pending, fulfilled, rejected } = extraActions.sendNewMessage;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Message sent successfully") {
                    const newMessages = [...state.channelMessages, action.payload.respondedMessage];
                    state.channelMessages = removeDuplicateMessages(newMessages);


                    if (action.payload.usersAdded) {
                        state.channelDetails = action.payload?.channelDetails;
                    }
                }
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function uploadFile() {
        var { pending, fulfilled, rejected } = extraActions.uploadFile;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Message sent successfully") {
                    const newMessages = [...state.channelMessages, action.payload.respondedMessage];
                    state.channelMessages = removeDuplicateMessages(newMessages);
                }
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function sendNewThreadMessage() {
        var { pending, fulfilled, rejected } = extraActions.sendNewThreadMessage;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Message sent successfully") {
                    state.messageThread = action.payload.messageThread;
                }
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function appendReceivedMessage() {
        var { pending, fulfilled, rejected } = extraActions.appendReceivedMessage;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                state.channelMessages = [...state.channelMessages, action.payload];
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function updateReaction() {
        var { pending, fulfilled, rejected } = extraActions.updateReaction;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

                const messageId = parseInt(action.payload.messageId);
                const reactionType = action.payload.reactionType;
                const responseMessage = action.payload.responseMessage;
                const userId = action.payload.userId;
                const messageIndex = state.channelMessages.findIndex(msg => msg.id === messageId);

                if (messageIndex !== -1) {
                    if (responseMessage === 'Reaction added' || responseMessage === 'Reaction restored') {
                        const alreadyExists = state.channelMessages[messageIndex].reactions.some(reaction => reaction.type === reactionType && reaction.user_id === userId);
                        if (!alreadyExists) {
                            state.channelMessages[messageIndex].reactions.push({
                                user_id: userId,
                                type: reactionType
                            });
                        }
                    } else if (responseMessage === 'Reaction removed') {
                        state.channelMessages[messageIndex].reactions = state.channelMessages[messageIndex].reactions.filter(reaction => !(reaction.type === reactionType && reaction.user_id === userId));
                    }
                }
            },
            [rejected]: (state, action) => {

            }
        };
    }

    function searchMembers() {
        var { pending, fulfilled, rejected } = extraActions.searchMembers;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.people = action.payload.members;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function searchMembersDM() {
        var { pending, fulfilled, rejected } = extraActions.searchMembersDM;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.people = action.payload.members;
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function addMemberToChannel() {
        var { pending, fulfilled, rejected } = extraActions.addMemberToChannel;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function createNewChannel() {
        var { pending, fulfilled, rejected } = extraActions.createNewChannel;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Successfully created channel") {
                    state.channelList = [];
                    state.channelList = action.payload.channelList;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function createNewDM() {
        var { pending, fulfilled, rejected } = extraActions.createNewDM;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Direct message started successfully") {
                    state.dmList = [];
                    state.dmList = action.payload.direct_messages;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function searchForPublicChannels() {
        var { pending, fulfilled, rejected } = extraActions.searchForPublicChannels;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Public channels retrieved successfully") {
                    state.publicOverview = action.payload.public_channels;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function joinPublicChannel() {
        var { pending, fulfilled, rejected } = extraActions.joinPublicChannel;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Successfully joined channel") {
                    state.channelList = [];
                    state.channelList = action.payload.channelList;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getAppDetails() {
        var { pending, fulfilled, rejected } = extraActions.getAppDetails;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "App details retrieved successfully.") {
                    state.appDetails = action?.payload?.app ?? [];
                    state.rejectedSubmissions = action?.payload?.rejectedSubmissions ?? [];
                    state.completedSubmissions = action?.payload?.completedSubmissions ?? [];
                    state.openSubmissions = action?.payload?.openSubmissions ?? [];
                    state.approvalList = action?.payload?.openApprovals ?? [];
                    state.approvedSubmissions = action?.payload?.approvedSubmissions ?? [];
                    state.approvedSubmissions = action?.payload?.approvedSubmissions ?? [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function addnewSubmission() {
        var { pending, fulfilled, rejected } = extraActions.addnewSubmission;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Submission created successfully") {

                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function approveSubmission() {
        var { pending, fulfilled, rejected } = extraActions.approveSubmission;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Submission approved successfully") {

                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function rejectSubmission() {
        var { pending, fulfilled, rejected } = extraActions.rejectSubmission;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function printSubmission() {
        var { pending, fulfilled, rejected } = extraActions.printSubmission;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function completePermission() {
        var { pending, fulfilled, rejected } = extraActions.completePermission;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getDigestMessages() {
        var { pending, fulfilled, rejected } = extraActions.getDigestMessages;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "User digest messages retrieved successfully") {
                    state.digestMessages = action.payload.digest_messages;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getUserEntitlements() {
        var { pending, fulfilled, rejected } = extraActions.getUserEntitlements;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "User entitlements retrieved successfully") {
                    state.entitlementList = action.payload.entitlements;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getRotaForOneWeek() {
        var { pending, fulfilled, rejected } = extraActions.getRotaForOneWeek;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Rota retrieved successfully") {
                    state.currentRota = action.payload.rota;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function assignAllStaffForWeek(){
        var { pending, fulfilled, rejected } = extraActions.assignAllStaffForWeek;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function unassignAllStaffForWeek(){
        var { pending, fulfilled, rejected } = extraActions.unassignAllStaffForWeek;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function closeSiteForWeek(){
        var { pending, fulfilled, rejected } = extraActions.closeSiteForWeek;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getSkillCountsForWeek() {
        var { pending, fulfilled, rejected } = extraActions.getSkillCountsForWeek;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Skill counts retrieved successfully") {
                    state.locationSkills = action.payload.skill_counts;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getStaffShiftCountsForWeek() {
        var { pending, fulfilled, rejected } = extraActions.getStaffShiftCountsForWeek;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Staff shift counts retrieved successfully") {
                    state.shiftCount = action.payload.staff_shift_counts;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getSkillsAndUserCountsForLocation() {
        var { pending, fulfilled, rejected } = extraActions.getSkillsAndUserCountsForLocation;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Skills and user counts retrieved successfully") {
                    state.siteSkills = action.payload.skills_and_user_counts;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getLocationUsersAndSkills() {
        var { pending, fulfilled, rejected } = extraActions.getLocationUsersAndSkills;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === "Location users and skills retrieved successfully") {
                    state.thisSiteUsers = action.payload.location_users_and_skills?.users ?? [];
                    state.thisSiteSkills = action.payload.location_users_and_skills?.skills ?? [];
                    state.thisSiteSkillsOther = action.payload.location_users_and_skills?.locationSkills ?? [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function toggleUserSkill() {
        var { pending, fulfilled, rejected } = extraActions.toggleUserSkill;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const { userId, skillName, toggled } = action.payload;
                state.fetching = false;
                state.thisSiteUsers = state.thisSiteUsers.map(user => {
                    if (user.user_id === userId) {

                        if (user.skills[skillName]) {
                            user.skills[skillName].has_skill = toggled;
                        } else {
                            user.skills[skillName] = { has_skill: toggled };
                        }
                    }

                    return user;
                });
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function getUserRotas() {
        var { pending, fulfilled, rejected } = extraActions.getUserRotas;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === 'User rotas retrieved successfully') {
                    state.staffRota = action.payload.user_rotas;
                }

            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function updateUserAvailability() {
        var { pending, fulfilled, rejected } = extraActions.updateUserAvailability;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function updateChannelDetails() {
        var { pending, fulfilled, rejected } = extraActions.updateChannelDetails;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.fetching = false;
                if (action.payload.message === 'Details updated successfully') {
                    state.channelDetails = action.payload.channelDetails;
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        };
    }

    function submitWeekRequirements(){
        var { pending, fulfilled, rejected } = extraActions.submitWeekRequirements;
        return {
            [pending]: (state) => {
                state.fetching = true;
                state.error = null;
            },
            [fulfilled]:(state, action) => {
                state.fetching = false;
                state.error = null;
            },
            [rejected]:(state, action) => {
                state.fetching = false;
                state.error = action.error.message;
            }
        }
    }

}