import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'whistle';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const cacheDuration = 3600000;
export const whistleActions = { ...slice.actions, ...extraActions };
export const whistleReducer = slice.reducer;

const token = JSON.parse(localStorage.getItem('token'));
const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

function setCache(key, data) {
    const item = { data, timestamp: Date.now() };
    sessionStorage.setItem(key, JSON.stringify(item));
    clearExpiredCacheItems();
}

function getCache(key) {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);
    if (Date.now() - item.timestamp > cacheDuration) {
        sessionStorage.removeItem(key);
        return null;
    }
    return item.data;
}

function clearExpiredCacheItems() {
    const now = Date.now();
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        try {
            const itemStr = sessionStorage.getItem(key);
            const item = JSON.parse(itemStr);
            if (item && item.timestamp && (now - item.timestamp > cacheDuration)) {
                sessionStorage.removeItem(key);
                console.log(`Cache item with key '${key}' has been removed due to expiration.`);
                i--;
            }
        } catch (e) {
            console.error(`Error reading or parsing sessionStorage item with key '${key}':`, e);
        }
    }
}

function clearCache(key) {
    sessionStorage.removeItem(key);
}

function createInitialState() {
    return {
        isLoading: false,
        loading: false,
        error: false,
        isFuseError: false,
        isCached: false,
        isOverCached: false,
        whistleSearch: [],
        whistleQuery: [],
        whistleDataAll: [],
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_whistle_API_URL;

    return {
        ...basicActions(),
        ...postActions(),
    };

    function basicActions() {
        return {
            getAll: createAsyncThunk(
                `/${name}/getAll`,
                async ({anonymous, status, limit}, { dispatch }) => {
                    const cacheKey = `thcache_whistle_${'getAll_'}${anonymous}_${status}_${limit}`;
                    const cachedData = getCache(cacheKey);
                    if (cachedData) {
                        return { ...cachedData, isCached: true };
                    }
                    const response = await fetchWrapper.get(`${baseUrl}/whistle?anonymous=` + String(anonymous) + "&status=" + String(status) + "&limit=" + String(limit));
                    if (response.message === 'Success') {
                        setCache(cacheKey, response);
                    }
                    return { ...response, isCached: false };
                }
            ),
        };
    }

    function postActions() {
        return {
            postWhistle: createAsyncThunk(
                `/${name}/postWhistle`,
                async (form) => {
                    const response = await fetchWrapper.post(`${baseUrl}/whistle/postWhistle`, form );
                    return response;
                }
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
        ...postReducers(),
    };

    function basicReducers() {
        return {
            ...getAll(),
        };
    }

    function postReducers() {
        return {
            ...postWhistle(),
        };
    }

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.whistleDataAll = action.payload.data;
                } else {
                    state.whistleDataAll = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }


    function postWhistle() {
        var { pending, fulfilled, rejected } = extraActions.postWhistle;
        return {
            [pending]: (state) => {
                state.loading = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Success') {
                    state.whistleQuery = action.payload.response;
                } else {
                    state.whistleQuery = [];
                }
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
                state.error = action.error;
            }
        };
    }
}