import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { history, fetchWrapper } from '_helpers'

const name = 'auth'
const initialState = createInitialState()
const reducers = createReducers()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, reducers, extraReducers })

export const authActions = { ...slice.actions, ...extraActions }
export const authReducer = slice.reducer

function createInitialState() {
  return {
    user: JSON.parse(localStorage.getItem('user')),
    token: JSON.parse(localStorage.getItem('token')),
    error: null,
    forgot: null,
    reset: null,
    register: null,
    userDetails: [],
    allBadges: [],
  }
}

function createReducers() {
  return {
    logout,
  }

  function logout(state) {
    state.user = null
    state.token = null
    state.forgot = null
    state.register = null
    state.reset = null
    localStorage.removeItem('user')
    localStorage.removeItem('activeType')
    localStorage.removeItem('activeMessage')
    localStorage.removeItem('session')
    localStorage.removeItem('token')
    window.location.href = '/login'
  }
}

function createExtraActions() {
  const baseUrl = process.env.REACT_APP_auth_API_URL

  return {
    login: login(),
    loginCheck: loginCheck(),
    forgot: forgot(),
    reset: reset(),
    register: register(),
    updateAvatar: updateAvatar(),
    updateBanner: updateBanner(),
    updateDesc: updateDesc(),
    updateHeaderColor: updateHeaderColor(),
    updateWeatherData: updateWeatherData(),
    clearWeatherData: clearWeatherData(),
    logMeOut: logMeOut(),
    refreshUser: refreshUser(),
    getUser: getUser(),
  }

  function logMeOut() {
    return createAsyncThunk(
      `${name}/logMeOut`,
      async () => await fetchWrapper.get(`${baseUrl}/api/logout`)
    )
  }

  function refreshUser() {
    return createAsyncThunk(
      `${name}/refreshUser`,
      async () => await fetchWrapper.get(`${baseUrl}/api/users/refreshUser`)
    )
  }

  function getUser() {
    return createAsyncThunk(
      `${name}/getUser`,
      async () => await fetchWrapper.get(`${baseUrl}/api/users/getUser`)
    )
  }

  function updateAvatar() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/updateAvatar`,
      async (data) =>
        await axios
          .post(`${baseUrl}/api/users/updateUserAvatar`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function updateBanner() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/updateBanner`,
      async (data) =>
        await axios
          .post(`${baseUrl}/api/users/updateUserBanner`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function updateHeaderColor() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/updateHeaderColor`,
      async (color) =>
        await axios
          .post(`${baseUrl}/api/users/updateHeaderColor`, { color }, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function updateWeatherData() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/updateWeatherData`,
      async (postcode) =>
        await axios
          .post(`${baseUrl}/api/users/updateWeatherData`, { postcode }, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function clearWeatherData() {
    return createAsyncThunk(
      `${name}/clearWeatherData`,
      async () =>
        await fetchWrapper.get(`${baseUrl}/api/users/clearWeatherData`)
    )
  }

  function updateDesc() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/updateDesc`,
      async (data) =>
        await axios
          .post(`${baseUrl}/api/users/updateUserDesc`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async ({ email, password }) =>
        await fetchWrapper.post(`${baseUrl}/goape/portal/checkAuthenticate`, {
          email,
          password,
        })
    )
  }

  function forgot() {
    return createAsyncThunk(
      `${name}/forgot`,
      async ({ email }) =>
        await fetchWrapper.post(`${baseUrl}/goape/portal/haveYouForgot`, {
          email,
        })
    )
  }

  function reset() {
    return createAsyncThunk(
      `${name}/forgot`,
      async ({ token, email, password, password_confirmation }) =>
        await fetchWrapper.post(`${baseUrl}/goape/portal/canIReset`, {
          token,
          email,
          password,
          password_confirmation,
        })
    )
  }

  function register() {
    return createAsyncThunk(
      `${name}/register`,
      async ({
        firstName,
        lastName,
        email,
        title,
        password,
        c_password,
        location,
      }) =>
        await fetchWrapper.post(`${baseUrl}/goape/portal/userCreation`, {
          firstName,
          lastName,
          email,
          title,
          password,
          c_password,
          location,
        })
    )
  }

  function loginCheck() {
    return createAsyncThunk(
      `${name}/loginCheck`,
      async ({ code, sessionState }) =>
        await fetchWrapper.get(
          `${baseUrl}/login/microsoft/callback?code=${code}&session_state=${sessionState}`
        )
    )
  }
}

function createExtraReducers() {
  return {
    ...login(),
    ...loginCheck(),
    ...forgot(),
    ...reset(),
    ...register(),
    ...updateAvatar(),
    ...updateBanner(),
    ...updateDesc(),
    ...logMeOut(),
    ...refreshUser(),
    ...getUser(),
  }

  function logMeOut() {
    var { pending, fulfilled, rejected } = extraActions.logMeOut

    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        state.user = null
        state.token = null
        state.forgot = null
        state.register = null
        state.reset = null
        localStorage.removeItem('user')
        localStorage.removeItem('activeType')
        localStorage.removeItem('activeMessage')
        localStorage.removeItem('session')
        localStorage.removeItem('token')
        history.navigate('/login')
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function refreshUser() {
    var { pending, fulfilled, rejected } = extraActions.refreshUser
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully refreshed') {
          localStorage.removeItem('user')
          const user = action.payload
          state.user = user.user
          localStorage.setItem('user', JSON.stringify(user.user))
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function updateAvatar() {
    var { pending, fulfilled, rejected } = extraActions.updateAvatar
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Avatar updated') {
          localStorage.removeItem('user')
          const user = action.payload
          state.user = user.user
          localStorage.setItem('user', JSON.stringify(user.user))
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function updateBanner() {
    var { pending, fulfilled, rejected } = extraActions.updateBanner
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Banner updated') {
          localStorage.removeItem('user')
          const user = action.payload
          state.user = user.user
          localStorage.setItem('user', JSON.stringify(user.user))
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function updateDesc() {
    var { pending, fulfilled, rejected } = extraActions.updateDesc
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Description updated') {
          localStorage.removeItem('user')
          const user = action.payload
          state.user = user.user
          localStorage.setItem('user', JSON.stringify(user.user))
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }
  function login() {
    var { pending, fulfilled, rejected } = extraActions.login
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        const user = action.payload
        state.user = user.user
        localStorage.setItem('user', JSON.stringify(user.user))
        localStorage.setItem('token', JSON.stringify(user.token))
        let returnUrl = localStorage.getItem('returnUrl')
        localStorage.removeItem('returnUrl')

        if (returnUrl) {
          history.navigate(returnUrl)
        } else {
          history.navigate('/home')
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function loginCheck() {
    var { pending, fulfilled, rejected } = extraActions.loginCheck
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        const user = action.payload
        state.user = user.user

        localStorage.setItem('user', JSON.stringify(user.user))
        localStorage.setItem('token', JSON.stringify(user.token))
        let returnUrl = localStorage.getItem('returnUrl')
        localStorage.removeItem('returnUrl')

        if (returnUrl) {
          history.navigate(returnUrl)
        } else {
          history.navigate('/home')
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function register() {
    var { pending, fulfilled, rejected } = extraActions.register
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        const user = action.payload
        state.user = user.user
        localStorage.setItem('user', JSON.stringify(user.user))
        localStorage.setItem('token', JSON.stringify(user.token))

        const { from } = history.location.state || {
          from: { pathname: '/home' },
        }
        history.navigate(from)
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function forgot() {
    var { pending, fulfilled, rejected } = extraActions.forgot
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        const returned = action.payload.message
        state.forgot = returned
        state.reset = returned
        localStorage.removeItem('user')
        localStorage.removeItem('token')
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function reset() {
    var { pending, fulfilled, rejected } = extraActions.reset
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        const reset = action.payload.message
        state.reset = reset
        state.forgot = reset
        const { from } = { from: { pathname: '/login' } }
        history.navigate(from)
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }

  function getUser() {
    var { pending, fulfilled, rejected } = extraActions.getUser
    return {
      [pending]: (state) => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        state.userDetails = action.payload.user
        state.allBadges = action.payload.badges ?? []
      },
      [rejected]: (state, action) => {
        state.error = action.error
      },
    }
  }
}
