import React from 'react';

import Main from '../_components/Main';

function App() {
    return (
        <div className="bg-blue-gray-50">
            <Main />
        </div>
    );
}

export { App };