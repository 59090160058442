import {authActions, store} from '_store'

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    postPart: request('POST', 'part'),
    put: request('PUT'),
    delete: request('DELETE'),
}
const baseUrl = process.env.REACT_APP_API_BASE_URL

function request(method, part = null) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url),
        }
        requestOptions.headers['Accept'] = 'application/json'
        requestOptions.headers['X-Requested-With'] = 'XMLHttpRequest'
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json'
            requestOptions.body = JSON.stringify(body)
        }
        return fetch(url, requestOptions).then(handleResponse)
    }
}

function authHeader(url) {
    const token = authToken()
    const isLoggedIn = !!token
    const isApiUrl = url.startsWith(baseUrl)
    if (isLoggedIn && isApiUrl) {
        return {Authorization: `Bearer ${token}`}
    } else {
        return {}
    }
}

function authToken() {
    return JSON.parse(localStorage.getItem('token'))
}

function handleResponse(response, responseType = 'json') {
    if (response.headers.get('Content-Type') === 'text/csv; charset=UTF-8') {
        return response.blob()
    }

    return response.text().then((text) => {
        let data
        try {
            data = text && JSON.parse(text)
        } catch (e) {
            if (responseType !== 'json') {
                data = text
            } else {
                throw new Error('Invalid JSON')
            }
        }

        if (!response.ok) {
            if ([401].includes(response.status) && authToken()) {
                const logout = () => store.dispatch(authActions.logout())
                logout()
            }
            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
        }

        return data
    })
}
