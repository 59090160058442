import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { fetchWrapper } from '_helpers'
import { getApiUrl } from '_helpers/url'

const name = 'kudos'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, extraReducers })

export const kudosActions = { ...slice.actions, ...extraActions }
export const kudosReducer = slice.reducer

function createInitialState() {
  return {
    kudosList: {},
    infiniScroll: {},
    thisKudos: {},
    kudosComments: {},
    fetching: false,
    topPeeps: [],
    topContributors: [],
    topMentions: [],
  }
}

function createExtraActions() {
  const baseUrl = getApiUrl(`/api/kudosapp`)

  return {
    kudosList: kudosList(),
    getTopPeeps: getTopPeeps(),
    fetchInfiniScroll: fetchInfiniScroll(),
    kudosGet: kudosGet(),
    updateKudos: updateKudos(),
    kudosGetByHash: kudosGetByHash(),
    saveKudos: saveKudos(),
    addKudosComment: addKudosComment(),
    updateKudosReaction: updateKudosReaction(),
    deleteThisKudos: deleteThisKudos(),
  }

  function getTopPeeps() {
    return createAsyncThunk(
      `/${name}/getTopPeeps`,
      async () => await fetchWrapper.get(`${baseUrl}/getTopPeeps`)
    )
  }

  function deleteThisKudos() {
    return createAsyncThunk(
      `/${name}/deleteThisKudos`,
      async (kudos_id) =>
        await fetchWrapper.get(`${baseUrl}/deleteThisKudos/` + kudos_id)
    )
  }
  function addKudosComment() {
    return createAsyncThunk(
      `/${name}/addKudosComment`,
      async ({ comment, kudos_id }) =>
        await fetchWrapper.post(`${baseUrl}/savingKudosComment`, {
          comment,
          kudos_id,
        })
    )
  }

  function saveKudos() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/saveKudos`,
      async (data) =>
        await axios
          .post(`${baseUrl}/savingPrivateKudos`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }
  function updateKudos() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/updateKudos`,
      async (data) =>
        await axios
          .post(`${baseUrl}/updatingPrivateKudos`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function kudosList() {
    return createAsyncThunk(
      `/${name}/getKudos`,
      async () => await fetchWrapper.get(`${baseUrl}/getKudos`)
    )
  }

  function kudosGet() {
    return createAsyncThunk(
      `/${name}/kudosGet`,
      async (kudosID) =>
        await fetchWrapper.get(`${baseUrl}/fetchThisKudos/` + kudosID)
    )
  }

  function kudosGetByHash() {
    return createAsyncThunk(
      `/${name}/kudosGetByHash`,
      async (hashID) =>
        await fetchWrapper.get(`${baseUrl}/fetchTheseKudos/` + hashID)
    )
  }

  function updateKudosReaction() {
    return createAsyncThunk(
      `/${name}/updateKudosReaction`,
      async ({ kudos, type, responseType }) =>
        await fetchWrapper.get(
          `${baseUrl}/updateKudosReaction/` +
            kudos +
            '/' +
            type +
            '/' +
            responseType
        )
    )
  }

  function fetchInfiniScroll() {
    return createAsyncThunk(
      `/${name}/fetchInfiniScroll`,
      async ({ filterOf, pageOf, limitOf }) =>
        await fetchWrapper.get(
          `${baseUrl}/getInfini?query=` +
            String(filterOf) +
            '&page=' +
            String(pageOf) +
            '&limit=' +
            String(limitOf)
        )
    )
  }
}

function createExtraReducers() {
  return {
    ...kudosList(),
    ...getTopPeeps(),
    ...fetchInfiniScroll(),
    ...kudosGet(),
    ...updateKudos(),
    ...kudosGetByHash(),
    ...saveKudos(),
    ...addKudosComment(),
    ...updateKudosReaction(),
    ...deleteThisKudos(),
  }

  function getTopPeeps() {
    var { pending, fulfilled, rejected } = extraActions.getTopPeeps
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Top Peeps retrieved') {
          state.topPeeps = action.payload?.topPeeps
          state.topContributors = action.payload?.topContributors
          state.topMentions = action.payload?.topMentions
          state.fetching = false
        } else {
          state.topPeeps = null
          state.topContributors = null
          state.topMentions = null
          state.fetching = false
        }
      },
      [rejected]: (state, action) => {
        state.fetching = false
      },
    }
  }

  function fetchInfiniScroll() {
    var { pending, fulfilled, rejected } = extraActions.fetchInfiniScroll
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Kudos retrieved') {
          var bananas = action.payload.kudos?.kudosList?.data

          if (bananas) {
            if (action.payload.kudos.kudosList.current_page === 1) {
              state.infiniScroll = bananas
            } else {
              if (bananas.length > 0) {
                state.infiniScroll.push(...bananas)
              }
            }
          }

          state.fetching = false
        } else {
          state.fetching = false
        }
      },
      [rejected]: (state, action) => {
        state.fetching = false
      },
    }
  }

  function updateKudosReaction() {
    var { pending, fulfilled, rejected } = extraActions.updateKudosReaction
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Reaction added!') {
          switch (action.payload.responseType) {
            case 'single':
              return {
                ...state,
                thisKudos: action.payload.kudos,
                kudosComments: action.payload.kudos[0].comments,
              }

            case 'all':
              var index = state.infiniScroll.findIndex(
                (kudos) => kudos.kudos_id === action.payload.kudos[0].kudos_id
              )
              var newArray = [...state.infiniScroll]
              newArray[index].reactions_count =
                action.payload.kudos[0].reactions_count
              newArray[index].own_reaction_count =
                action.payload.kudos[0].own_reaction_count
              void (state.infiniScroll = newArray)
              break
            default:
              return state
          }
        } else {
          return state
        }
      },
      [rejected]: (state, action) => {
        state.infiniScroll = { error: action.error }
        state.fetching = false
      },
    }
  }
  function saveKudos() {
    var { pending, fulfilled, rejected } = extraActions.saveKudos
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Kudos added!') {
          return {
            ...state,
            infiniScroll: [action.payload.kudos[0], ...state.infiniScroll],
            fetching: false,
          }
        } else {
        }
        state.fetching = false
      },
      [rejected]: (state, action) => {
        state.fetching = false
      },
    }
  }
  function updateKudos() {
    var { pending, fulfilled, rejected } = extraActions.updateKudos
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Kudos updated!') {
          var updatedKudos = action.payload.kudos[0]
          var index = state.infiniScroll.findIndex(
            (kudos) => kudos.kudos_id === updatedKudos.kudos_id
          )
          if (index >= 0) {
            state.infiniScroll[index] = updatedKudos
          }
        }
        state.fetching = false
      },
      [rejected]: (state, action) => {
        state.fetching = false
      },
    }
  }

  function deleteThisKudos() {
    var { pending, fulfilled, rejected } = extraActions.deleteThisKudos
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Kudos removed!') {
          var newArray = state.infiniScroll.filter(
            (kudos) => kudos.kudos_id !== action.payload.kudos
          )
          void (state.infiniScroll = newArray)
        }
        state.fetching = false
      },
      [rejected]: (state, action) => {
        state.fetching = false
      },
    }
  }

  function addKudosComment() {
    var { pending, fulfilled, rejected } = extraActions.addKudosComment
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Comment added!') {
          return {
            ...state,
            thisKudos: action.payload.kudos,
            kudosComments: action.payload.kudos[0]?.comments,
          }
        } else {
          state.kudosList = null
        }
      },
      [rejected]: (state, action) => {
        state.kudosList = { error: action.error }
      },
    }
  }
  function kudosList() {
    var { pending, fulfilled, rejected } = extraActions.kudosList
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Kudos retrieved') {
          state.kudosList = action.payload?.kudos
          state.fetching = false
        } else {
          state.kudosList = null
          state.fetching = false
        }
      },
      [rejected]: (state, action) => {
        state.kudosList = { error: action.error }
      },
    }
  }

  function kudosGet() {
    var { pending, fulfilled, rejected } = extraActions.kudosGet
    return {
      [pending]: (state) => {
        state.thisKudos = { loading: true }
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Kudos retrieved') {
          return {
            ...state,
            thisKudos: action.payload.kudos,
            kudosComments: action.payload?.kudos[0]?.comments,
          }
        } else {
          state.thisKudos = null
        }
      },
      [rejected]: (state, action) => {
        state.thisKudos = { error: action.error }
      },
    }
  }

  function kudosGetByHash() {
    var { pending, fulfilled, rejected } = extraActions.kudosGetByHash
    return {
      [pending]: (state) => {
        state.fetching = true
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Kudos retrieved') {
          state.kudosList = action.payload?.kudos
          state.fetching = false
        } else {
          state.kudosList = null
          state.fetching = false
        }
      },
      [rejected]: (state, action) => {
        state.kudosList = { error: action.error }
      },
    }
  }
}
