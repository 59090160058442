export function markdownParser(text) {
    const boldItalicRegex = /(\*\*\*|___)(.*?)\1/g;
    text = text.replace(boldItalicRegex, '<strong><em>$2</em></strong>');

    const boldRegex = /(\*\*|__)(.*?)\1/g;
    text = text.replace(boldRegex, '<strong>$2</strong>');

    const italicRegex = /(\*|_)(.*?)\1/g;
    text = text.replace(italicRegex, '<em>$2</em>');

    const strikethroughRegex = /~~(.*?)~~/g;
    text = text.replace(strikethroughRegex, '<del>$1</del>');

    const inlineCodeRegex = /`(.*?)`/g;
    text = text.replace(inlineCodeRegex, '<code>$1</code>');

    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    text = text.replace(linkRegex, '<a href="$2">$1</a>');

    const urlRegex = /(\b(https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?)/g;
    text=  text.replace(urlRegex, (url) => {
        // Check if URL starts with http:// or https://, if not, prepend https://
        const completeUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
        return `<a href="${completeUrl}" target="_blank">${url}</a>`;
    });

    const headingRegex = /^(#+)\s*(.*)/gm;
    text = text.replace(headingRegex, (match, hashes, content) => {
        let level = hashes.length;
        return `<h${level}>${content}</h${level}>`;
    });

    return text;
}