import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchWrapper } from '_helpers';

const name = 'appraisals';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const appraisalsActions = { ...slice.actions, ...extraActions };
export const appraisalsReducer = slice.reducer;

function createInitialState() {
    return {
        appraisals: [],
        types: [],
        selectedAppraisal: [],
        status: 'idle',
        error: null,
        openAppraisals: 0,
        openSelfReflections: 0,
        selfReflections: [],
        feedbackRequests: [],
        adminCycles: [],
        adminYearCount: [],
        appraisalHistory: [],
        appraisalsAll: [],
        appraisal_statuses: [],
        appraisal_ranks: [],
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_APPRAISAL_API_URL;

    return {
        fetchAppraisal: fetchAppraisal(),
        getAppraisalStatus: getAppraisalStatus(),
        fetchAppraisals: fetchAppraisals(),
        createAppraisals: createAppraisals(),
        sendFeedbackRequests: sendFeedbackRequests(),
        fetchCurrentReflections: fetchCurrentReflections(),
        fetchCurrentFeedbackRequests: fetchCurrentFeedbackRequests(),
        saveFeedbackSubmission: saveFeedbackSubmission(),
        setFeedbackRejected: setFeedbackRejected(),
        saveReflectionSubmission: saveReflectionSubmission(),
        adminCycles: adminCycles(),
        editThisAppraisal: editThisAppraisal(),
        appraisalHistory: appraisalHistory(),
        updateObjectives: updateObjectives(),
        deleteObjective: deleteObjective(),
        markComplete: markComplete(),
        createOneOffAppraisal: createOneOffAppraisal(),
        markInReview: markInReview(),
        sendFeedbackPrompt: sendFeedbackPrompt(),
        retractFeedbackRequest: retractFeedbackRequest(),
    };

    function retractFeedbackRequest() {
        return createAsyncThunk(
            `/${name}/retractFeedbackRequest`,
            async (feedbackID) => await fetchWrapper.get(`${baseUrl}/retractFeedbackRequest/${feedbackID}`)
        );
    }

    function sendFeedbackPrompt() {
        return createAsyncThunk(
            `/${name}/sendFeedbackPrompt`,
            async (feedbackID) => await fetchWrapper.get(`${baseUrl}/sendFeedbackPrompt/${feedbackID}`)
        );
    }

    function markInReview() {
        return createAsyncThunk(
            `/${name}/markInReview`,
            async (data) => await fetchWrapper.post(`${baseUrl}/markInReview`, data)
        );
    }

    function createOneOffAppraisal() {
        return createAsyncThunk(
            `/${name}/createOneOffAppraisal`,
            async (data) => await fetchWrapper.post(`${baseUrl}/createOneOffAppraisal`, data)
        );
    }

    function markComplete() {
        return createAsyncThunk(
            `/${name}/markComplete`,
            async (data) => await fetchWrapper.post(`${baseUrl}/markComplete`, data)
        );
    }

    function updateObjectives() {
        return createAsyncThunk(
            `/${name}/updateObjectives`,
            async (data) => await fetchWrapper.post(`${baseUrl}/updateObjectives`, data)
        );
    }

    function deleteObjective() {
        return createAsyncThunk(
            `/${name}/deleteObjective`,
            async (data) => await fetchWrapper.post(`${baseUrl}/deleteObjective`, data)
        );
    }

    function editThisAppraisal() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/editThisAppraisal`,
            async (data) => await
                axios.post(`${baseUrl}/editThisAppraisal`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function saveFeedbackSubmission() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/saveFeedbackSubmission`,
            async (data) => await
                axios.post(`${baseUrl}/saveFeedbackSubmission`, data, config).then(function (response) {
                    return response.data
                })
        );
    }
    function saveReflectionSubmission() {
        var token = JSON.parse(localStorage.getItem('token'));
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } };

        return createAsyncThunk(
            `/${name}/saveReflectionSubmission`,
            async (data) => await
                axios.post(`${baseUrl}/saveReflectionSubmission`, data, config).then(function (response) {
                    return response.data
                })
        );
    }

    function setFeedbackRejected() {
        return createAsyncThunk(
            `/${name}/setFeedbackRejected`,
            async ({ feedbackID, feedbackProvider, type, rejected_reason }) => await fetchWrapper.post(`${baseUrl}/setFeedbackRejected`, { feedbackID, feedbackProvider, type, rejected_reason })
        );
    }

    function sendFeedbackRequests() {
        return createAsyncThunk(
            `/${name}/sendFeedbackRequests`,
            async ({ incomingUsers, appraisalID, targetDate }) => await fetchWrapper.post(`${baseUrl}/sendFeedbackRequests`, { incomingUsers, appraisalID, targetDate })
        );
    }

    function appraisalHistory() {
        return createAsyncThunk(
            `/${name}/appraisalHistory`,
            async (id) => await fetchWrapper.get(`${baseUrl}/appraisalHistory/${id}`)
        );
    }

    function fetchCurrentReflections() {
        return createAsyncThunk(
            `/${name}/fetchCurrentReflections`,
            async () => await fetchWrapper.get(`${baseUrl}/fetchCurrentReflections`)
        );
    }

    function fetchCurrentFeedbackRequests() {
        return createAsyncThunk(
            `/${name}/fetchCurrentFeedbackRequests`,
            async () => await fetchWrapper.get(`${baseUrl}/fetchCurrentFeedbackRequests`)
        );
    }

    function adminCycles() {
        return createAsyncThunk(
            `/${name}/adminCycles`,
            async () => await fetchWrapper.get(`${baseUrl}/adminCycles`)
        );
    }

    function createAppraisals() {
        return createAsyncThunk(
            `/${name}/createAppraisals`,
            async (type) => await fetchWrapper.get(`${baseUrl}/createAppraisals/` + type)
        );
    }

    function fetchAppraisal() {
        return createAsyncThunk(
            `/${name}/fetchAppraisal`,
            async (id) => await fetchWrapper.get(`${baseUrl}/fetchAppraisal/` + id)
        );
    }

    function getAppraisalStatus() {
        return createAsyncThunk(
            `/${name}/getAppraisalStatus`,
            async (year) => await fetchWrapper.get(`${baseUrl}/getAppraisalStatus/` + year)
        );
    }

    function fetchAppraisals() {
        return createAsyncThunk(
            `/${name}/fetchAppraisals`,
            async ({page, limit}) => await fetchWrapper.get(`${baseUrl}/fetchAppraisals?page=${page}&limit=${limit}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...fetchAppraisal(),
        ...getAppraisalStatus(),
        ...fetchAppraisals(),
        ...createAppraisals(),
        ...sendFeedbackRequests(),
        ...fetchCurrentReflections(),
        ...fetchCurrentFeedbackRequests(),
        ...saveFeedbackSubmission(),
        ...editThisAppraisal(),
        ...setFeedbackRejected(),
        ...saveReflectionSubmission(),
        ...adminCycles(),
        ...appraisalHistory(),
        ...updateObjectives(),
        ...deleteObjective(),
        ...markComplete(),
        ...createOneOffAppraisal(),
        ...markInReview(),
        ...sendFeedbackPrompt(),
        ...retractFeedbackRequest(),
    };

    function retractFeedbackRequest() {
        var { pending, fulfilled, rejected } = extraActions.retractFeedbackRequest;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        }
    }

    function sendFeedbackPrompt() {
        var { pending, fulfilled, rejected } = extraActions.sendFeedbackPrompt;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        }
    }

    function markInReview() {
        var { pending, fulfilled, rejected } = extraActions.markInReview;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        }
    }

    function createOneOffAppraisal() {
        var { pending, fulfilled, rejected } = extraActions.createOneOffAppraisal;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        }
    }

    function markComplete() {
        var { pending, fulfilled, rejected } = extraActions.markComplete;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        }
    }

    function deleteObjective() {
        var { pending, fulfilled, rejected } = extraActions.deleteObjective;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        }
    }

    function updateObjectives() {
        var { pending, fulfilled, rejected } = extraActions.updateObjectives;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        }
    }

    function adminCycles() {
        var { pending, fulfilled, rejected } = extraActions.adminCycles;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.adminCycles = action.payload?.data;
                state.adminYearCount = action.payload?.data;
                state.appraisal_statuses = action.payload?.appraisal_statuses;
                state.appraisal_ranks = action.payload?.appraisal_ranks;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function saveReflectionSubmission() {
        var { pending, fulfilled, rejected } = extraActions.saveReflectionSubmission;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function setFeedbackRejected() {
        var { pending, fulfilled, rejected } = extraActions.setFeedbackRejected;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function editThisAppraisal() {
        var { pending, fulfilled, rejected } = extraActions.editThisAppraisal;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function saveFeedbackSubmission() {
        var { pending, fulfilled, rejected } = extraActions.saveFeedbackSubmission;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';

            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function appraisalHistory() {
        var { pending, fulfilled, rejected } = extraActions.appraisalHistory;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {
                state.appraisalHistory = action.payload?.data;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
            }
        };
    }

    function fetchCurrentReflections() {
        var { pending, fulfilled, rejected } = extraActions.fetchCurrentReflections;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.selfReflections = action.payload?.data;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function fetchCurrentFeedbackRequests() {
        var { pending, fulfilled, rejected } = extraActions.fetchCurrentFeedbackRequests;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.feedbackRequests = action.payload?.data;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function fetchAppraisal() {
        var { pending, fulfilled, rejected } = extraActions.fetchAppraisal;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.selectedAppraisal = action.payload?.data;
            },
            [rejected]: (state, action) => {
                console.log(action)
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function getAppraisalStatus() {
        var { pending, fulfilled, rejected } = extraActions.getAppraisalStatus;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                if (action.payload?.message === 'Successfully retrieved appraisals') {
                    state.status = 'succeeded';
                    // state.appraisalsAll = action.payload?.appraisals;
                } else {
                    state.status = 'succeeded';
                    state.appraisalsAll = [];
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function createAppraisals() {
        var { pending, fulfilled, rejected } = extraActions.createAppraisals;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';

            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }

    function sendFeedbackRequests() {
        var { pending, fulfilled, rejected } = extraActions.sendFeedbackRequests;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }
    function fetchAppraisals() {
        var { pending, fulfilled, rejected } = extraActions.fetchAppraisals;
        return {
            [pending]: (state) => {
                state.status = 'loading';
            },
            [fulfilled]: (state, action) => {
                state.status = 'succeeded';
                state.appraisals = action.payload?.data;
                state.types = action.payload?.types;

                state.openAppraisals = action.payload?.openAppraisals;
                state.feedbackRequests = action.payload?.feedbackRequests;
                state.openSelfReflections = action.payload?.openSelfReflections;
            },
            [rejected]: (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            }
        };
    }


}