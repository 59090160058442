import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'sroData';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers: {
        resetSearchResults: (state) => initialState
    },
    extraReducers
});

export const sroDataActions = { ...slice.actions, ...extraActions };
export const sroDataReducer = slice.reducer;

function createInitialState() {
    return {
        sroSearchedQuery: {},
        customerSearchResult: [],
        customerCombined: [],
        giftVoucherSearchResult: [],
        fetching: false,
        
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_sroData_API_URL;

    return {
        getSearchResults: getSearchResults(),
        getFullSearchResults: getFullSearchResults(),
        getCustomerSearchResults: getCustomerSearchResults(),
        getCombinedSearch: getCombinedSearch(),
        getGiftVoucherSearchResults: getGiftVoucherSearchResults(),
        resetSearchResults: () => ({ type: `${name}/resetSearchResults` })
    };

    

    function getSearchResults() {
        return createAsyncThunk(
            `/${name}/getSearchResults`,
            async ({ transactionNumber }) => await fetchWrapper.get(`${baseUrl}/searchTransaction/${transactionNumber}`)
        );
    }
    function getFullSearchResults() {
        return createAsyncThunk(
            `/${name}/getFullSearchResults`,
            async ({ transactionNumber }) => await fetchWrapper.get(`${baseUrl}/getFullTransactionDetails/${transactionNumber}`)
        );
    }
    function getCustomerSearchResults() {
        return createAsyncThunk(
            `/${name}/getCustomerSearchResults`,
            async ({ firstName, lastName, crmId }) => await fetchWrapper.get(`${baseUrl}/searchCustomer/getCustomerSearchResults?firstName=` + String(firstName) + "&lastName=" + String(lastName) + "&crmId=" + String(crmId))
        );
    }
    function getCombinedSearch() {
        return createAsyncThunk(
            `/${name}/getCombinedSearch`,
            async ({ searchTerm }) => await fetchWrapper.get(`${baseUrl}/searchCustomer/combinedSearch?searchTerm=` + String(searchTerm))
        );
    }
    function getGiftVoucherSearchResults() {
        return createAsyncThunk(
            `/${name}/getGiftVoucherSearchResults`,
            async ({ giftVoucherNumber }) => await fetchWrapper.get(`${baseUrl}/searchGiftVoucher/getGiftVoucherSearchResults?giftVoucherNumber=${giftVoucherNumber}`)
        );
    }

}

function createExtraReducers() {
    return {
        ...getSearchResults(),
        ...getFullSearchResults(),
        ...getCustomerSearchResults(),
        ...getCombinedSearch(),
        ...getGiftVoucherSearchResults(),
        [`${name}/RESET_SEARCH_RESULTS`]: (state) => {
            Object.assign(state, initialState);
        }
    };

    function getSearchResults() {
        var { pending, fulfilled, rejected } = extraActions.getSearchResults;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved query results') {
                    state.sroSearchedQuery = action.payload.mainQuery;
                } else {
                    state.sroSearchedQuery = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function getFullSearchResults() {
        var { pending, fulfilled, rejected } = extraActions.getFullSearchResults;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved query results') {
                    state.sroSearchedQuery = action.payload.mainQuery;
                } else {
                    state.sroSearchedQuery = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function getCustomerSearchResults() {
        var { pending, fulfilled, rejected } = extraActions.getCustomerSearchResults;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved query results') {
                    state.customerSearchResult = action.payload;
                } else {
                    state.customerSearchResult = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function getCombinedSearch() {
        var { pending, fulfilled, rejected } = extraActions.getCombinedSearch;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved query results') {
                    state.customerCombined = action.payload;
                } else {
                    state.customerCombined = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

    function getGiftVoucherSearchResults() {
        var { pending, fulfilled, rejected } = extraActions.getGiftVoucherSearchResults;
        return {
            [pending]: (state) => {
                state.fetching = true;
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved query results') {
                    state.giftVoucherSearchResult = action.payload;
                } else {
                    state.giftVoucherSearchResult = [];
                }
            },
            [rejected]: (state, action) => {
                state.fetching = false;
            }
        };
    }

}