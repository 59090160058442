import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'tasks';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const tasksActions = { ...slice.actions, ...extraActions };
export const tasksReducer = slice.reducer;

function createInitialState() {
    return {
        allTasks: {},
        dashboardData: [],
        tasksByProject: {},
        thisTask: {},
        thisProject: {},
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_TASKS_API_URL;

    return {
        ...basicActions(),
        ...taskActions(),
        ...projectActions()
    };

    function basicActions() {
        return {
            getAllTasks: createAsyncThunk(
                `/${name}/getAllTasks`,
                async () => await fetchWrapper.get(`${baseUrl}/tasks/getAllTasks`)
            ),
            getTasksByProject: createAsyncThunk(
                `/${name}/getTasksByProject`,
                async ({ projectId }) => await fetchWrapper.get(`${baseUrl}/tasks/getTasksByProject/${projectId}`)
            ),
        };
    }

    function taskActions() {
        return {
            createTask: createAsyncThunk(
                `/${name}/createTask`,
                async (cleanedData) => await fetchWrapper.post(`${baseUrl}/tasks/createTask`, cleanedData)
            ),

            updateTask: createAsyncThunk(
                `/${name}/updateTask`,
                async (taskData) => await fetchWrapper.put(`${baseUrl}/tasks/updateTask`, taskData)
            ),

            deleteTask: createAsyncThunk(
                `/${name}/deleteTask`,
                async ({ taskId }) => await fetchWrapper.delete(`${baseUrl}/tasks/deleteTask/${taskId}`)
            ),

            unDeleteTask: createAsyncThunk(
                `/${name}/unDeleteTask`,
                async ({ taskId }) => await fetchWrapper.put(`${baseUrl}/tasks/unDeleteTask/${taskId}`)
            ),

            assignTask: createAsyncThunk(
                `/${name}/assignTask`,
                async ({ taskId, userId }) => await fetchWrapper.put(`${baseUrl}/tasks/assignTask/${taskId}/${userId}`)
            ),

            completeTask: createAsyncThunk(
                `/${name}/completeTask`,
                async ({ taskId }) => await fetchWrapper.put(`${baseUrl}/tasks/completeTask/${taskId}`)
            ),

            assignTaskToMe: createAsyncThunk(
                `/${name}/assignTaskToMe`,
                async ({ taskId }) => await fetchWrapper.put(`${baseUrl}/tasks/assignTaskToMe/${taskId}`)
            ),

            assignTaskToUser: createAsyncThunk(
                `/${name}/assignTaskToUser`,
                async ({ taskId, userId }) => await fetchWrapper.put(`${baseUrl}/tasks/assignTaskToUser/${taskId}/${userId}`)
            ),
            
            assignTaskToSite: createAsyncThunk(
                `/${name}/assignTaskToSite`,
                async ({ taskId, siteId }) => await fetchWrapper.put(`${baseUrl}/tasks/assignTaskToSite/${taskId}/${siteId}`)
            ),

            getAllTasksFiltered: createAsyncThunk(
                `/${name}/getAllTasksFiltered`,
                async (filter) => await fetchWrapper.get(`${baseUrl}/tasks/getAllTasksFiltered/${filter}`)
            ),
        };
    }


    function projectActions() {
        return {
            createProject: createAsyncThunk(
                `/${name}/createProject`,
                async (projectData) => await fetchWrapper.post(`${baseUrl}/tasks/createProject`, projectData)
            ),

            updateProject: createAsyncThunk(
                `/${name}/updateProject`,
                async (projectData) => await fetchWrapper.put(`${baseUrl}/tasks/updateProject`, projectData)
            ),

            deleteProject: createAsyncThunk(
                `/${name}/deleteProject`,
                async ({ projectId }) => await fetchWrapper.delete(`${baseUrl}/tasks/deleteProject/${projectId}`)
            ),

            assignProject: createAsyncThunk(
                `/${name}/assignProject`,
                async ({ projectId, userId }) => await fetchWrapper.put(`${baseUrl}/tasks/assignProject/${projectId}/${userId}`)
            ),

            completeProject: createAsyncThunk(
                `/${name}/completeProject`,
                async ({ projectId }) => await fetchWrapper.put(`${baseUrl}/tasks/completeProject/${projectId}`)
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...basicReducers(),
        ...taskReducers(),
        ...projectReducers()
    };

    function basicReducers() {
        return {
            ...getAllTasksReducers(),
            ...getTasksByProjectReducers(),
        };
    }

    function taskReducers() {
        return {
            ...createTask(),
            ...updateTask(),
            ...deleteTask(),
            ...unDeleteTask(),
            ...assignTask(),
            ...completeTask(),
            ...assignTaskToMe(),
            ...assignTaskToUser(),
            ...assignTaskToSite(),
            ...getAllTasksFiltered(),
        };
    }

    function projectReducers() {
        return {
            ...createProject(),
            ...updateProject(),
            ...deleteProject(),
            ...assignProject(),
            ...completeProject(),
        };
    }

    function getAllTasksReducers() {
        var { pending, fulfilled, rejected } = extraActions.getAllTasks;
        return {
            [pending]: (state) => {
                state.allTasks = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Tasks retrieved successfully') {
                    state.allTasks = action.payload.tasks;
                    state.dashboardData = action.payload.dashboardData;
                } else {
                    state.allTasks = [];
                    state.dashboardData = [];
                }
            },
            [rejected]: (state, action) => {
                state.allTasks = { error: action.error };
            }
        };
    }

    function getTasksByProjectReducers() {
        var { pending, fulfilled, rejected } = extraActions.getTasksByProject;
        return {
            [pending]: (state) => {
                state.tasksByProject = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.tasksByProject = { error: action.error };
            }
        };
    }

    function createTask() {
        var { pending, fulfilled, rejected } = extraActions.createTask;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function updateTask() {
        var { pending, fulfilled, rejected } = extraActions.updateTask;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function deleteTask() {
        var { pending, fulfilled, rejected } = extraActions.deleteTask;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function unDeleteTask() {
        var { pending, fulfilled, rejected } = extraActions.unDeleteTask;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
               
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function assignTask() {
        var { pending, fulfilled, rejected } = extraActions.assignTask;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function completeTask() {
        var { pending, fulfilled, rejected } = extraActions.completeTask;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
               
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function assignTaskToMe() {
        var { pending, fulfilled, rejected } = extraActions.assignTaskToMe;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function assignTaskToUser() {
        var { pending, fulfilled, rejected } = extraActions.assignTaskToUser;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function assignTaskToSite() {
        var { pending, fulfilled, rejected } = extraActions.assignTaskToSite;
        return {
            [pending]: (state) => {
                state.thisTask = { loading: true };
            },
            [fulfilled]: (state, action) => {
               
            },
            [rejected]: (state, action) => {
                state.thisTask = { error: action.error };
            }
        };
    }

    function getAllTasksFiltered() {
        var { pending, fulfilled, rejected } = extraActions.getAllTasksFiltered;
        return {
            [pending]: (state) => {
                state.allTasks = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Tasks retrieved successfully') {
                    state.allTasks = action.payload.tasks;
                }
            },
            [rejected]: (state, action) => {
                state.allTasks = { error: action.error };
            }
        };
    }
    
    function createProject() {
        var { pending, fulfilled, rejected } = extraActions.createProject;
        return {
            [pending]: (state) => {
                state.thisProject = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisProject = { error: action.error };
            }
        };
    }

    function updateProject() {
        var { pending, fulfilled, rejected } = extraActions.updateProject;
        return {
            [pending]: (state) => {
                state.thisProject = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisProject = { error: action.error };
            }
        };
    }


    function deleteProject() {
        var { pending, fulfilled, rejected } = extraActions.deleteProject;
        return {
            [pending]: (state) => {
                state.thisProject = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisProject = { error: action.error };
            }
        };
    }

    function assignProject() {
        var { pending, fulfilled, rejected } = extraActions.assignProject;
        return {
            [pending]: (state) => {
                state.thisProject = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisProject = { error: action.error };
            }
        };
    }

    function completeProject() {
        var { pending, fulfilled, rejected } = extraActions.completeProject;
        return {
            [pending]: (state) => {
                state.thisProject = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
            },
            [rejected]: (state, action) => {
                state.thisProject = { error: action.error };
            }
        };
    }

}
