import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'social';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const socialActions = { ...slice.actions, ...extraActions };
export const socialReducer = slice.reducer;

function createInitialState() {
    return {
        socialItems: {},
        socialItem: {}
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_social_API_URL;

    return {
        getSocialItems: getSocialItems(),
        getSocialItem: getSocialItem(),
        saveSocialLink: saveSocialLink(),
        editSocialLink: editSocialLink(),
        destroySocialLink: destroySocialLink(),
    };

    function getSocialItems() {
        return createAsyncThunk(
            `/${name}/getSocialItems`,
            async () => await fetchWrapper.get(`${baseUrl}`)
        );
    }
    function getSocialItem() {
        return createAsyncThunk(
            `/${name}/getSocialItem`,
            async ({ id }) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }

    function saveSocialLink() {
        return createAsyncThunk(
            `/${name}/saveSocialLink`,
            async ({ link, link_type }) => await fetchWrapper.post(`${baseUrl}/saveSocialLink`, { link, link_type })
        );
    }
    function editSocialLink() {
        return createAsyncThunk(
            `/${name}/editSocialLink`,
            async ({ link, link_type, social_link_id }) => await fetchWrapper.post(`${baseUrl}/editSocialLink`, { link, link_type, social_link_id })
        );
    }
    function destroySocialLink() {
        return createAsyncThunk(
            `/${name}/destroyAlert`,
            async ({ links }) => await fetchWrapper.post(`${baseUrl}/destroySocialLink`, { links })
        );
    }
}

function createExtraReducers() {
    return {
        ...getSocialItems(),
        ...getSocialItem(),
        ...saveSocialLink(),
        ...editSocialLink(),
        ...destroySocialLink(),
    };

    
    function getSocialItems() {
        var { pending, fulfilled, rejected } = extraActions.getSocialItems;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved sociallinks') {
                    state.socialItems = action.payload.sociallinks;
                }else{
                    state.socialItems = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function getSocialItem() {
        var { pending, fulfilled, rejected } = extraActions.getSocialItem;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully retrieved sociallink') {
                    state.socialItems = action.payload.sociallink;
                }else{
                    state.socialItems = [];
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function saveSocialLink() {
        var { pending, fulfilled, rejected } = extraActions.saveSocialLink;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Social Link created') {
                    state.socialItems = action.payload.sociallinks;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function editSocialLink() {
        var { pending, fulfilled, rejected } = extraActions.editSocialLink;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Successfully edited link!') {
                    state.socialItems = action.payload.sociallinks;
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }
    function destroySocialLink() {
        var { pending, fulfilled, rejected } = extraActions.destroySocialLink;
        return {
            [pending]: (state) => {
                
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === "Successfully deleted link!") {
                    var array = [...state.socialItems]
                    var anotherOne = action.payload.sociallinks
                    const newArr = array.filter(i => !anotherOne.includes(i.id))
                    void (state.socialItems = newArr)
                }
            },
            [rejected]: (state, action) => {
                
            }
        };
    }

}