import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'opsLinks';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const opsLinksActions = { ...slice.actions, ...extraActions };
export const opsLinksReducer = slice.reducer;

function createInitialState() {
    return {
        allLinks: [],
        allTypes: [],
        thisLink: {}
    };
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_OPSLINKS_API_URL;

    return {
        ...linkActions(),
    };

    function linkActions() {
        return {
            getAllLinks: createAsyncThunk(
                `/${name}/getAllLinks`,
                async (id) => await fetchWrapper.get(`${baseUrl}${ id === 0 ? '' : '?type_id=' + id }`)
            ),
            getLinkById: createAsyncThunk(
                `/${name}/getLinkById`,
                async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
            ),
            saveNewLink: createAsyncThunk(
                `/${name}/saveNewLink`,
                async (linkData) => await fetchWrapper.post(`${baseUrl}/saveNewLink`, linkData)
            ),
            saveNewType: createAsyncThunk(
                `/${name}/saveNewType`,
                async (typeData) => await fetchWrapper.post(`${baseUrl}/saveNewType`, typeData)
            ),
            editType: createAsyncThunk(
                `/${name}/editThisType`,
                async (typeData) => await fetchWrapper.post(`${baseUrl}/editThisType`, typeData)
            ),
            editLink: createAsyncThunk(
                `/${name}/editThisLink`,
                async (linkData) => await fetchWrapper.post(`${baseUrl}/editThisLink`, linkData)
            ),
            deleteLink: createAsyncThunk(
                `/${name}/destroyThisLink`,
                async (id) => await fetchWrapper.get(`${baseUrl}/destroyThisLink/${id}`)
            ),
        };
    }
}

function createExtraReducers() {
    return {
        ...linkReducers(),
    };

    function linkReducers() {
        return {
            ...getAllLinks(),
            ...getLinkById(),
            ...saveNewLink(),
            ...saveNewType(),
            ...editType(),
            ...editLink(),
            ...deleteLink(),
        };
    }

    function getAllLinks() {
        var { pending, fulfilled, rejected } = extraActions.getAllLinks;
        return {
            [pending]: (state) => {
                state.allLinks = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Links retrieved successfully') {
                    state.allLinks = action.payload;
                    state.allTypes = action.payload.types;
                } else {
                    state.allLinks = [];
                }
            },
            [rejected]: (state, action) => {
                state.allLinks = { error: action.error };
            }
        };
    }

    function getLinkById(){
        var { pending, fulfilled, rejected } = extraActions.getLinkById;
        return {
            [pending]: (state) => {
                state.thisLink = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Link retrieved successfully') {
                    state.thisLink = action.payload;
                } else {
                    state.thisLink = [];
                }
            },
            [rejected]: (state, action) => {
                state.thisLink = { error: action.error };
            }
        };
    }

    function saveNewLink() {
        var { pending, fulfilled, rejected } = extraActions.saveNewLink;
        return {
            [pending]: (state) => {
                state.thisLink = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Link created successfully') {
                    state.thisLink = action.payload;
                } else {
                    state.thisLink = [];
                }
            },
            [rejected]: (state, action) => {
                state.thisLink = { error: action.error };
            }
        };
    }

    function editLink() {
        var { pending, fulfilled, rejected } = extraActions.editLink;
        return {
            [pending]: (state) => {
                state.thisLink = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Link updated successfully') {
                    state.thisLink = action.payload;
                } else {
                    state.thisLink = [];
                }
            },
            [rejected]: (state, action) => {
                state.thisLink = { error: action.error };
            }
        };
    }

    function deleteLink() {
        var { pending, fulfilled, rejected } = extraActions.deleteLink;
        return {
            [pending]: (state) => {
                state.thisLink = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Link deleted successfully') {
                    state.thisLink = action.payload;
                } else {
                    state.thisLink = [];
                }
            },
            [rejected]: (state, action) => {
                state.thisLink = { error: action.error };
            }
        };
    }


    function saveNewType() {
        var { pending, fulfilled, rejected } = extraActions.saveNewType;
        return {
            [pending]: (state) => {
                state.thisLink = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Type created successfully') {
                    state.thisLink = action.payload;
                } else {
                    state.thisLink = [];
                }
            },
            [rejected]: (state, action) => {
                state.thisLink = { error: action.error };
            }
        };
    }

    function editType() {
        var { pending, fulfilled, rejected } = extraActions.editType;
        return {
            [pending]: (state) => {
                state.thisLink = { loading: true };
            },
            [fulfilled]: (state, action) => {
                if (action.payload.message === 'Type updated successfully') {
                    state.thisLink = action.payload;
                } else {
                    state.thisLink = [];
                }
            },
            [rejected]: (state, action) => {
                state.thisLink = { error: action.error };
            }
        };
    }
    
}
