import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { fetchWrapper } from '_helpers'
import { getApiUrl } from '_helpers/url'

const name = 'notices'
const initialState = createInitialState()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()
const slice = createSlice({ name, initialState, extraReducers })

export const noticesActions = { ...slice.actions, ...extraActions }
export const noticesReducer = slice.reducer

function createInitialState() {
  return {
    notices: {},
    thisNotice: {},
    homeNotices: {},
  }
}

function createExtraActions() {
  const baseUrl = getApiUrl(`/api/noticesapp`)

  return {
    destroyThisNotice: destroyThisNotice(),
    saveNewNotice: saveNewNotice(),
    getThisNotice: getThisNotice(),
    getAllNotices: getAllNotices(),
    getAllNoticesReduced: getAllNoticesReduced(),
    editThisNotice: editThisNotice(),
    getHomeNotices: getHomeNotices(),
    addComment: addComment(),
    addReaction: addReaction(),
    removeReaction: removeReaction(),
  }

  function addComment() {
    return createAsyncThunk(
      `/${name}/addComment`,
      async ({ notices_id, comment }) => {
        const token = JSON.parse(localStorage.getItem('token'))
        const config = { headers: { Authorization: `Bearer ${token}` } }
        const response = await axios.post(
          `${baseUrl}/add-comment`,
          { notices_id, comment },
          config
        )
        return response.data
      }
    )
  }

  function addReaction() {
    return createAsyncThunk(
      `/${name}/addReaction`,
      async ({ notices_id, type }) => {
        const token = JSON.parse(localStorage.getItem('token'))
        const config = { headers: { Authorization: `Bearer ${token}` } }
        const response = await axios.post(
          `${baseUrl}/add-reaction`,
          { notices_id, type },
          config
        )
        return response.data
      }
    )
  }

  function removeReaction() {
    return createAsyncThunk(
      `/${name}/removeReaction`,
      async ({ reaction_id }) => {
        const token = JSON.parse(localStorage.getItem('token'))
        const config = { headers: { Authorization: `Bearer ${token}` } }
        const response = await axios.delete(
          `${baseUrl}/remove-reaction/${reaction_id}`,
          config
        )
        return response.data
      }
    )
  }

  function destroyThisNotice() {
    return createAsyncThunk(
      `/${name}/destroyThisNotice`,
      async ({ notices_id }) =>
        await fetchWrapper.post(`${baseUrl}/notices/destroyThisNotice`, {
          notices_id,
        })
    )
  }
  function editThisNotice() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/editThisNotice`,
      async (data) =>
        await axios
          .post(`${baseUrl}/notices/editThisNotice`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function saveNewNotice() {
    var token = JSON.parse(localStorage.getItem('token'))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    return createAsyncThunk(
      `/${name}/saveNewNotice`,
      async (data) =>
        await axios
          .post(`${baseUrl}/notices/saveNewNotice`, data, config)
          .then(function (response) {
            return response.data
          })
    )
  }

  function getThisNotice() {
    return createAsyncThunk(
      `/${name}/getThisNotice`,
      async (id) => await fetchWrapper.get(`${baseUrl}/notices/` + id)
    )
  }

  function getAllNotices() {
    return createAsyncThunk('notices/getAllNotices', async (page) => {
      const url = `${baseUrl}/notices${page ? `?page=${page}` : ''}`
      return await fetchWrapper.get(url)
    })
  }

  function getAllNoticesReduced() {
    return createAsyncThunk(
      `/${name}/getAllNotices`,
      async (dataUrl) => await fetchWrapper.get(`${dataUrl}`)
    )
  }

  function getHomeNotices() {
    return createAsyncThunk(
      `/${name}/getHomeNotices`,
      async () => await fetchWrapper.get(`${baseUrl}/getHomeNotices`)
    )
  }
}

function createExtraReducers() {
  return {
    ...destroyThisNotice(),
    ...saveNewNotice(),
    ...getThisNotice(),
    ...getAllNotices(),
    ...getAllNoticesReduced(),
    ...editThisNotice(),
    ...getHomeNotices(),
    ...addComment(),
    ...addReaction(),
    ...removeReaction(),
  }

  function removeReaction() {
    var { pending, fulfilled, rejected } = extraActions.removeReaction
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully removed reaction!') {
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function addComment() {
    var { pending, fulfilled, rejected } = extraActions.addComment
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully added comment!') {
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function addReaction() {
    var { pending, fulfilled, rejected } = extraActions.addReaction
    return {
      [pending]: (state) => {},
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully added reaction!') {
        }
      },
      [rejected]: (state, action) => {},
    }
  }

  function editThisNotice() {
    var { pending, fulfilled, rejected } = extraActions.editThisNotice
    return {
      [pending]: (state) => {
        state.thisNotice = { loading: true }
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully edited notice!') {
          state.thisNotice = action.payload.notice
        } else {
          state.thisNotice = null
        }
      },
      [rejected]: (state, action) => {
        state.thisNotice = { error: action.error }
      },
    }
  }

  function destroyThisNotice() {
    var { pending, fulfilled, rejected } = extraActions.destroyThisNotice
    return {
      [pending]: (state) => {
        state.thisNotice = { loading: true }
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully deleted notice!') {
          state.thisNotice = action.payload.notice
        } else {
          state.thisNotice = null
        }
      },
      [rejected]: (state, action) => {
        state.thisNotice = { error: action.error }
      },
    }
  }
  function getThisNotice() {
    var { pending, fulfilled, rejected } = extraActions.getThisNotice
    return {
      [pending]: (state) => {
        state.thisNotice = { loading: true }
      },
      [fulfilled]: (state, action) => {
        state.thisNotice = action.payload.notice
      },
      [rejected]: (state, action) => {
        state.thisNotice = { error: action.error }
      },
    }
  }
  function getAllNotices() {
    var { pending, fulfilled, rejected } = extraActions.getAllNotices
    return {
      [pending]: (state) => {
        state.notices = { loading: true }
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved notices') {
          state.notices = action.payload.notices
        } else {
          state.notices = null
        }
      },
      [rejected]: (state, action) => {
        state.notices = { error: action.error }
      },
    }
  }

  function getAllNoticesReduced() {
    var { pending, fulfilled, rejected } = extraActions.getAllNoticesReduced
    return {
      [pending]: (state) => {
        state.notices = { loading: true }
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved notices') {
          state.notices = action.payload.notices
        } else {
          state.notices = null
        }
      },
      [rejected]: (state, action) => {
        state.notices = { error: action.error }
      },
    }
  }

  function getHomeNotices() {
    var { pending, fulfilled, rejected } = extraActions.getHomeNotices
    return {
      [pending]: (state) => {
        state.homeNotices = { loading: true }
      },
      [fulfilled]: (state, action) => {
        if (action.payload.message === 'Successfully retrieved notices') {
          state.homeNotices = action.payload.notices
        } else {
          state.homeNotices = null
        }
      },
      [rejected]: (state, action) => {
        state.homeNotices = { error: action.error }
      },
    }
  }

  function saveNewNotice() {
    var { pending, fulfilled, rejected } = extraActions.saveNewNotice
    return {
      [pending]: (state) => {
        state.thisNotice = { loading: true }
      },
      [fulfilled]: (state, action) => {
        state.thisNotice = action.payload.notice
      },
      [rejected]: (state, action) => {
        state.thisNotice = { error: action.error }
      },
    }
  }
}
